import gql from 'graphql-tag';
import { NOTIFICATION_FRAGMENT } from './fragment';

export const GET_CURRENT_USER_NOTIFICATION = gql`
  query getCurrentUserNotifications($input: InputGetUserNotifications) {
    getCurrentUserNotifications(input: $input) {
      data {
        ...NotificationFragment
      }
      pageInfo {
        currentPage
        totalCount
        hasNextPage
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
