// material
import { FC } from "react";
import { Box, BoxProps } from "@material-ui/core";

// ----------------------------------------------------------------------
interface LogoProps {
  width?: number;
  heigth?: number;
}
const Logo: FC<LogoProps & BoxProps> = (props) => {
  const { heigth, width, sx } = props;

  return (
    <Box
      sx={{
        width: width ? width : 66,
        height: heigth ? heigth : 40,
        ...sx,
      }}
    >
      <img
        src="/static/illustrations/illustration_logo_batifire.png"
        alt="login"
      />
    </Box>
  );
};

export default Logo;
