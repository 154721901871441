import { ApolloQueryResult } from '@apollo/client';
import {
  Exact,
  GetCurrentUserNotificationsQuery,
  InputGetUserNotifications,
  Maybe,
  MeQuery,
  useGetCurrentUserNotificationsQuery,
  useMeQuery
} from 'generated/graphql';
import Login from 'pages/authentication/Login';
import React, { createContext, FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROOTS_AUTH } from 'routes/paths';

type UserContextType = {
  me: MeQuery['me'];
  notificationsData: GetCurrentUserNotificationsQuery | undefined;
  permissions: string[];
  currentPage: number;
  setCurrentPage: (value: any) => void;
  can: (permission: string[]) => boolean;
  loadMoreNotifications: () => void;
  reFetchNotification: (
    variables?:
      | Partial<Exact<{
      input?: Maybe<InputGetUserNotifications> | undefined;
    }>>
      | undefined
  ) => Promise<ApolloQueryResult<GetCurrentUserNotificationsQuery>> | undefined;
};

export const UserContext = createContext<UserContextType>({
  me: null,
  notificationsData: undefined,
  setCurrentPage: () => {
  },
  permissions: [],
  currentPage: 0,
  can: (permissions: string[]) => {
    return false;
  },
  loadMoreNotifications: () => {
  },
  reFetchNotification: () => undefined
});

export const UserProvider: FC = ({ children }) => {
  const [me, setMe] = useState<MeQuery['me']>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const { pathname } = useLocation();
  const {
    data: notifications,
    fetchMore: fetchMoreNotifications,
    refetch: reFetchNotification
  } = useGetCurrentUserNotificationsQuery({
    variables: {
      input: { maxPage: 3, currentPage, searchParams: '' }
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data, error } = useMeQuery({ fetchPolicy: 'cache-and-network' });
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (data && data.me) {
      setMe(data.me);
      const userPermissions = data.me.userGroup?.permissions.map((p) => p.name.toLowerCase()) || [];
      setPermissions(userPermissions);
    }
    setPermissionsLoaded(true);
  }, [data, error]);

  if (
    !pathname.includes(ROOTS_AUTH) &&
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].message === 'NOT_AUTHENTICATED'
  ) {
    window.localStorage.setItem('accessToken', '');
    return <Login/>;
  }
  const loadMoreNotifications = () => {
    const nextPage = currentPage + 1;
    fetchMoreNotifications({
      variables: {
        input: { maxPage: 3, currentPage: nextPage, searchParams: '' }
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        if (!fetchMoreResult?.getCurrentUserNotifications?.data) {
          return previousQueryResult;
        }
        return {
          ...previousQueryResult,
          getCurrentUserNotifications: {
            ...fetchMoreResult.getCurrentUserNotifications,
            data: fetchMoreResult.getCurrentUserNotifications.data
          }
        };
      }
    });
    setCurrentPage(nextPage);
  };

  return (
    <UserContext.Provider
      value={ {
        me,
        notificationsData: notifications,
        permissions,
        loadMoreNotifications,
        setCurrentPage,
        currentPage,
        reFetchNotification,
        can: (p: string[]) => {
          return p.some((p) => permissions.includes(p.toLowerCase()));
        }
      } }
    >
      { permissionsLoaded && children }
    </UserContext.Provider>
  );
};
