import React, { ReactNode, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { PERMISSIONS_BY_TYPE } from './permissions';
import { Navigate } from 'react-router-dom';

type EntryPointGuardProp = {
  children?: ReactNode | string;
};

export default function EntryPointGuard({ children }: EntryPointGuardProp) {
  const { can, me } = useContext(UserContext);

  if (me) {
    if (can(PERMISSIONS_BY_TYPE.site)) {
      return <Navigate to="/dashboard/site/list" replace/>;
    } else if (can(PERMISSIONS_BY_TYPE.user)) {
      return <Navigate to="/dashboard/user/list" replace/>;
    } else if (can(PERMISSIONS_BY_TYPE.group)) {
      return <Navigate to="/dashboard/group/list" replace/>;
    } else if (can(PERMISSIONS_BY_TYPE.currentRisk)) {
      return <Navigate to="/dashboard/risk/" replace/>;
    } else if (can(PERMISSIONS_BY_TYPE.specificRisk)) {
      return <Navigate to="/dashboard/specificRisk/" replace/>;
    } else {
      return <Navigate to="/404" replace/>;
    }
  } else {
    return null;
  }
}
