import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link, Switch,
  TextField, Typography
} from '@material-ui/core';
import { User, useUpdateUserProfilMutation } from 'generated/graphql';
import React, { FC, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ME } from 'gql/user/query';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import _ from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { keyErrorExist } from 'common/commonFunction';

interface UserProfilDialogProps {
  children?: any;
  open: boolean;
  onClose: () => void;
  user: User;
}

type showPassword = {
  current: boolean;
  new: boolean;
  confirm: boolean;
};

const UserProfilDialog: FC<UserProfilDialogProps> = (props) => {
  const { open, onClose, user } = props;

  const editUserProfilSchema = Yup.object().shape({
    firstName: Yup.string().required('Nom obligatoire'),
    lastName: Yup.string().required('Prénom obligatoire'),
    email: Yup.string().required('Email obligatoire').email('Email invalide'),
    currentPassword: Yup.string(),
    newPassword: Yup.string().matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial'
    ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'Les mots de passe doivent correspondre'
    ),
    accountName: Yup.string().required('Nom du compte obligatoire')
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [passwordShow, setPasswordShow] = useState<showPassword>({
    current: false,
    new: false,
    confirm: false
  });

  const [updateUserProfil, { loading }] = useUpdateUserProfilMutation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      lastName: user?.lastName || '',
      firstName: user?.firstName || '',
      accountName: user?.accountName || '',
      getCreateNotification: user?.getCreateNotification || false,
      getUpdateNotification: user?.getUpdateNotification || false,
      currentPassword: '',
      confirmPassword: '',
      newPassword: '',
      email: user?.email || ''
    },
    validationSchema: editUserProfilSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const result = await updateUserProfil({
          variables: {
            input: {
              id: user.id,
              firstName: values.firstName,
              lastName: values.lastName,
              getCreateNotification: values.getCreateNotification,
              getUpdateNotification: values.getUpdateNotification,
              currentPassword: values.currentPassword || '',
              newPassword: values.currentPassword ? values.newPassword : '',
              email: user.email,
              accountName: values.accountName
            }
          },
          async update(cache, mutationResult) {
            cache.readQuery({
              query: ME
            });
            const newUserProfil = mutationResult.data?.updateUserProfil;
            cache.writeQuery({
              query: ME,
              data: {
                me: {
                  newUserProfil
                }
              }
            });
          }
        });
        if (result && result.data && result.data.updateUserProfil) {
          setSubmitting(false);
          handleClose();
          enqueueSnackbar('Profil modifié', { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar('Mot de passe incorrect', { variant: 'error' });
        setSubmitting(false);
      }
    }
  });
  const {
    errors,
    values,
    initialValues,
    touched,
    handleSubmit,
    resetForm,
    isSubmitting,
    getFieldProps
  } = formik;

  const checkFirstNameLastNameFields = () => {
    const password = {
      current: values.currentPassword,
      new: values.newPassword,
      confirm: values.confirmPassword
    };
    const fieldsInital = {
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      getCreateNotification: initialValues.getCreateNotification,
      getUpdateNotification: initialValues.getUpdateNotification
    };
    const fieldsValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      getCreateNotification: values.getCreateNotification,
      getUpdateNotification: values.getUpdateNotification
    };
    if (password.current || password.new || password.confirm) {
      return checkFieldPassword();
    }
    if (keyErrorExist(['firstName', 'lastName'], errors)) {
      return true;
    }
    return _.isEqual(fieldsInital, fieldsValues);
  };

  const checkFieldPassword = () => {
    const fieldsPasswordValues = {
      current: values.currentPassword,
      new: values.newPassword,
      confirm: values.confirmPassword
    };
    if (fieldsPasswordValues.current || fieldsPasswordValues.new || fieldsPasswordValues.confirm) {
      if (
        fieldsPasswordValues.current &&
        fieldsPasswordValues.new &&
        fieldsPasswordValues.confirm
      ) {
        if (fieldsPasswordValues.new === fieldsPasswordValues.confirm) {
          if (
            !keyErrorExist(
              ['firstName', 'lastName', 'newPassword', 'confirmPassword'],
              errors
            )
          ) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  };

  const handleClose = () => {
    onClose();
    resetForm();
    setIsEdit(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={ open } onClose={ handleClose }>
      <DialogTitle>Mon profil</DialogTitle>
      <DialogContent>
        <FormikProvider value={ formik }>
          <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 12 }>
                <TextField
                  fullWidth
                  label="Nom *"
                  { ...getFieldProps('firstName') }
                  defaultValue={ user?.firstName }
                  error={ Boolean(touched.firstName && errors.firstName) }
                  helperText={ touched.firstName && errors.firstName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 12 }>
                <TextField
                  fullWidth
                  label="Prenom *"
                  defaultValue={ user?.lastName }
                  { ...getFieldProps('lastName') }
                  error={ Boolean(touched.lastName && errors.lastName) }
                  helperText={ touched.lastName && errors.lastName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 12 }>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      {...getFieldProps('getCreateNotification')}
                      checked={values.getCreateNotification}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={ { mb: 0.5 } }>
                        Recevoir les notifications de création
                      </Typography>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 12 }>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      {...getFieldProps('getUpdateNotification')}
                      checked={values.getUpdateNotification}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={ { mb: 0.5 } }>
                        Recevoir les notifications de modification
                      </Typography>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 12 }>
                <Link
                  style={ { cursor: 'pointer' } }
                  variant="body2"
                  color="#1e88e5"
                  onClick={ () => {
                    setIsEdit((prev) => !prev);
                  } }
                >
                  Modifier le mot de passe
                </Link>
              </Grid>
              { isEdit ? (
                <>
                  <Grid item xs={ 12 } md={ 12 }>
                    <TextField
                      fullWidth
                      label="Mot de passe actuel *"
                      type={ passwordShow.current ? 'text' : 'password' }
                      { ...getFieldProps('currentPassword') }
                      InputProps={ {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={ () =>
                                setPasswordShow({
                                  ...passwordShow,
                                  current: !passwordShow.current
                                })
                              }
                              edge="end"
                            >
                              { passwordShow.current ? <Visibility/> : <VisibilityOff/> }
                            </IconButton>
                          </InputAdornment>
                        )
                      } }
                      error={ Boolean(touched.currentPassword && errors.currentPassword) }
                      helperText={ touched.currentPassword && errors.currentPassword }
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 12 }>
                    <TextField
                      fullWidth
                      label="Nouveau mot de passe *"
                      type={ passwordShow.new ? 'text' : 'password' }
                      { ...getFieldProps('newPassword') }
                      InputProps={ {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={ () =>
                                setPasswordShow({
                                  ...passwordShow,
                                  new: !passwordShow.new
                                })
                              }
                              edge="end"
                            >
                              { passwordShow.new ? <Visibility/> : <VisibilityOff/> }
                            </IconButton>
                          </InputAdornment>
                        )
                      } }
                      error={ Boolean(touched.newPassword && errors.newPassword) }
                      helperText={ touched.newPassword && errors.newPassword }
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 12 }>
                    <TextField
                      fullWidth
                      label="Confirmer le nouveau mot de passe *"
                      type={ passwordShow.confirm ? 'text' : 'password' }
                      { ...getFieldProps('confirmPassword') }
                      InputProps={ {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={ () =>
                                setPasswordShow({
                                  ...passwordShow,
                                  confirm: !passwordShow.confirm
                                })
                              }
                              edge="end"
                            >
                              { passwordShow.confirm ? <Visibility/> : <VisibilityOff/> }
                            </IconButton>
                          </InputAdornment>
                        )
                      } }
                      error={ Boolean(touched.confirmPassword && errors.confirmPassword) }
                      helperText={ touched.confirmPassword && errors.confirmPassword }
                    />
                  </Grid>
                </>
              ) : null }
            </Grid>
            <Box
              sx={ {
                mt: 3,
                display: 'flex',
                justifyContent: 'flex-end'
              } }
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={ isSubmitting || loading }
                style={ { marginRight: 32 } }
                disabled={ checkFirstNameLastNameFields() }
              >
                Modifier
              </LoadingButton>
              <Button variant="outlined" onClick={ handleClose } disabled={ loading }>
                Annuler
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfilDialog;
