/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Serializes and Deserializes Base64 strings */
  Base64: any;
  /** Date custom scalar type */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AccountStatusEnum =
  | 'DISABLE'
  | 'PENDING'
  | 'ENABLE'
  | 'NONE';

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  user: User;
  token: Scalars['String'];
};


export type Bien = {
  __typename?: 'Bien';
  id: Scalars['String'];
  name: Scalars['String'];
  level: Scalars['String'];
  priority: Scalars['Int'];
  fait: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  site: Site;
  url?: Maybe<Scalars['String']>;
  encryptedPartBase64?: Maybe<Scalars['String']>;
  clearPartBase64?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BienHistory = {
  __typename?: 'BienHistory';
  id: Scalars['String'];
  site?: Maybe<Site>;
  authorId: Scalars['String'];
  author: User;
  field: Scalars['String'];
  value: Scalars['String'];
  status: ValidationStatus;
};

export type BienPage = {
  __typename?: 'BienPage';
  data: Array<Bien>;
  pageInfo?: Maybe<PageInfo>;
};

export type BienWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  name: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CategoryPage = {
  __typename?: 'CategoryPage';
  data: Array<Category>;
  pageInfo?: Maybe<PageInfo>;
};

export type Commune = {
  __typename?: 'Commune';
  id: Scalars['String'];
  name: Scalars['String'];
  sites: Array<Site>;
  deleted: Scalars['Boolean'];
  zipCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  regionCode: Scalars['Int'];
  departmentId?: Maybe<Scalars['String']>;
  department?: Maybe<Department>;
  departmentCode?: Maybe<Scalars['String']>;
};


export type CommuneSitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<SiteWhereUniqueInput>;
  after?: Maybe<SiteWhereUniqueInput>;
};

export type CommuneWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CommunesPage = {
  __typename?: 'CommunesPage';
  data: Array<Commune>;
  pageInfo?: Maybe<PageInfo>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  dayPhone?: Maybe<Scalars['String']>;
  nightPhone?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  site: Site;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type ContactHistory = {
  __typename?: 'ContactHistory';
  id: Scalars['String'];
  site?: Maybe<Site>;
  authorId: Scalars['String'];
  author: User;
  field: Scalars['String'];
  value: Scalars['String'];
  status: ValidationStatus;
};

export type ContactPageCursor = {
  __typename?: 'ContactPageCursor';
  totalCount?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgeContact>>>;
  pageCursorInfo?: Maybe<ContactPageCursorInfo>;
};

export type ContactPageCursorInfo = {
  __typename?: 'ContactPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type ContactWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ContactsPage = {
  __typename?: 'ContactsPage';
  data: Array<Contact>;
  pageInfo?: Maybe<PageInfo>;
};

export type CurrentRisk = {
  __typename?: 'CurrentRisk';
  id: Scalars['String'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type CurrentRiskPage = {
  __typename?: 'CurrentRiskPage';
  data: Array<CurrentRisk>;
  pageInfo?: Maybe<PageInfo>;
};

export type CurrentRiskToSite = {
  __typename?: 'CurrentRiskToSite';
  id: Scalars['String'];
  level: Scalars['Int'];
  description: Scalars['String'];
  descriptionDecrypted?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  currentRiskId: Scalars['String'];
  currentRisk: CurrentRisk;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type CurrentRiskToSiteSiteIdCurrentRiskIdCompoundUniqueInput = {
  siteId: Scalars['String'];
  currentRiskId: Scalars['String'];
};

export type CurrentRiskToSiteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  siteId_currentRiskId?: Maybe<CurrentRiskToSiteSiteIdCurrentRiskIdCompoundUniqueInput>;
};

export type DangerousMaterial = {
  __typename?: 'DangerousMaterial';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  codeONU: Scalars['String'];
  deleted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type DangerousMaterialPage = {
  __typename?: 'DangerousMaterialPage';
  data: Array<DangerousMaterial>;
  pageInfo?: Maybe<PageInfo>;
};


export type DeleteGroupPayload = {
  __typename?: 'DeleteGroupPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['String'];
  name: Scalars['String'];
  communes: Array<Commune>;
  departmentCode: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  displayBatiRegistre?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  departmentUser: Array<DepartmentUser>;
  devices: Array<Device>;
};


export type DepartmentCommunesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<CommuneWhereUniqueInput>;
  after?: Maybe<CommuneWhereUniqueInput>;
};


export type DepartmentDepartmentUserArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DepartmentUserWhereUniqueInput>;
  after?: Maybe<DepartmentUserWhereUniqueInput>;
};


export type DepartmentDevicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DeviceWhereUniqueInput>;
  after?: Maybe<DeviceWhereUniqueInput>;
};

export type DepartmentDocument = {
  __typename?: 'DepartmentDocument';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  categoryId: Scalars['String'];
  departmentId: Scalars['String'];
  category: Category;
  department: Department;
  path: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type DepartmentDocumentPage = {
  __typename?: 'DepartmentDocumentPage';
  data: Array<DepartmentDocument>;
  pageInfo?: Maybe<PageInfo>;
};

export type DepartmentPage = {
  __typename?: 'DepartmentPage';
  data: Array<Department>;
  pageInfo?: Maybe<PageInfo>;
};

export type DepartmentPageCursorInfo = {
  __typename?: 'DepartmentPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type DepartmentUser = {
  __typename?: 'DepartmentUser';
  id: Scalars['String'];
  userId: Scalars['String'];
  departmentId: Scalars['String'];
  department: Department;
  countCategory?: Maybe<Scalars['Int']>;
  countDocuments?: Maybe<Scalars['Int']>;
  readonly?: Maybe<Scalars['Boolean']>;
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DepartmentUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DepartmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firebaseToken: Scalars['String'];
  serialNumber: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['String']>;
  department?: Maybe<Department>;
  user: User;
};

export type DevicePage = {
  __typename?: 'DevicePage';
  data: Array<Device>;
  pageInfo?: Maybe<PageInfo>;
};

export type DeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['String'];
  name: Scalars['String'];
  type: DocumentTypeEnum;
  path?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  encryptedPartBase64?: Maybe<Scalars['String']>;
  clearPartBase64?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type DocumentHistory = {
  __typename?: 'DocumentHistory';
  id: Scalars['String'];
  site?: Maybe<Site>;
  authorId: Scalars['String'];
  author: User;
  field: Scalars['String'];
  value: Scalars['String'];
  status: ValidationStatus;
};

export type DocumentPage = {
  __typename?: 'DocumentPage';
  data: Array<Document>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentPageCursor = {
  __typename?: 'DocumentPageCursor';
  totalCount?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgeDocument>>>;
  pageCursorInfo?: Maybe<DocumentPageCursorInfo>;
};

export type DocumentPageCursorInfo = {
  __typename?: 'DocumentPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type DocumentTypeEnum =
  | 'ETARE'
  | 'PSO'
  | 'PHOTO'
  | 'PROCEDURES'
  | 'FDS';

export type DocumentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EdgeContact = {
  __typename?: 'EdgeContact';
  node?: Maybe<Contact>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgeDepartment = {
  __typename?: 'EdgeDepartment';
  node?: Maybe<Department>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgeDocument = {
  __typename?: 'EdgeDocument';
  node?: Maybe<Document>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgeNotification = {
  __typename?: 'EdgeNotification';
  node?: Maybe<Notification>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgePermission = {
  __typename?: 'EdgePermission';
  node?: Maybe<Permission>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgePlan = {
  __typename?: 'EdgePlan';
  node?: Maybe<Plan>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgeSite = {
  __typename?: 'EdgeSite';
  node?: Maybe<Site>;
  cursor?: Maybe<Scalars['String']>;
};

export type EdgeUserGroup = {
  __typename?: 'EdgeUserGroup';
  node: UserGroup;
  cursor?: Maybe<Scalars['String']>;
};

export type ExportSiteBySiteId = {
  __typename?: 'ExportSiteBySiteId';
  file?: Maybe<Scalars['String']>;
};

export type ExportSites = {
  __typename?: 'ExportSites';
  file?: Maybe<Scalars['String']>;
};

export type FileSize = {
  __typename?: 'FileSize';
  id: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  resetEmailSent?: Maybe<Scalars['Boolean']>;
};

export type GroupPermissionManagementTypeEnum =
  | 'ADD_PERMISSION'
  | 'REMOVE_PERMISSION';

export type HistoryDataReturn = {
  __typename?: 'HistoryDataReturn';
  hasPlanHistories?: Maybe<Scalars['Boolean']>;
  hasContactHistories?: Maybe<Scalars['Boolean']>;
  hasDocumentHistories?: Maybe<Scalars['Boolean']>;
  hasCurrentRiskHistories?: Maybe<Scalars['Boolean']>;
  hasSpecificRiskHistories?: Maybe<Scalars['Boolean']>;
  hasMoyenHistories?: Maybe<Scalars['Boolean']>;
  hasBienHistories?: Maybe<Scalars['Boolean']>;
};

export type IInputCreateUser = {
  userGroupId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  password: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  accountName: Scalars['String'];
  departmentIds: Array<Maybe<Scalars['String']>>;
  readOnlyDepartmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IInputUpdateUser = {
  id?: Maybe<Scalars['ID']>;
  userGroupId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  accountName: Scalars['String'];
  accountStatus?: Maybe<AccountStatusEnum>;
  departmentIds: Array<Maybe<Scalars['String']>>;
  readOnlyDepartmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputAcceptCreateRequest = {
  id: Scalars['String'];
  receiver_type: ReceiverTypeEnum;
  notificationType: NotificationTypeEnum;
  authorGroupId: Scalars['String'];
};

export type InputAcceptCreateWithoutAuthoriGroupId = {
  id: Scalars['String'];
  receiver_type: ReceiverTypeEnum;
  notificationType: NotificationTypeEnum;
};

export type InputAddUserToGroup = {
  userIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

export type InputBienHistory = {
  siteId: Scalars['String'];
  userGroupId: Scalars['String'];
};

export type InputCheckDeviceStatus = {
  serialNumber: Scalars['String'];
};

export type InputCheckReference = {
  reference: Scalars['String'];
};

export type InputCreateCurrentRisk = {
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type InputCreateCurrentRiskToSite = {
  currentRiskId: Scalars['String'];
  siteId: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type InputCreateCurrentRiskToSiteList = {
  currentRiskToSiteList: Array<InputCreateCurrentRiskToSite>;
};

export type InputCreateNotification = {
  siteId: Scalars['String'];
};

export type InputCreateSite = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  etare?: Maybe<Scalars['Boolean']>;
  referenceEtare?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  communeId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['String']>;
  siteTypeId?: Maybe<Scalars['String']>;
  editHistorics?: Maybe<Scalars['JSON']>;
  sensible?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  authorizedGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  managePasswordBySupervisor?: Maybe<Scalars['Boolean']>;
};

export type InputCreateSiteFullInfo = {
  name: Scalars['String'];
  etare: Scalars['Boolean'];
  referenceEtare?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  ranking: Scalars['String'];
  activity: Scalars['String'];
  communeId: Scalars['String'];
  departmentId: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  town: Scalars['String'];
  moyen: Scalars['String'];
  zipCode: Scalars['String'];
  siteTypeId: Scalars['String'];
  coordinates: Scalars['String'];
  contacts: Scalars['JSON'];
  currentRisks: Scalars['JSON'];
  specificRisks: Scalars['JSON'];
  plans: Scalars['JSON'];
  documents: Scalars['JSON'];
  sensible?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  managePasswordBySupervisor?: Maybe<Scalars['Boolean']>;
  authorizedGroups: Array<Maybe<Scalars['String']>>;
  biens: Scalars['JSON'];
  sendedFilePaths?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputCreateSiteSubscription = {
  departementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  userGroupName?: Maybe<Scalars['String']>;
};

export type InputCreateSpecificRisk = {
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type InputCreateSpecificRisksToSiteInStepper = {
  specificRiskId: Scalars['String'];
  siteId: Scalars['String'];
  description: Scalars['String'];
};

export type InputCreateUpdateBien = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  fait?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  editHistorics?: Maybe<Scalars['JSON']>;
};

export type InputCreateUpdateBienFromBatiRegistre = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  fait?: Maybe<Scalars['Boolean']>;
  siteId: Scalars['String'];
  fileData: Scalars['String'];
};

export type InputCreateUpdateBienInStepper = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  siteId: Scalars['String'];
  location?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateCategory = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type InputCreateUpdateCommune = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['Int']>;
  departmentCode?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateContact = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  occupation: Scalars['String'];
  dayPhone: Scalars['String'];
  nightPhone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  editHistorics?: Maybe<Scalars['JSON']>;
};

export type InputCreateUpdateContactFromBatiRegistre = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  lastName: Scalars['String'];
  dayPhone: Scalars['String'];
  firstName: Scalars['String'];
  occupation: Scalars['String'];
  nightPhone: Scalars['String'];
};

export type InputCreateUpdateContactInStepper = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  occupation: Scalars['String'];
  dayPhone: Scalars['String'];
  nightPhone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
};

export type InputCreateUpdateCurrentRiskToSiteFromBatiRegistre = {
  currentRiskId: Scalars['String'];
  siteId: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateDangerousMaterial = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  codeONU?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateDepartment = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  departmentCode?: Maybe<Scalars['String']>;
  displayBatiRegistre?: Maybe<Scalars['Boolean']>;
};

export type InputCreateUpdateDocument = {
  id?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  editHistorics?: Maybe<Scalars['JSON']>;
  type: DocumentTypeEnum;
};

export type InputCreateUpdateDocumentFromBatiRegistre = {
  id?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  name: Scalars['String'];
  fileData: Scalars['String'];
  type: DocumentTypeEnum;
};

export type InputCreateUpdateDocumentInStepper = {
  id?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  type: DocumentTypeEnum;
};

export type InputCreateUpdateFileSize = {
  id?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
};

export type InputCreateUpdateGedOpsDocument = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  departmentId: Scalars['String'];
  categoryId: Scalars['String'];
  path: Scalars['String'];
};

export type InputCreateUpdateMoyen = {
  siteId: Scalars['String'];
  note: Scalars['String'];
};

export type InputCreateUpdateMoyenInStepper = {
  siteId: Scalars['String'];
  note: Scalars['String'];
};

export type InputCreateUpdatePermission = {
  name: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type InputCreateUpdatePlan = {
  id?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isImage?: Maybe<Scalars['Boolean']>;
  tagId?: Maybe<Scalars['String']>;
  editHistorics?: Maybe<Scalars['JSON']>;
};

export type InputCreateUpdatePlanFromBatiRegistre = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  fileData: Scalars['String'];
};

export type InputCreateUpdatePlanInStepper = {
  id?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateSiteFromBatiRegistre = {
  siteId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  etare?: Maybe<Scalars['Boolean']>;
  referenceEtare?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  fileData?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  communeId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['String']>;
  siteTypeId?: Maybe<Scalars['String']>;
};

export type InputCreateUpdateSiteGeneralInfo = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  etare?: Maybe<Scalars['Boolean']>;
  referenceEtare?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  activity?: Maybe<Scalars['String']>;
  communeId?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['String']>;
  siteTypeId?: Maybe<Scalars['String']>;
  sensible?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  managePasswordBySupervisor?: Maybe<Scalars['Boolean']>;
};

export type InputCreateUpdateSiteType = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type InputCreateUpdateSpecificRiskToSite = {
  specificRiskId: Scalars['String'];
  siteId: Scalars['String'];
  description: Scalars['String'];
  editHistorics?: Maybe<Scalars['JSON']>;
};

export type InputCreateUpdateSpecificRiskToSiteFromBatiRegistre = {
  specificRiskId: Scalars['String'];
  siteId: Scalars['String'];
  description: Scalars['String'];
};

export type InputCreateUpdateTag = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type InputCreateUserGroup = {
  name: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  permissionIds: Array<Maybe<Scalars['String']>>;
  groupPermissionManagementType?: Maybe<GroupPermissionManagementTypeEnum>;
  otherUserGroupIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputCurrentRiskHistories = {
  siteId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type InputCustomName = {
  id: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

export type InputDeleteBien = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputDeleteCategories = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteCommune = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputDeleteContact = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteCurrentRisk = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteDangerousMaterial = {
  ids: Array<Maybe<Scalars['String']>>;
};

export type InputDeleteDepartment = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputDeleteDocument = {
  ids: Array<Maybe<Scalars['String']>>;
};

export type InputDeleteFileFromBatiRegistre = {
  id: Scalars['String'];
  modelType: ModelFileTypeEnum;
};

export type InputDeleteGedOpsDocument = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteGroup = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteMoyen = {
  id: Scalars['String'];
};

export type InputDeletePermission = {
  id: Scalars['String'];
};

export type InputDeletePlan = {
  id: Scalars['String'];
};

export type InputDeleteSite = {
  ids: Array<Maybe<Scalars['String']>>;
};

export type InputDeleteSiteTypes = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteSpecificRisk = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteSpecificRiskToSite = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteTags = {
  ids: Array<Scalars['String']>;
};

export type InputDeleteUser = {
  ids: Array<Scalars['String']>;
};

export type InputDevice = {
  deviceName: Scalars['String'];
  serialNumber: Scalars['String'];
  fcmToken: Scalars['String'];
};

export type InputDeviceDepartment = {
  serialNumber: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type InputDisableDeviceSubscription = {
  serialNumber?: Maybe<Scalars['String']>;
};

export type InputFilterUsers = {
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  notIngroup?: Maybe<Scalars['Boolean']>;
  selectedDepartmentId?: Maybe<Scalars['String']>;
};

export type InputForgotPassword = {
  email: Scalars['String'];
};

export type InputGetAllBien = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetAllCommunesByDepartment = {
  departmentId: Scalars['String'];
};

export type InputGetAllContactsBySite = {
  id: Scalars['String'];
};

export type InputGetAllCurrentRiskBySite = {
  siteId: Scalars['String'];
};

export type InputGetAllDangerousMaterial = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetAllDepartment = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
  selectedUserId?: Maybe<Scalars['String']>;
  queryType?: Maybe<QueryTypeEnum>;
};

export type InputGetAllDepartmentUserByUser = {
  userId: Scalars['String'];
};

export type InputGetAllDepartmentsByUserId = {
  userId: Scalars['String'];
};

export type InputGetAllDocumentsBySite = {
  id: Scalars['String'];
};

export type InputGetAllMoyen = {
  maxPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetAllPermissions = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['String']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetAllPlansBySite = {
  id: Scalars['String'];
};

export type InputGetAllReadOnlyDepartmentsByUserId = {
  userId: Scalars['String'];
};

export type InputGetAllSite = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
  isFromBatiRegistre?: Maybe<Scalars['Boolean']>;
};

export type InputGetAllSitesByCommune = {
  communeId: Scalars['String'];
};

export type InputGetAllSpecificRiskBySite = {
  siteId: Scalars['String'];
};

export type InputGetAllValidationRequestCount = {
  userId: Scalars['String'];
};

export type InputGetBienById = {
  id: Scalars['String'];
};

export type InputGetBienBySite = {
  id: Scalars['String'];
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetCategories = {
  searchParams?: Maybe<Scalars['String']>;
  maxPerPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type InputGetCommunes = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type InputGetContact = {
  id: Scalars['String'];
};

export type InputGetContactsBySite = {
  id: Scalars['String'];
  maxPerPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetCurrentDevice = {
  serialNumber: Scalars['String'];
};

export type InputGetCurrentRisk = {
  id: Scalars['String'];
};

export type InputGetCurrentRisks = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetCurrentRisksToSite = {
  siteId: Scalars['String'];
};

export type InputGetDepartment = {
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetDepartmentById = {
  id: Scalars['String'];
};

export type InputGetDeviceBySerialNumber = {
  serialNumber: Scalars['String'];
};

export type InputGetDevices = {
  searchParams?: Maybe<Scalars['String']>;
  maxPerPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type InputGetDocumentsBySite = {
  id: Scalars['String'];
  maxPerPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetExportSiteBySiteId = {
  siteId: Scalars['String'];
};

export type InputGetExportSites = {
  date: Scalars['String'];
  departmentId: Scalars['String'];
};

export type InputGetGedOpsDocument = {
  id: Scalars['String'];
};

export type InputGetGedOpsDocuments = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
};

export type InputGetGroup = {
  id: Scalars['String'];
};

export type InputGetGroups = {
  maxPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetLogs = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetMoyenBySite = {
  siteId: Scalars['String'];
};

export type InputGetNotAddedSpecificRisksToSite = {
  siteId: Scalars['String'];
};

export type InputGetPermission = {
  id: Scalars['String'];
};

export type InputGetPlansBySite = {
  id: Scalars['String'];
  maxPerPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetSiteById = {
  id: Scalars['String'];
};

export type InputGetSiteByReference = {
  reference: Scalars['String'];
};

export type InputGetSiteBySiteId = {
  siteId: Scalars['String'];
};

export type InputGetSiteTypes = {
  searchParams?: Maybe<Scalars['String']>;
  maxPerPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type InputGetSpecificRisk = {
  id: Scalars['String'];
};

export type InputGetSpecificRisks = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetSpecificRisksToSite = {
  siteId: Scalars['String'];
  maxPerPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetTags = {
  searchParams?: Maybe<Scalars['String']>;
  maxPerPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type InputGetUserGroupById = {
  id: Scalars['String'];
};

export type InputGetUserNotifications = {
  maxPage: Scalars['Int'];
  currentPage?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type InputGetUsersByCurrentUser = {
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  selectedDepartmentId?: Maybe<Scalars['String']>;
};

export type InputHistories = {
  siteId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type InputHistoryData = {
  siteId: Scalars['String'];
  userGroupId: Scalars['String'];
};

export type InputLogin = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  isMobile?: Maybe<Scalars['Boolean']>;
  device?: Maybe<InputDevice>;
};

export type InputManagePermissionInGroup = {
  permissionIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  groupeMangementType?: Maybe<GroupPermissionManagementTypeEnum>;
};

export type InputMoyenHistory = {
  siteId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type InputPlanHistories = {
  siteId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type InputPresigneUrl = {
  filePath: Scalars['String'];
};

export type InputRemoveUserFromGroup = {
  userIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

export type InputResetPassword = {
  token: Scalars['String'];
  password: Scalars['String'];
  confirmationPassword: Scalars['String'];
};

export type InputSavePlansOrder = {
  plans: Array<PlanOrder>;
};

export type InputSiteFile = {
  path: Scalars['String'];
};

export type InputSpecificRiskHistories = {
  siteId?: Maybe<Scalars['String']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type InputUpdateBienFait = {
  id: Scalars['String'];
  fait?: Maybe<Scalars['Boolean']>;
};

export type InputUpdateCoordinates = {
  siteId: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type InputUpdateCurrentRisk = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type InputUpdateCurrentRiskToSite = {
  currentRiskToSiteId: Scalars['String'];
  currentRiskId: Scalars['String'];
  siteId: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type InputUpdateCurrentRiskToSiteList = {
  currentRiskToSiteList: Array<InputUpdateCurrentRiskToSite>;
};

export type InputUpdateDevice = {
  serialNumber: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

export type InputUpdateDeviceStatus = {
  id: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type InputUpdateSensibility = {
  id: Scalars['String'];
  sensible: Scalars['Boolean'];
  password: Scalars['String'];
};

export type InputUpdateSpecificRisk = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type InputUpdateSpecificRisksToSiteInStepper = {
  specificRiskToSiteId: Scalars['String'];
  specificRiskId: Scalars['String'];
  siteId: Scalars['String'];
  description: Scalars['String'];
};

export type InputUpdateUserProfil = {
  id: Scalars['ID'];
  email: Scalars['String'];
  currentPassword?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  newPassword: Scalars['String'];
  accountName: Scalars['String'];
  getCreateNotification: Scalars['Boolean'];
  getUpdateNotification: Scalars['Boolean'];
};

export type InputUser = {
  id: Scalars['String'];
};


export type Logs = {
  __typename?: 'Logs';
  id: Scalars['String'];
  siteId?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  method: Scalars['String'];
  subModel?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageDecrypted?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LogsPage = {
  __typename?: 'LogsPage';
  data: Array<Logs>;
  pageInfo?: Maybe<PageInfo>;
};

export type Moyen = {
  __typename?: 'Moyen';
  id: Scalars['String'];
  note: Scalars['String'];
  noteDecrypted?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  site?: Maybe<Site>;
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MoyenPage = {
  __typename?: 'MoyenPage';
  data: Array<Moyen>;
  pageInfo?: Maybe<PageInfo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUpdateSite?: Maybe<Site>;
  createUpdateSiteFromBatiRegistre?: Maybe<Site>;
  createSiteFullInfo?: Maybe<Site>;
  acceptCreateRequest?: Maybe<Scalars['Boolean']>;
  acceptEditRequest?: Maybe<Scalars['Boolean']>;
  rejectRequest?: Maybe<Scalars['Boolean']>;
  deleteSite?: Maybe<Scalars['Boolean']>;
  checkReference?: Maybe<CheckReferenceReturn>;
  deleteSiteFileOnAWS?: Maybe<Scalars['Boolean']>;
  createOrUpdateSiteGeneralInfo?: Maybe<Site>;
  updateCoordinates?: Maybe<Site>;
  updateSensibility?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<Array<Maybe<User>>>;
  updateUserProfil?: Maybe<User>;
  createUpdateContact?: Maybe<Contact>;
  createUpdateContactInStepper?: Maybe<Contact>;
  deleteContact?: Maybe<Array<Contact>>;
  createUpdateContactFromBatiRegistre?: Maybe<Contact>;
  createUpdatePermission?: Maybe<Permission>;
  deletePermission?: Maybe<Permission>;
  createUpdateUserGroup?: Maybe<UserGroup>;
  deleteUserGroup?: Maybe<DeleteGroupPayload>;
  addUserToGroup?: Maybe<UserGroup>;
  removeUserFromGroup?: Maybe<UserGroup>;
  addOrRemovePermissionFromGroup?: Maybe<UserGroup>;
  getGroupById?: Maybe<UserGroup>;
  login?: Maybe<AuthPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  createCurrentRiskToSite?: Maybe<CurrentRiskToSite>;
  createCurrentRiskToSiteList?: Maybe<Array<CurrentRiskToSite>>;
  updateCurrentRiskToSite?: Maybe<CurrentRiskToSite>;
  updateCurrentRiskToSiteList?: Maybe<Array<CurrentRiskToSite>>;
  createUpdateCurrentRiskToSiteFromBatiRegistre?: Maybe<CurrentRiskToSite>;
  createCurrentRiskToSiteForAllSite?: Maybe<Array<CurrentRiskToSite>>;
  createUpdateSpecificRiskToSite?: Maybe<SpecificRiskToSite>;
  createSpecificRisksToSiteInStepper?: Maybe<SpecificRiskToSite>;
  updateSpecificRisksToSiteInStepper?: Maybe<SpecificRiskToSite>;
  createUpdateSpecificRiskToSiteFromBatiRegistre?: Maybe<SpecificRiskToSite>;
  deleteSpecificRiskToSite?: Maybe<Array<SpecificRiskToSite>>;
  createUpdatePlan?: Maybe<Array<Plan>>;
  createUpdatePlanInStepper?: Maybe<Plan>;
  deletePlan?: Maybe<Plan>;
  savePlansOrder?: Maybe<Array<Plan>>;
  createUpdatePlanFromBatiRegistre?: Maybe<Plan>;
  createUpdateDocument?: Maybe<Document>;
  createUpdateDocumentInStepper?: Maybe<Document>;
  deleteDocument?: Maybe<Array<Maybe<Document>>>;
  createUpdateDocumentFromBatiRegistre?: Maybe<Document>;
  createCurrentRisk?: Maybe<CurrentRisk>;
  updateCurrentRisk?: Maybe<CurrentRisk>;
  deleteCurrentRisk?: Maybe<Array<Maybe<CurrentRisk>>>;
  createSpecificRisk?: Maybe<SpecificRisk>;
  updateSpecificRisk?: Maybe<SpecificRisk>;
  deleteSpecificRisk?: Maybe<Array<Maybe<SpecificRisk>>>;
  createPresignedUrlPut?: Maybe<PresignedUrl>;
  createPresignedUrlGet?: Maybe<PresignedUrl>;
  createUpdateDepartment?: Maybe<Department>;
  deleteDepartments?: Maybe<Array<Maybe<Department>>>;
  duplicateDepartmentWithEtare?: Maybe<Scalars['Boolean']>;
  recoveryUserDepartmentFromUserToDepartmentRelation?: Maybe<Scalars['Int']>;
  createUpdateSiteType?: Maybe<SiteType>;
  deleteSiteTypes?: Maybe<Array<SiteType>>;
  createUpdateTag?: Maybe<Tag>;
  deleteTags?: Maybe<Array<Tag>>;
  createUpdateMoyen?: Maybe<Moyen>;
  createUpdateMoyenInStepper?: Maybe<Moyen>;
  createUpdateMoyenFromBatiRegistre?: Maybe<Moyen>;
  deleteMoyen?: Maybe<Scalars['Boolean']>;
  createUpdateDangerousMaterial?: Maybe<DangerousMaterial>;
  deleteDangerousMaterials?: Maybe<Array<Maybe<DangerousMaterial>>>;
  createUpdateCategory?: Maybe<Category>;
  deleteCategories?: Maybe<Array<Category>>;
  createUpdateGedOpsDocument?: Maybe<DepartmentDocument>;
  deleteGedOpsDocument?: Maybe<Array<Maybe<DepartmentDocument>>>;
  createNotification?: Maybe<Notification>;
  updateNotificationById?: Maybe<Scalars['Boolean']>;
  updateDeviceStatus?: Maybe<Device>;
  updateDeviceCustomName?: Maybe<Device>;
  updateDeviceUser?: Maybe<Device>;
  updateDeviceDepartmentUser?: Maybe<Device>;
  getDeviceStatus?: Maybe<Scalars['Boolean']>;
  checkActiveDevice?: Maybe<Scalars['Boolean']>;
  createUpdateCommune?: Maybe<Commune>;
  deleteCommunes?: Maybe<Array<Maybe<Commune>>>;
  createUpdateFileSize?: Maybe<FileSize>;
  createUpdateBien?: Maybe<Bien>;
  deleteBien?: Maybe<Array<Maybe<Bien>>>;
  updateBienFait?: Maybe<Bien>;
  createUpdateBienFromBatiRegistre?: Maybe<Bien>;
  deleteFileFromBatiRegistre?: Maybe<Scalars['Boolean']>;
  createUpdateBienInStepper?: Maybe<Bien>;
};


export type MutationCreateUpdateSiteArgs = {
  input?: Maybe<InputCreateSite>;
};


export type MutationCreateUpdateSiteFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateSiteFromBatiRegistre>;
};


export type MutationCreateSiteFullInfoArgs = {
  input?: Maybe<InputCreateSiteFullInfo>;
};


export type MutationAcceptCreateRequestArgs = {
  input?: Maybe<InputAcceptCreateWithoutAuthoriGroupId>;
};


export type MutationAcceptEditRequestArgs = {
  input?: Maybe<InputAcceptCreateRequest>;
};


export type MutationRejectRequestArgs = {
  input?: Maybe<InputAcceptCreateRequest>;
};


export type MutationDeleteSiteArgs = {
  input?: Maybe<InputDeleteSite>;
};


export type MutationCheckReferenceArgs = {
  input?: Maybe<InputCheckReference>;
};


export type MutationDeleteSiteFileOnAwsArgs = {
  input?: Maybe<InputSiteFile>;
};


export type MutationCreateOrUpdateSiteGeneralInfoArgs = {
  input?: Maybe<InputCreateUpdateSiteGeneralInfo>;
};


export type MutationUpdateCoordinatesArgs = {
  input?: Maybe<InputUpdateCoordinates>;
};


export type MutationUpdateSensibilityArgs = {
  input?: Maybe<InputUpdateSensibility>;
};


export type MutationCreateUserArgs = {
  input?: Maybe<IInputCreateUser>;
};


export type MutationUpdateUserArgs = {
  input?: Maybe<IInputUpdateUser>;
};


export type MutationDeleteUserArgs = {
  input?: Maybe<InputDeleteUser>;
};


export type MutationUpdateUserProfilArgs = {
  input?: Maybe<InputUpdateUserProfil>;
};


export type MutationCreateUpdateContactArgs = {
  input?: Maybe<InputCreateUpdateContact>;
};


export type MutationCreateUpdateContactInStepperArgs = {
  input?: Maybe<InputCreateUpdateContactInStepper>;
};


export type MutationDeleteContactArgs = {
  input?: Maybe<InputDeleteContact>;
};


export type MutationCreateUpdateContactFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateContactFromBatiRegistre>;
};


export type MutationCreateUpdatePermissionArgs = {
  input?: Maybe<InputCreateUpdatePermission>;
};


export type MutationDeletePermissionArgs = {
  input?: Maybe<InputDeletePermission>;
};


export type MutationCreateUpdateUserGroupArgs = {
  input?: Maybe<InputCreateUserGroup>;
};


export type MutationDeleteUserGroupArgs = {
  input?: Maybe<InputDeleteGroup>;
};


export type MutationAddUserToGroupArgs = {
  input?: Maybe<InputAddUserToGroup>;
};


export type MutationRemoveUserFromGroupArgs = {
  input?: Maybe<InputRemoveUserFromGroup>;
};


export type MutationAddOrRemovePermissionFromGroupArgs = {
  input?: Maybe<InputManagePermissionInGroup>;
};


export type MutationGetGroupByIdArgs = {
  input?: Maybe<InputGetUserGroupById>;
};


export type MutationLoginArgs = {
  input?: Maybe<InputLogin>;
};


export type MutationResetPasswordArgs = {
  input?: Maybe<InputResetPassword>;
};


export type MutationForgotPasswordArgs = {
  input?: Maybe<InputForgotPassword>;
};


export type MutationCreateCurrentRiskToSiteArgs = {
  input?: Maybe<InputCreateCurrentRiskToSite>;
};


export type MutationCreateCurrentRiskToSiteListArgs = {
  input?: Maybe<InputCreateCurrentRiskToSiteList>;
};


export type MutationUpdateCurrentRiskToSiteArgs = {
  input?: Maybe<InputUpdateCurrentRiskToSite>;
};


export type MutationUpdateCurrentRiskToSiteListArgs = {
  input?: Maybe<InputUpdateCurrentRiskToSiteList>;
};


export type MutationCreateUpdateCurrentRiskToSiteFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateCurrentRiskToSiteFromBatiRegistre>;
};


export type MutationCreateUpdateSpecificRiskToSiteArgs = {
  input?: Maybe<InputCreateUpdateSpecificRiskToSite>;
};


export type MutationCreateSpecificRisksToSiteInStepperArgs = {
  input?: Maybe<InputCreateSpecificRisksToSiteInStepper>;
};


export type MutationUpdateSpecificRisksToSiteInStepperArgs = {
  input?: Maybe<InputUpdateSpecificRisksToSiteInStepper>;
};


export type MutationCreateUpdateSpecificRiskToSiteFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateSpecificRiskToSiteFromBatiRegistre>;
};


export type MutationDeleteSpecificRiskToSiteArgs = {
  input?: Maybe<InputDeleteSpecificRiskToSite>;
};


export type MutationCreateUpdatePlanArgs = {
  input?: Maybe<InputCreateUpdatePlan>;
};


export type MutationCreateUpdatePlanInStepperArgs = {
  input?: Maybe<InputCreateUpdatePlanInStepper>;
};


export type MutationDeletePlanArgs = {
  input?: Maybe<InputDeletePlan>;
};


export type MutationSavePlansOrderArgs = {
  input?: Maybe<InputSavePlansOrder>;
};


export type MutationCreateUpdatePlanFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdatePlanFromBatiRegistre>;
};


export type MutationCreateUpdateDocumentArgs = {
  input?: Maybe<InputCreateUpdateDocument>;
};


export type MutationCreateUpdateDocumentInStepperArgs = {
  input?: Maybe<InputCreateUpdateDocumentInStepper>;
};


export type MutationDeleteDocumentArgs = {
  input?: Maybe<InputDeleteDocument>;
};


export type MutationCreateUpdateDocumentFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateDocumentFromBatiRegistre>;
};


export type MutationCreateCurrentRiskArgs = {
  input?: Maybe<InputCreateCurrentRisk>;
};


export type MutationUpdateCurrentRiskArgs = {
  input?: Maybe<InputUpdateCurrentRisk>;
};


export type MutationDeleteCurrentRiskArgs = {
  input?: Maybe<InputDeleteCurrentRisk>;
};


export type MutationCreateSpecificRiskArgs = {
  input?: Maybe<InputCreateSpecificRisk>;
};


export type MutationUpdateSpecificRiskArgs = {
  input?: Maybe<InputUpdateSpecificRisk>;
};


export type MutationDeleteSpecificRiskArgs = {
  input?: Maybe<InputDeleteSpecificRisk>;
};


export type MutationCreatePresignedUrlPutArgs = {
  input?: Maybe<InputPresigneUrl>;
};


export type MutationCreatePresignedUrlGetArgs = {
  input?: Maybe<InputPresigneUrl>;
};


export type MutationCreateUpdateDepartmentArgs = {
  input?: Maybe<InputCreateUpdateDepartment>;
};


export type MutationDeleteDepartmentsArgs = {
  input?: Maybe<InputDeleteDepartment>;
};


export type MutationCreateUpdateSiteTypeArgs = {
  input?: Maybe<InputCreateUpdateSiteType>;
};


export type MutationDeleteSiteTypesArgs = {
  input?: Maybe<InputDeleteSiteTypes>;
};


export type MutationCreateUpdateTagArgs = {
  input?: Maybe<InputCreateUpdateTag>;
};


export type MutationDeleteTagsArgs = {
  input?: Maybe<InputDeleteTags>;
};


export type MutationCreateUpdateMoyenArgs = {
  input?: Maybe<InputCreateUpdateMoyen>;
};


export type MutationCreateUpdateMoyenInStepperArgs = {
  input?: Maybe<InputCreateUpdateMoyenInStepper>;
};


export type MutationCreateUpdateMoyenFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateMoyen>;
};


export type MutationDeleteMoyenArgs = {
  input?: Maybe<InputDeleteMoyen>;
};


export type MutationCreateUpdateDangerousMaterialArgs = {
  input?: Maybe<InputCreateUpdateDangerousMaterial>;
};


export type MutationDeleteDangerousMaterialsArgs = {
  input?: Maybe<InputDeleteDangerousMaterial>;
};


export type MutationCreateUpdateCategoryArgs = {
  input?: Maybe<InputCreateUpdateCategory>;
};


export type MutationDeleteCategoriesArgs = {
  input?: Maybe<InputDeleteCategories>;
};


export type MutationCreateUpdateGedOpsDocumentArgs = {
  input?: Maybe<InputCreateUpdateGedOpsDocument>;
};


export type MutationDeleteGedOpsDocumentArgs = {
  input?: Maybe<InputDeleteGedOpsDocument>;
};


export type MutationCreateNotificationArgs = {
  siteId: Scalars['String'];
};


export type MutationUpdateNotificationByIdArgs = {
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
};


export type MutationUpdateDeviceStatusArgs = {
  input?: Maybe<InputUpdateDeviceStatus>;
};


export type MutationUpdateDeviceCustomNameArgs = {
  input?: Maybe<InputCustomName>;
};


export type MutationUpdateDeviceUserArgs = {
  input?: Maybe<InputUpdateDevice>;
};


export type MutationUpdateDeviceDepartmentUserArgs = {
  input?: Maybe<InputDeviceDepartment>;
};


export type MutationGetDeviceStatusArgs = {
  input?: Maybe<InputCheckDeviceStatus>;
};


export type MutationCheckActiveDeviceArgs = {
  deviceSerialNumber?: Maybe<Scalars['String']>;
};


export type MutationCreateUpdateCommuneArgs = {
  input?: Maybe<InputCreateUpdateCommune>;
};


export type MutationDeleteCommunesArgs = {
  input?: Maybe<InputDeleteCommune>;
};


export type MutationCreateUpdateFileSizeArgs = {
  input?: Maybe<InputCreateUpdateFileSize>;
};


export type MutationCreateUpdateBienArgs = {
  input?: Maybe<InputCreateUpdateBien>;
};


export type MutationDeleteBienArgs = {
  input?: Maybe<InputDeleteBien>;
};


export type MutationUpdateBienFaitArgs = {
  input?: Maybe<InputUpdateBienFait>;
};


export type MutationCreateUpdateBienFromBatiRegistreArgs = {
  input?: Maybe<InputCreateUpdateBienFromBatiRegistre>;
};


export type MutationDeleteFileFromBatiRegistreArgs = {
  input?: Maybe<InputDeleteFileFromBatiRegistre>;
};


export type MutationCreateUpdateBienInStepperArgs = {
  input?: Maybe<InputCreateUpdateBienInStepper>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  department?: Maybe<Department>;
  isRead: Scalars['Boolean'];
  receiver_type?: Maybe<Receiver_Type>;
  sender?: Maybe<User>;
  notificationType?: Maybe<NotificationTypeEnum>;
  site?: Maybe<Site>;
};

export type NotificationPage = {
  __typename?: 'NotificationPage';
  data: Array<Maybe<Notification>>;
  pageInfo: PageInfo;
};

export type NotificationPageCurrsor = {
  __typename?: 'NotificationPageCurrsor';
  total?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgeNotification>>>;
  pageCursorInfo?: Maybe<NotificationPageCursorInfo>;
};

export type NotificationPageCursorInfo = {
  __typename?: 'NotificationPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  hidden: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  userGroups: Array<UserGroup>;
};


export type PermissionUserGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<UserGroupWhereUniqueInput>;
  after?: Maybe<UserGroupWhereUniqueInput>;
};

export type PermissionPageCursor = {
  __typename?: 'PermissionPageCursor';
  total?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgePermission>>>;
  pageCursorInfo?: Maybe<PermissionPageCursorInfo>;
};

export type PermissionPageCursorInfo = {
  __typename?: 'PermissionPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PermissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  siteId: Scalars['String'];
  isImage?: Maybe<Scalars['Boolean']>;
  tagId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
  order?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  encryptedPartBase64?: Maybe<Scalars['String']>;
  clearPartBase64?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  editedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlanHistory = {
  __typename?: 'PlanHistory';
  id: Scalars['String'];
  site?: Maybe<Site>;
  authorId: Scalars['String'];
  author: User;
  field: Scalars['String'];
  value: Scalars['String'];
  status: ValidationStatus;
};

export type PlanOrder = {
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type PlanPage = {
  __typename?: 'PlanPage';
  data: Array<Plan>;
  pageInfo?: Maybe<PageInfo>;
};

export type PlanPageCursor = {
  __typename?: 'PlanPageCursor';
  totalCount?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgePlan>>>;
  pageCursorInfo?: Maybe<PlanPageCursorInfo>;
};

export type PlanPageCursorInfo = {
  __typename?: 'PlanPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PlanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  filePath?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  syncAllCommunes?: Maybe<SyncAllCommunesTimeStampReturn>;
  syncCommunesEager?: Maybe<SyncCommunesChangesTimeStampReturn>;
  syncCommunesLazy?: Maybe<SyncCommunesChangesTimeStampReturn>;
  syncCommunesByDepartment?: Maybe<SyncCommunesByDepartmentChangesTimeStampReturn>;
  syncSitesByCommuneEager?: Maybe<SyncSitesByCommuneChangesTimeStampReturn>;
  syncSiteByReferenceEager?: Maybe<SyncSiteByReferenceEagerChangesTimeStampReturn>;
  syncDepartmentUser?: Maybe<SyncDepartmentUserChangesTimeStampReturn>;
  syncDangerousMaterial?: Maybe<SyncDangerousMaterialChangesTimeStampReturn>;
  syncGedopsDepartments?: Maybe<SyncGedopsDepartmentsChangesTimeStampReturn>;
  syncGedopsByDepartment?: Maybe<SyncGedopsByDepartmentChangesTimeStampReturn>;
  me?: Maybe<User>;
  users?: Maybe<UserPage>;
  getUserById?: Maybe<User>;
  getUsersByCurrentUser?: Maybe<UserPage>;
  allSites?: Maybe<SitePage>;
  getSiteById?: Maybe<Site>;
  getAllSitesByCommune?: Maybe<Array<Site>>;
  getSiteByReference?: Maybe<Site>;
  getSiteBySiteId?: Maybe<Site>;
  getExportSites?: Maybe<ExportSites>;
  getExportSiteBySiteId?: Maybe<ExportSiteBySiteId>;
  doExist?: Maybe<Site>;
  getAllCreationRequest?: Maybe<Array<Site>>;
  getAllEditRequest?: Maybe<Array<Maybe<SiteHistoryPage>>>;
  checkHistoriesData?: Maybe<HistoryDataReturn>;
  getValidationRequestCount?: Maybe<ValidationRequestCountReturn>;
  contact?: Maybe<Contact>;
  contactsBySite?: Maybe<ContactsPage>;
  getAllContactsBySite?: Maybe<Array<Contact>>;
  getContactHistories?: Maybe<Array<Maybe<Contact>>>;
  getAllGroups?: Maybe<UserGroupPageCursor>;
  getGroup?: Maybe<UserGroup>;
  userGroupListByConnectedUserGroup?: Maybe<Array<Maybe<UserGroup>>>;
  groupToAuthorized?: Maybe<Array<Maybe<UserGroup>>>;
  getAllPermissions?: Maybe<PermissionPageCursor>;
  getPermission?: Maybe<Permission>;
  getCurrentRisksToSite?: Maybe<Array<Maybe<CurrentRiskToSite>>>;
  getAllCurrentRiskBySite?: Maybe<Array<CurrentRiskToSite>>;
  getCurrentRiskHistories?: Maybe<Array<Maybe<CurrentRiskToSite>>>;
  getCurrentRisks?: Maybe<CurrentRiskPage>;
  getAllCurrentRisks?: Maybe<Array<Maybe<CurrentRisk>>>;
  getCurrentRisk?: Maybe<CurrentRisk>;
  getSpecificRisksToSite?: Maybe<SpecificRiskToSitePage>;
  getAllSpecificRiskBySite?: Maybe<Array<SpecificRiskToSite>>;
  getNotAddedSpecificRisksToSite?: Maybe<Array<SpecificRisk>>;
  getSpecificRiskHistories?: Maybe<Array<Maybe<SpecificRiskToSite>>>;
  getSpecificRisks?: Maybe<SpecificRiskPage>;
  getAllSpecificRisks?: Maybe<Array<SpecificRisk>>;
  getSpecificRisk?: Maybe<SpecificRisk>;
  getAllCommunesByDepartment?: Maybe<Array<Commune>>;
  getAllCommunes?: Maybe<CommunesPage>;
  getCommunes?: Maybe<CommunesPage>;
  getPlansBySite?: Maybe<PlanPage>;
  getAllPlansBySite?: Maybe<Array<Plan>>;
  getPlanHistories?: Maybe<Array<Maybe<Plan>>>;
  getDocumentsBySite?: Maybe<DocumentPage>;
  getAllDocumentsBySite?: Maybe<Array<Document>>;
  getDocumentHistories?: Maybe<Array<Maybe<Document>>>;
  allDepartment?: Maybe<DepartmentPage>;
  getAllDepartmentsByCurrentUser?: Maybe<Array<Department>>;
  getUserDepartments?: Maybe<Array<DepartmentUser>>;
  getAllDepartment?: Maybe<DepartmentPage>;
  getAllDepartments?: Maybe<Array<Department>>;
  getDepartmentById?: Maybe<Department>;
  getBatiregistreDepartments?: Maybe<Array<Maybe<Department>>>;
  getAllReadOnlyDepartmentsByUserId?: Maybe<Array<Department>>;
  getSelectedUserDefaultDepartment?: Maybe<Array<Maybe<Department>>>;
  getAllDepartmentsByUserId?: Maybe<Array<Department>>;
  getAllDepartmentUserByUser?: Maybe<Array<DepartmentUser>>;
  getAllSiteTypes?: Maybe<Array<SiteType>>;
  getSiteTypes?: Maybe<SiteTypePage>;
  getAllTags?: Maybe<Array<Tag>>;
  getTags?: Maybe<TagPage>;
  getMoyenBySite?: Maybe<Moyen>;
  getAllMoyen?: Maybe<Array<Maybe<Moyen>>>;
  getMoyens?: Maybe<MoyenPage>;
  getMoyenHistory?: Maybe<Moyen>;
  getAllDangerousMaterial?: Maybe<DangerousMaterialPage>;
  getAllCategories?: Maybe<Array<Category>>;
  getCategories?: Maybe<CategoryPage>;
  getGedOpsDocuments?: Maybe<DepartmentDocumentPage>;
  getGedOpsDocument?: Maybe<DepartmentDocument>;
  getCurrentUserNotifications?: Maybe<NotificationPage>;
  getDevices?: Maybe<DevicePage>;
  getDeviceBySerialNumber?: Maybe<Device>;
  getCurrentDevice?: Maybe<Device>;
  getFileSize?: Maybe<FileSize>;
  getAllBien?: Maybe<BienPage>;
  getBienById?: Maybe<Bien>;
  getBienBySite?: Maybe<BienPage>;
  getBienHistory?: Maybe<Array<Maybe<Bien>>>;
  getLogs?: Maybe<LogsPage>;
};


export type QuerySyncAllCommunesArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
};


export type QuerySyncCommunesEagerArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
};


export type QuerySyncCommunesLazyArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
};


export type QuerySyncCommunesByDepartmentArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
  departmentId?: Maybe<Scalars['ID']>;
};


export type QuerySyncSitesByCommuneEagerArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
  communeId?: Maybe<Scalars['ID']>;
};


export type QuerySyncSiteByReferenceEagerArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
};


export type QuerySyncDepartmentUserArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
};


export type QuerySyncDangerousMaterialArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
};


export type QuerySyncGedopsDepartmentsArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
};


export type QuerySyncGedopsByDepartmentArgs = {
  lastPulledAt?: Maybe<Scalars['DateTime']>;
  departmentId?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  input?: Maybe<InputFilterUsers>;
};


export type QueryGetUserByIdArgs = {
  input?: Maybe<InputUser>;
};


export type QueryGetUsersByCurrentUserArgs = {
  input?: Maybe<InputGetUsersByCurrentUser>;
};


export type QueryAllSitesArgs = {
  input?: Maybe<InputGetAllSite>;
};


export type QueryGetSiteByIdArgs = {
  input?: Maybe<InputGetSiteById>;
};


export type QueryGetAllSitesByCommuneArgs = {
  input?: Maybe<InputGetAllSitesByCommune>;
};


export type QueryGetSiteByReferenceArgs = {
  input?: Maybe<InputGetSiteByReference>;
};


export type QueryGetSiteBySiteIdArgs = {
  input?: Maybe<InputGetSiteBySiteId>;
};


export type QueryGetExportSitesArgs = {
  input?: Maybe<InputGetExportSites>;
};


export type QueryGetExportSiteBySiteIdArgs = {
  input?: Maybe<InputGetExportSiteBySiteId>;
};


export type QueryDoExistArgs = {
  input?: Maybe<InputGetSiteByReference>;
};


export type QueryCheckHistoriesDataArgs = {
  input?: Maybe<InputHistoryData>;
};


export type QueryContactArgs = {
  input?: Maybe<InputGetContact>;
};


export type QueryContactsBySiteArgs = {
  input?: Maybe<InputGetContactsBySite>;
};


export type QueryGetAllContactsBySiteArgs = {
  input?: Maybe<InputGetAllContactsBySite>;
};


export type QueryGetContactHistoriesArgs = {
  input?: Maybe<InputHistories>;
};


export type QueryGetAllGroupsArgs = {
  input?: Maybe<InputGetGroups>;
};


export type QueryGetGroupArgs = {
  input?: Maybe<InputGetGroup>;
};


export type QueryGetAllPermissionsArgs = {
  input?: Maybe<InputGetAllPermissions>;
};


export type QueryGetPermissionArgs = {
  input?: Maybe<InputGetPermission>;
};


export type QueryGetCurrentRisksToSiteArgs = {
  input?: Maybe<InputGetCurrentRisksToSite>;
};


export type QueryGetAllCurrentRiskBySiteArgs = {
  input?: Maybe<InputGetAllCurrentRiskBySite>;
};


export type QueryGetCurrentRiskHistoriesArgs = {
  input?: Maybe<InputCurrentRiskHistories>;
};


export type QueryGetCurrentRisksArgs = {
  input?: Maybe<InputGetCurrentRisks>;
};


export type QueryGetCurrentRiskArgs = {
  input?: Maybe<InputGetCurrentRisk>;
};


export type QueryGetSpecificRisksToSiteArgs = {
  input?: Maybe<InputGetSpecificRisksToSite>;
};


export type QueryGetAllSpecificRiskBySiteArgs = {
  input?: Maybe<InputGetAllSpecificRiskBySite>;
};


export type QueryGetNotAddedSpecificRisksToSiteArgs = {
  input?: Maybe<InputGetNotAddedSpecificRisksToSite>;
};


export type QueryGetSpecificRiskHistoriesArgs = {
  input?: Maybe<InputSpecificRiskHistories>;
};


export type QueryGetSpecificRisksArgs = {
  input?: Maybe<InputGetSpecificRisks>;
};


export type QueryGetSpecificRiskArgs = {
  input?: Maybe<InputGetSpecificRisk>;
};


export type QueryGetAllCommunesByDepartmentArgs = {
  input?: Maybe<InputGetAllCommunesByDepartment>;
};


export type QueryGetAllCommunesArgs = {
  input?: Maybe<InputGetCommunes>;
};


export type QueryGetCommunesArgs = {
  input?: Maybe<InputGetCommunes>;
};


export type QueryGetPlansBySiteArgs = {
  input?: Maybe<InputGetPlansBySite>;
};


export type QueryGetAllPlansBySiteArgs = {
  input?: Maybe<InputGetAllPlansBySite>;
};


export type QueryGetPlanHistoriesArgs = {
  input?: Maybe<InputPlanHistories>;
};


export type QueryGetDocumentsBySiteArgs = {
  input?: Maybe<InputGetDocumentsBySite>;
};


export type QueryGetAllDocumentsBySiteArgs = {
  input?: Maybe<InputGetAllDocumentsBySite>;
};


export type QueryGetDocumentHistoriesArgs = {
  input?: Maybe<InputHistories>;
};


export type QueryAllDepartmentArgs = {
  input?: Maybe<InputGetAllDepartment>;
};


export type QueryGetAllDepartmentsByCurrentUserArgs = {
  input?: Maybe<InputGetDepartment>;
};


export type QueryGetAllDepartmentArgs = {
  input?: Maybe<InputGetAllDepartment>;
};


export type QueryGetDepartmentByIdArgs = {
  input?: Maybe<InputGetDepartmentById>;
};


export type QueryGetAllReadOnlyDepartmentsByUserIdArgs = {
  input?: Maybe<InputGetAllReadOnlyDepartmentsByUserId>;
};


export type QueryGetSelectedUserDefaultDepartmentArgs = {
  input?: Maybe<InputGetAllReadOnlyDepartmentsByUserId>;
};


export type QueryGetAllDepartmentsByUserIdArgs = {
  input?: Maybe<InputGetAllDepartmentsByUserId>;
};


export type QueryGetAllDepartmentUserByUserArgs = {
  input?: Maybe<InputGetAllDepartmentUserByUser>;
};


export type QueryGetSiteTypesArgs = {
  input?: Maybe<InputGetSiteTypes>;
};


export type QueryGetTagsArgs = {
  input?: Maybe<InputGetTags>;
};


export type QueryGetMoyenBySiteArgs = {
  input?: Maybe<InputGetMoyenBySite>;
};


export type QueryGetMoyensArgs = {
  input?: Maybe<InputGetAllMoyen>;
};


export type QueryGetMoyenHistoryArgs = {
  input?: Maybe<InputMoyenHistory>;
};


export type QueryGetAllDangerousMaterialArgs = {
  input?: Maybe<InputGetAllDangerousMaterial>;
};


export type QueryGetCategoriesArgs = {
  input?: Maybe<InputGetCategories>;
};


export type QueryGetGedOpsDocumentsArgs = {
  input?: Maybe<InputGetGedOpsDocuments>;
};


export type QueryGetGedOpsDocumentArgs = {
  input?: Maybe<InputGetGedOpsDocument>;
};


export type QueryGetCurrentUserNotificationsArgs = {
  input?: Maybe<InputGetUserNotifications>;
};


export type QueryGetDevicesArgs = {
  input?: Maybe<InputGetDevices>;
};


export type QueryGetDeviceBySerialNumberArgs = {
  input?: Maybe<InputGetDeviceBySerialNumber>;
};


export type QueryGetCurrentDeviceArgs = {
  input?: Maybe<InputGetCurrentDevice>;
};


export type QueryGetAllBienArgs = {
  input?: Maybe<InputGetAllBien>;
};


export type QueryGetBienByIdArgs = {
  input?: Maybe<InputGetBienById>;
};


export type QueryGetBienBySiteArgs = {
  input?: Maybe<InputGetBienBySite>;
};


export type QueryGetBienHistoryArgs = {
  input?: Maybe<InputBienHistory>;
};


export type QueryGetLogsArgs = {
  input?: Maybe<InputGetLogs>;
};

export type Receiver_Type =
  | 'CLIENT'
  | 'OPERATEUR_SDIS'
  | 'SUPERVISEUR_SDIS'
  | 'ADMINISTRATEUR_BATIFIRE';

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  passwordReseted?: Maybe<Scalars['Boolean']>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['String'];
  reference: Scalars['String'];
  name: Scalars['String'];
  riskLevel?: Maybe<Scalars['Int']>;
  address: Scalars['String'];
  activity: Scalars['String'];
  ranking: Scalars['String'];
  etare: Scalars['Boolean'];
  referenceEtare?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  status?: Maybe<ValidationStatus>;
  town?: Maybe<Scalars['String']>;
  coordinates: Scalars['String'];
  isFromBatiregistre: Scalars['Boolean'];
  path?: Maybe<Scalars['String']>;
  authorizedGroups: Array<UserGroup>;
  groupAuthorized?: Maybe<Scalars['String']>;
  managePasswordBySupervisor: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  siteType?: Maybe<SiteType>;
  department?: Maybe<Department>;
  communeId: Scalars['String'];
  siteTypeId?: Maybe<Scalars['String']>;
  commune: Commune;
  currentRisks: Array<CurrentRiskToSite>;
  specificRisks: Array<SpecificRiskToSite>;
  siteHistories?: Maybe<Array<Maybe<SiteHistory>>>;
  planHistories?: Maybe<Scalars['Boolean']>;
  documentHistories?: Maybe<Scalars['Boolean']>;
  contactHistories?: Maybe<Scalars['Boolean']>;
  currentRisksHistories?: Maybe<Scalars['Boolean']>;
  specificRisksHistories?: Maybe<Scalars['Boolean']>;
  plans: Array<Plan>;
  contacts: Array<Contact>;
  documents: Array<Document>;
  author?: Maybe<User>;
  sensible: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  passwordDecrypted?: Maybe<Scalars['String']>;
  biens: Array<Bien>;
  bienHistory?: Maybe<Scalars['Boolean']>;
  moyen?: Maybe<Moyen>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
};


export type SiteAuthorizedGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<UserGroupWhereUniqueInput>;
  after?: Maybe<UserGroupWhereUniqueInput>;
};


export type SiteCurrentRisksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<CurrentRiskToSiteWhereUniqueInput>;
  after?: Maybe<CurrentRiskToSiteWhereUniqueInput>;
};


export type SiteSpecificRisksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<SpecificRiskToSiteWhereUniqueInput>;
  after?: Maybe<SpecificRiskToSiteWhereUniqueInput>;
};


export type SitePlansArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PlanWhereUniqueInput>;
  after?: Maybe<PlanWhereUniqueInput>;
};


export type SiteContactsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ContactWhereUniqueInput>;
  after?: Maybe<ContactWhereUniqueInput>;
};


export type SiteDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DocumentWhereUniqueInput>;
  after?: Maybe<DocumentWhereUniqueInput>;
};


export type SiteBiensArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<BienWhereUniqueInput>;
  after?: Maybe<BienWhereUniqueInput>;
};

export type SiteHistory = {
  __typename?: 'SiteHistory';
  id: Scalars['String'];
  site?: Maybe<Site>;
  authorId: Scalars['String'];
  author: User;
  field: Scalars['String'];
  value: Scalars['String'];
  status: ValidationStatus;
};

export type SiteHistoryPage = {
  __typename?: 'SiteHistoryPage';
  sites?: Maybe<Array<Maybe<Site>>>;
  editorGroupName?: Maybe<Scalars['String']>;
  editorId?: Maybe<Scalars['String']>;
};

export type SitePage = {
  __typename?: 'SitePage';
  data: Array<Site>;
  pageInfo?: Maybe<PageInfo>;
};

export type SitePageCurrsor = {
  __typename?: 'SitePageCurrsor';
  total?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<EdgeSite>>>;
  pageCursorInfo?: Maybe<SitePageCursorInfo>;
};

export type SitePageCursorInfo = {
  __typename?: 'SitePageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type SiteType = {
  __typename?: 'SiteType';
  id: Scalars['String'];
  name: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SiteTypePage = {
  __typename?: 'SiteTypePage';
  data: Array<SiteType>;
  pageInfo?: Maybe<PageInfo>;
};

export type SiteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type SpecificRisk = {
  __typename?: 'SpecificRisk';
  id: Scalars['String'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type SpecificRiskPage = {
  __typename?: 'SpecificRiskPage';
  data: Array<SpecificRisk>;
  pageInfo?: Maybe<PageInfo>;
};

export type SpecificRiskToSite = {
  __typename?: 'SpecificRiskToSite';
  id: Scalars['String'];
  description: Scalars['String'];
  descriptionDecrypted?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  specificRiskId: Scalars['String'];
  specificRisk: SpecificRisk;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
};

export type SpecificRiskToSitePage = {
  __typename?: 'SpecificRiskToSitePage';
  data: Array<SpecificRiskToSite>;
  pageInfo?: Maybe<PageInfo>;
};

export type SpecificRiskToSiteSiteIdSpecificRiskIdCompoundUniqueInput = {
  siteId: Scalars['String'];
  specificRiskId: Scalars['String'];
};

export type SpecificRiskToSiteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  siteId_specificRiskId?: Maybe<SpecificRiskToSiteSiteIdSpecificRiskIdCompoundUniqueInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  editUser?: Maybe<User>;
  /** Send notification to the connected device about device status */
  disableDeviceSubscription: Device;
  /** Send notification on site created for all groups expect supervisor */
  createNewSiteSubscription: Notification;
  getUpdatedNotifications?: Maybe<NotificationPage>;
  getValidationRequestCount?: Maybe<ValidationRequestCountReturn>;
};


export type SubscriptionEditUserArgs = {
  userId: Scalars['String'];
};


export type SubscriptionDisableDeviceSubscriptionArgs = {
  input?: Maybe<InputDisableDeviceSubscription>;
};


export type SubscriptionCreateNewSiteSubscriptionArgs = {
  input?: Maybe<InputCreateSiteSubscription>;
};


export type SubscriptionGetValidationRequestCountArgs = {
  input?: Maybe<InputGetAllValidationRequestCount>;
};

export type SuccessReturn = {
  __typename?: 'SuccessReturn';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type SyncAllCommunesReturn = {
  __typename?: 'SyncAllCommunesReturn';
  Commune: SyncCommunesChangesReturn;
};

export type SyncAllCommunesTimeStampReturn = {
  __typename?: 'SyncAllCommunesTimeStampReturn';
  changes: SyncAllCommunesReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncBienChangesReturn = {
  __typename?: 'SyncBienChangesReturn';
  created: Array<Maybe<Bien>>;
  updated: Array<Maybe<Bien>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCategoryChangesReturn = {
  __typename?: 'SyncCategoryChangesReturn';
  created: Array<Maybe<Category>>;
  updated: Array<Maybe<Category>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCommuneByUserChangesReturn = {
  __typename?: 'SyncCommuneByUserChangesReturn';
  created: Array<Maybe<Commune>>;
  updated: Array<Maybe<Commune>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCommunesByDepartmentChangesTimeStampReturn = {
  __typename?: 'SyncCommunesByDepartmentChangesTimeStampReturn';
  changes: SyncCommunesByDepartmentReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncCommunesByDepartmentReturn = {
  __typename?: 'SyncCommunesByDepartmentReturn';
  Commune: SyncCommunesChangesReturn;
};

export type SyncCommunesChangesReturn = {
  __typename?: 'SyncCommunesChangesReturn';
  created: Array<Maybe<Commune>>;
  updated: Array<Maybe<Commune>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCommunesChangesTimeStampReturn = {
  __typename?: 'SyncCommunesChangesTimeStampReturn';
  changes: SyncCommunesReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncCommunesReturn = {
  __typename?: 'SyncCommunesReturn';
  Commune: SyncCommunesChangesReturn;
  Site: SyncSitesChangesReturn;
  CurrentRisk: SyncCurrentRiskChangesReturn;
  SpecificRisk: SyncSpecificRiskChangesReturn;
};

export type SyncContactChangesReturn = {
  __typename?: 'SyncContactChangesReturn';
  created: Array<Maybe<Contact>>;
  updated: Array<Maybe<Contact>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCurrentRiskChangesReturn = {
  __typename?: 'SyncCurrentRiskChangesReturn';
  created: Array<Maybe<CurrentRisk>>;
  updated: Array<Maybe<CurrentRisk>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncCurrentRiskToSiteChangesReturn = {
  __typename?: 'SyncCurrentRiskToSiteChangesReturn';
  created: Array<Maybe<CurrentRiskToSite>>;
  updated: Array<Maybe<CurrentRiskToSite>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncDangerousMaterialChangesReturn = {
  __typename?: 'SyncDangerousMaterialChangesReturn';
  created: Array<Maybe<DangerousMaterial>>;
  updated: Array<Maybe<DangerousMaterial>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncDangerousMaterialChangesTimeStampReturn = {
  __typename?: 'SyncDangerousMaterialChangesTimeStampReturn';
  changes: SyncDangerousMaterialReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncDangerousMaterialReturn = {
  __typename?: 'SyncDangerousMaterialReturn';
  DangerousMaterial: SyncDangerousMaterialChangesReturn;
};

export type SyncDepartmentChangesReturn = {
  __typename?: 'SyncDepartmentChangesReturn';
  created: Array<Maybe<Department>>;
  updated: Array<Maybe<Department>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncDepartmentUserChangesReturn = {
  __typename?: 'SyncDepartmentUserChangesReturn';
  created: Array<Maybe<DepartmentUser>>;
  updated: Array<Maybe<DepartmentUser>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncDepartmentUserChangesTimeStampReturn = {
  __typename?: 'SyncDepartmentUserChangesTimeStampReturn';
  changes: SyncDepartmentUserReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncDepartmentUserReturn = {
  __typename?: 'SyncDepartmentUserReturn';
  DepartmentUser: SyncDepartmentUserChangesReturn;
  CurrentRisk: SyncCurrentRiskChangesReturn;
  SpecificRisk: SyncSpecificRiskChangesReturn;
  SiteType: SyncSiteTypeChangesReturn;
  Department: SyncDepartmentChangesReturn;
  Commune: SyncCommuneByUserChangesReturn;
  Device: SyncDeviceByUserChangesReturn;
};

export type SyncDeviceByUserChangesReturn = {
  __typename?: 'SyncDeviceByUserChangesReturn';
  created: Array<Maybe<Device>>;
  updated: Array<Maybe<Device>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncDocumentSiteChangesReturn = {
  __typename?: 'SyncDocumentSiteChangesReturn';
  created: Array<Maybe<Document>>;
  updated: Array<Maybe<Document>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncGedopsByDepartmentChangesReturn = {
  __typename?: 'SyncGedopsByDepartmentChangesReturn';
  created: Array<Maybe<DepartmentDocument>>;
  updated: Array<Maybe<DepartmentDocument>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncGedopsByDepartmentChangesTimeStampReturn = {
  __typename?: 'SyncGedopsByDepartmentChangesTimeStampReturn';
  changes: SyncGedopsByDepartmentReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncGedopsByDepartmentReturn = {
  __typename?: 'SyncGedopsByDepartmentReturn';
  DepartmentDocument: SyncGedopsByDepartmentChangesReturn;
};

export type SyncGedopsDepartmentsChangesTimeStampReturn = {
  __typename?: 'SyncGedopsDepartmentsChangesTimeStampReturn';
  changes: SyncGedopsDepartmentsReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncGedopsDepartmentsReturn = {
  __typename?: 'SyncGedopsDepartmentsReturn';
  DepartmentUser: SyncDepartmentUserChangesReturn;
  Category: SyncCategoryChangesReturn;
};

export type SyncMoyenChangesReturn = {
  __typename?: 'SyncMoyenChangesReturn';
  created: Array<Maybe<Moyen>>;
  updated: Array<Maybe<Moyen>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncPlanSiteChangesReturn = {
  __typename?: 'SyncPlanSiteChangesReturn';
  created: Array<Maybe<Plan>>;
  updated: Array<Maybe<Plan>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncSiteByReferenceEagerChangesTimeStampReturn = {
  __typename?: 'SyncSiteByReferenceEagerChangesTimeStampReturn';
  changes: SyncSiteReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncSiteReturn = {
  __typename?: 'SyncSiteReturn';
  Site: SyncSitesChangesReturn;
  CurrentRiskToSite: SyncCurrentRiskToSiteChangesReturn;
  SpecificRiskToSite: SyncSpecificRiskToSiteChangesReturn;
  Contact: SyncContactChangesReturn;
  Bien: SyncBienChangesReturn;
  Plan: SyncPlanSiteChangesReturn;
  CurrentRisk: SyncCurrentRiskChangesReturn;
  SpecificRisk: SyncSpecificRiskChangesReturn;
  Document: SyncDocumentSiteChangesReturn;
  SiteType: SyncSiteTypeChangesReturn;
  Moyen: SyncMoyenChangesReturn;
  Commune: SyncCommunesChangesReturn;
  Department: SyncDepartmentChangesReturn;
};

export type SyncSiteTypeChangesReturn = {
  __typename?: 'SyncSiteTypeChangesReturn';
  created: Array<Maybe<SiteType>>;
  updated: Array<Maybe<SiteType>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncSitesByCommuneChangesTimeStampReturn = {
  __typename?: 'SyncSitesByCommuneChangesTimeStampReturn';
  changes: SyncSitesReturn;
  timestamp: Scalars['DateTime'];
};

export type SyncSitesChangesReturn = {
  __typename?: 'SyncSitesChangesReturn';
  created: Array<Maybe<Site>>;
  updated: Array<Maybe<Site>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncSitesReturn = {
  __typename?: 'SyncSitesReturn';
  Site: SyncSitesChangesReturn;
  CurrentRiskToSite: SyncCurrentRiskToSiteChangesReturn;
  SpecificRiskToSite: SyncSpecificRiskToSiteChangesReturn;
  Contact: SyncContactChangesReturn;
  Bien: SyncBienChangesReturn;
  Plan: SyncPlanSiteChangesReturn;
  Document: SyncDocumentSiteChangesReturn;
  Moyen: SyncMoyenChangesReturn;
};

export type SyncSpecificRiskChangesReturn = {
  __typename?: 'SyncSpecificRiskChangesReturn';
  created: Array<Maybe<SpecificRisk>>;
  updated: Array<Maybe<SpecificRisk>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type SyncSpecificRiskToSiteChangesReturn = {
  __typename?: 'SyncSpecificRiskToSiteChangesReturn';
  created: Array<Maybe<SpecificRiskToSite>>;
  updated: Array<Maybe<SpecificRiskToSite>>;
  deleted: Array<Maybe<Scalars['ID']>>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TagPage = {
  __typename?: 'TagPage';
  data: Array<Tag>;
  pageInfo?: Maybe<PageInfo>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroup?: Maybe<UserGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  device: Array<Device>;
  firstName?: Maybe<Scalars['String']>;
  accountName: Scalars['String'];
  email: Scalars['String'];
  users: Array<User>;
  author?: Maybe<User>;
  departments: Array<Department>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  accountStatus?: Maybe<AccountStatusEnum>;
  departmentUser: Array<DepartmentUser>;
  defaultDepartments?: Maybe<Array<Maybe<Department>>>;
  getCreateNotification?: Maybe<Scalars['Boolean']>;
  getUpdateNotification?: Maybe<Scalars['Boolean']>;
};


export type UserDeviceArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DeviceWhereUniqueInput>;
  after?: Maybe<DeviceWhereUniqueInput>;
};


export type UserUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<UserWhereUniqueInput>;
  after?: Maybe<UserWhereUniqueInput>;
};


export type UserDepartmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DepartmentWhereUniqueInput>;
  after?: Maybe<DepartmentWhereUniqueInput>;
};


export type UserDepartmentUserArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DepartmentUserWhereUniqueInput>;
  after?: Maybe<DepartmentUserWhereUniqueInput>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  deleted: Scalars['Boolean'];
  users: Array<User>;
  permissions: Array<Permission>;
  otherUserGroupIdList: Array<Scalars['String']>;
};


export type UserGroupUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<UserWhereUniqueInput>;
  after?: Maybe<UserWhereUniqueInput>;
};


export type UserGroupPermissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PermissionWhereUniqueInput>;
  after?: Maybe<PermissionWhereUniqueInput>;
};

export type UserGroupPageCursor = {
  __typename?: 'UserGroupPageCursor';
  total?: Maybe<Scalars['Int']>;
  edges: Array<EdgeUserGroup>;
  pageCursorInfo?: Maybe<UserGroupPageCursorInfo>;
};

export type UserGroupPageCursorInfo = {
  __typename?: 'UserGroupPageCursorInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type UserGroupPermission = {
  __typename?: 'UserGroupPermission';
  A: Scalars['String'];
  B: Scalars['String'];
};

export type UserGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserPage = {
  __typename?: 'UserPage';
  data: Array<User>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ValidationRequestCountReturn = {
  __typename?: 'ValidationRequestCountReturn';
  create?: Maybe<Scalars['Int']>;
  edit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ValidationStatus =
  | 'PENDING'
  | 'ACCEPTED'
  | 'REJECTED';

export type CheckReferenceReturn = {
  __typename?: 'checkReferenceReturn';
  reference?: Maybe<Scalars['String']>;
  isDuplicated?: Maybe<Scalars['Boolean']>;
};

export type ModelFileTypeEnum =
  | 'PLAN'
  | 'DOCUMENT'
  | 'BIEN';

export type NotificationTypeEnum =
  | 'CREATION_REQUEST'
  | 'MODIFICATION_REQUEST'
  | 'EDIT_REQUEST_ACCEPTED'
  | 'EDIT_REQUEST_CANCELED'
  | 'CREATION_REQUEST_ACCEPTED'
  | 'CREATION_REQUEST_CANCELED'
  | 'CREATION_ADMIN'
  | 'MODIFICATION_ADMIN';

export type QueryTypeEnum =
  | 'ALL'
  | 'IN_NEW_USER_FORM'
  | 'IN_USER_LIST';

export type ReceiverTypeEnum =
  | 'CLIENT'
  | 'OPERATEUR_SDIS'
  | 'SUPERVISEUR_SDIS'
  | 'ADMINISTRATEUR_BATIFIRE';

export type CreateUpdateBienMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateBien>;
}>;


export type CreateUpdateBienMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateBien?: Maybe<(
    { __typename?: 'Bien' }
    & BienFragmentFragment
  )> }
);

export type DeleteBienMutationVariables = Exact<{
  input?: Maybe<InputDeleteBien>;
}>;


export type DeleteBienMutation = (
  { __typename?: 'Mutation' }
  & { deleteBien?: Maybe<Array<Maybe<(
    { __typename?: 'Bien' }
    & BienFragmentFragment
  )>>> }
);

export type CreateUpdateBienInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateBienInStepper>;
}>;


export type CreateUpdateBienInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateBienInStepper?: Maybe<(
    { __typename?: 'Bien' }
    & BienFragmentFragment
  )> }
);

export type BienFragmentFragment = (
  { __typename?: 'Bien' }
  & Pick<Bien, 'id' | 'name' | 'level' | 'priority' | 'fait' | 'location' | 'url' | 'siteId'>
);

export type GetAllBienQueryVariables = Exact<{
  input?: Maybe<InputGetAllBien>;
}>;


export type GetAllBienQuery = (
  { __typename?: 'Query' }
  & { getAllBien?: Maybe<(
    { __typename?: 'BienPage' }
    & { data: Array<(
      { __typename?: 'Bien' }
      & BienFragmentFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetBienByIdQueryVariables = Exact<{
  input?: Maybe<InputGetBienById>;
}>;


export type GetBienByIdQuery = (
  { __typename?: 'Query' }
  & { getBienById?: Maybe<(
    { __typename?: 'Bien' }
    & BienFragmentFragment
  )> }
);

export type GetBienBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetBienBySite>;
}>;


export type GetBienBySiteQuery = (
  { __typename?: 'Query' }
  & { getBienBySite?: Maybe<(
    { __typename?: 'BienPage' }
    & { data: Array<(
      { __typename?: 'Bien' }
      & BienFragmentFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetBienHistoryQueryVariables = Exact<{
  input?: Maybe<InputBienHistory>;
}>;


export type GetBienHistoryQuery = (
  { __typename?: 'Query' }
  & { getBienHistory?: Maybe<Array<Maybe<(
    { __typename?: 'Bien' }
    & BienFragmentFragment
  )>>> }
);

export type CategoryFragmentFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'deleted' | 'updatedAt' | 'createdAt'>
);

export type DeleteCategoriesMutationVariables = Exact<{
  input?: Maybe<InputDeleteCategories>;
}>;


export type DeleteCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  )>> }
);

export type CreateUpdateCategoryMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateCategory>;
}>;


export type CreateUpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateCategory?: Maybe<(
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  )> }
);

export type GetCategoriesQueryVariables = Exact<{
  input?: Maybe<InputGetCategories>;
}>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCategories?: Maybe<(
    { __typename?: 'CategoryPage' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )>, data: Array<(
      { __typename?: 'Category' }
      & CategoryFragmentFragment
    )> }
  )> }
);

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = (
  { __typename?: 'Query' }
  & { getAllCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  )>> }
);

export type CreateUpdateCommuneMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateCommune>;
}>;


export type CreateUpdateCommuneMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateCommune?: Maybe<(
    { __typename?: 'Commune' }
    & Pick<Commune, 'id' | 'name' | 'zipCode' | 'regionCode' | 'departmentCode' | 'departmentId'>
  )> }
);

export type DeleteCommunesMutationVariables = Exact<{
  input?: Maybe<InputDeleteCommune>;
}>;


export type DeleteCommunesMutation = (
  { __typename?: 'Mutation' }
  & { deleteCommunes?: Maybe<Array<Maybe<(
    { __typename?: 'Commune' }
    & Pick<Commune, 'id'>
  )>>> }
);

export type GetAllCommunesByDepartmentQueryVariables = Exact<{
  input?: Maybe<InputGetAllCommunesByDepartment>;
}>;


export type GetAllCommunesByDepartmentQuery = (
  { __typename?: 'Query' }
  & { getAllCommunesByDepartment?: Maybe<Array<(
    { __typename?: 'Commune' }
    & Pick<Commune, 'id' | 'name' | 'zipCode' | 'regionCode' | 'departmentId'>
    & { department?: Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'name'>
    )> }
  )>> }
);

export type GetAllCommunesQueryVariables = Exact<{
  input?: Maybe<InputGetCommunes>;
}>;


export type GetAllCommunesQuery = (
  { __typename?: 'Query' }
  & { getAllCommunes?: Maybe<(
    { __typename?: 'CommunesPage' }
    & { data: Array<(
      { __typename?: 'Commune' }
      & Pick<Commune, 'id' | 'name' | 'zipCode' | 'regionCode' | 'departmentId'>
      & { department?: Maybe<(
        { __typename?: 'Department' }
        & Pick<Department, 'name'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetCommunesQueryVariables = Exact<{
  input?: Maybe<InputGetCommunes>;
}>;


export type GetCommunesQuery = (
  { __typename?: 'Query' }
  & { getCommunes?: Maybe<(
    { __typename?: 'CommunesPage' }
    & { data: Array<(
      { __typename?: 'Commune' }
      & Pick<Commune, 'id' | 'name' | 'zipCode' | 'regionCode' | 'departmentId'>
      & { department?: Maybe<(
        { __typename?: 'Department' }
        & Pick<Department, 'name'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type CreateUpdateContactMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateContact>;
}>;


export type CreateUpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'occupation' | 'email' | 'dayPhone' | 'nightPhone'>
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  input?: Maybe<InputDeleteContact>;
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'occupation' | 'email' | 'dayPhone' | 'nightPhone'>
  )>> }
);

export type CreateUpdateContactInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateContactInStepper>;
}>;


export type CreateUpdateContactInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateContactInStepper?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'occupation' | 'email' | 'dayPhone' | 'nightPhone'>
  )> }
);

export type GetContactsBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetContactsBySite>;
}>;


export type GetContactsBySiteQuery = (
  { __typename?: 'Query' }
  & { contactsBySite?: Maybe<(
    { __typename?: 'ContactsPage' }
    & { data: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'occupation' | 'email' | 'dayPhone' | 'nightPhone' | 'deleted'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetContactHistoriesQueryVariables = Exact<{
  input?: Maybe<InputHistories>;
}>;


export type GetContactHistoriesQuery = (
  { __typename?: 'Query' }
  & { getContactHistories?: Maybe<Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'occupation' | 'email' | 'dayPhone' | 'nightPhone' | 'deleted'>
  )>>> }
);

export type CreateCurrentRiskToSiteMutationVariables = Exact<{
  input?: Maybe<InputCreateCurrentRiskToSite>;
}>;


export type CreateCurrentRiskToSiteMutation = (
  { __typename?: 'Mutation' }
  & { createCurrentRiskToSite?: Maybe<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'level'>
  )> }
);

export type CreateCurrentRiskToSiteListMutationVariables = Exact<{
  input?: Maybe<InputCreateCurrentRiskToSiteList>;
}>;


export type CreateCurrentRiskToSiteListMutation = (
  { __typename?: 'Mutation' }
  & { createCurrentRiskToSiteList?: Maybe<Array<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'level'>
  )>> }
);

export type UpdateCurrentRiskToSiteMutationVariables = Exact<{
  input?: Maybe<InputUpdateCurrentRiskToSite>;
}>;


export type UpdateCurrentRiskToSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentRiskToSite?: Maybe<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'level'>
  )> }
);

export type UpdateCurrentRiskToSiteListMutationVariables = Exact<{
  input?: Maybe<InputUpdateCurrentRiskToSiteList>;
}>;


export type UpdateCurrentRiskToSiteListMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentRiskToSiteList?: Maybe<Array<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'level'>
  )>> }
);

export type CreateCurrentRiskToSiteForAllSiteMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateCurrentRiskToSiteForAllSiteMutation = (
  { __typename?: 'Mutation' }
  & { createCurrentRiskToSiteForAllSite?: Maybe<Array<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'createdAt' | 'updatedAt' | 'deleted'>
  )>> }
);

export type GetCurrentRisksToSiteQueryVariables = Exact<{
  input?: Maybe<InputGetCurrentRisksToSite>;
}>;


export type GetCurrentRisksToSiteQuery = (
  { __typename?: 'Query' }
  & { getCurrentRisksToSite?: Maybe<Array<Maybe<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'level' | 'siteId' | 'currentRiskId'>
    & { currentRisk: (
      { __typename?: 'CurrentRisk' }
      & Pick<CurrentRisk, 'id' | 'name' | 'path' | 'logoUrl' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )>>> }
);

export type GetCurrentRiskHistoriesQueryVariables = Exact<{
  input?: Maybe<InputCurrentRiskHistories>;
}>;


export type GetCurrentRiskHistoriesQuery = (
  { __typename?: 'Query' }
  & { getCurrentRiskHistories?: Maybe<Array<Maybe<(
    { __typename?: 'CurrentRiskToSite' }
    & Pick<CurrentRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'level' | 'siteId' | 'currentRiskId' | 'createdAt'>
    & { currentRisk: (
      { __typename?: 'CurrentRisk' }
      & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt'>
    ) }
  )>>> }
);

export type CreateUpdateDangerousMaterialMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateDangerousMaterial>;
}>;


export type CreateUpdateDangerousMaterialMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateDangerousMaterial?: Maybe<(
    { __typename?: 'DangerousMaterial' }
    & Pick<DangerousMaterial, 'id' | 'title' | 'description' | 'codeONU'>
  )> }
);

export type DeleteDangerousMaterialsMutationVariables = Exact<{
  input?: Maybe<InputDeleteDangerousMaterial>;
}>;


export type DeleteDangerousMaterialsMutation = (
  { __typename?: 'Mutation' }
  & { deleteDangerousMaterials?: Maybe<Array<Maybe<(
    { __typename?: 'DangerousMaterial' }
    & Pick<DangerousMaterial, 'id'>
  )>>> }
);

export type GetAllDangerousMaterialQueryVariables = Exact<{
  input?: Maybe<InputGetAllDangerousMaterial>;
}>;


export type GetAllDangerousMaterialQuery = (
  { __typename?: 'Query' }
  & { getAllDangerousMaterial?: Maybe<(
    { __typename?: 'DangerousMaterialPage' }
    & { data: Array<(
      { __typename?: 'DangerousMaterial' }
      & Pick<DangerousMaterial, 'id' | 'title' | 'description' | 'codeONU'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type CreateUpdateDepartmentMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateDepartment>;
}>;


export type CreateUpdateDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateDepartment?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'departmentCode' | 'displayBatiRegistre'>
  )> }
);

export type DeleteDepartmentsMutationVariables = Exact<{
  input?: Maybe<InputDeleteDepartment>;
}>;


export type DeleteDepartmentsMutation = (
  { __typename?: 'Mutation' }
  & { deleteDepartments?: Maybe<Array<Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id'>
  )>>> }
);

export type DuplicateDepartmentWithEtareMutationVariables = Exact<{ [key: string]: never; }>;


export type DuplicateDepartmentWithEtareMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'duplicateDepartmentWithEtare'>
);

export type GetAllDepartmentQueryVariables = Exact<{
  input?: Maybe<InputGetAllDepartment>;
}>;


export type GetAllDepartmentQuery = (
  { __typename?: 'Query' }
  & { getAllDepartment?: Maybe<(
    { __typename?: 'DepartmentPage' }
    & { data: Array<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name' | 'departmentCode' | 'displayBatiRegistre'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetAllDepartmentsByCurrentUserQueryVariables = Exact<{
  input?: Maybe<InputGetDepartment>;
}>;


export type GetAllDepartmentsByCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getAllDepartmentsByCurrentUser?: Maybe<Array<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'departmentCode'>
  )>> }
);

export type GetAllDepartmentsByUserIdQueryVariables = Exact<{
  input?: Maybe<InputGetAllDepartmentsByUserId>;
}>;


export type GetAllDepartmentsByUserIdQuery = (
  { __typename?: 'Query' }
  & { getAllDepartmentsByUserId?: Maybe<Array<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'departmentCode'>
  )>> }
);

export type AllDepartmentQueryVariables = Exact<{
  input?: Maybe<InputGetAllDepartment>;
}>;


export type AllDepartmentQuery = (
  { __typename?: 'Query' }
  & { allDepartment?: Maybe<(
    { __typename?: 'DepartmentPage' }
    & { data: Array<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name' | 'departmentCode' | 'displayBatiRegistre'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetUserDepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDepartmentsQuery = (
  { __typename?: 'Query' }
  & { getUserDepartments?: Maybe<Array<(
    { __typename?: 'DepartmentUser' }
    & Pick<DepartmentUser, 'id'>
  )>> }
);

export type GetDepartmentByIdQueryVariables = Exact<{
  input?: Maybe<InputGetDepartmentById>;
}>;


export type GetDepartmentByIdQuery = (
  { __typename?: 'Query' }
  & { getDepartmentById?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'departmentCode'>
  )> }
);

export type GetSelectedUserDefaultDepartmentQueryVariables = Exact<{
  input?: Maybe<InputGetAllReadOnlyDepartmentsByUserId>;
}>;


export type GetSelectedUserDefaultDepartmentQuery = (
  { __typename?: 'Query' }
  & { getSelectedUserDefaultDepartment?: Maybe<Array<Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )>>> }
);

export type GetAllReadOnlyDepartmentsByUserIdQueryVariables = Exact<{
  input?: Maybe<InputGetAllReadOnlyDepartmentsByUserId>;
}>;


export type GetAllReadOnlyDepartmentsByUserIdQuery = (
  { __typename?: 'Query' }
  & { getAllReadOnlyDepartmentsByUserId?: Maybe<Array<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name' | 'departmentCode'>
  )>> }
);

export type DeviceFragmentFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'id' | 'name' | 'serialNumber' | 'enabled' | 'customName'>
);

export type UpdateDeviceStatusMutationVariables = Exact<{
  input?: Maybe<InputUpdateDeviceStatus>;
}>;


export type UpdateDeviceStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateDeviceStatus?: Maybe<(
    { __typename?: 'Device' }
    & DeviceFragmentFragment
  )> }
);

export type UpdateDeviceCustomNameMutationVariables = Exact<{
  input?: Maybe<InputCustomName>;
}>;


export type UpdateDeviceCustomNameMutation = (
  { __typename?: 'Mutation' }
  & { updateDeviceCustomName?: Maybe<(
    { __typename?: 'Device' }
    & DeviceFragmentFragment
  )> }
);

export type GetDevicesQueryVariables = Exact<{
  input?: Maybe<InputGetDevices>;
}>;


export type GetDevicesQuery = (
  { __typename?: 'Query' }
  & { getDevices?: Maybe<(
    { __typename?: 'DevicePage' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )>, data: Array<(
      { __typename?: 'Device' }
      & DeviceFragmentFragment
    )> }
  )> }
);

export type DeleteDocumentMutationVariables = Exact<{
  input?: Maybe<InputDeleteDocument>;
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocument?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'url' | 'path' | 'type'>
  )>>> }
);

export type CreateUpdateDocumentMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateDocument>;
}>;


export type CreateUpdateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'url' | 'path' | 'type'>
  )> }
);

export type CreateUpdateDocumentInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateDocumentInStepper>;
}>;


export type CreateUpdateDocumentInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateDocumentInStepper?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'url' | 'path' | 'type'>
  )> }
);

export type GetDocumentsBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetDocumentsBySite>;
}>;


export type GetDocumentsBySiteQuery = (
  { __typename?: 'Query' }
  & { getDocumentsBySite?: Maybe<(
    { __typename?: 'DocumentPage' }
    & { data: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'name' | 'path' | 'url' | 'type' | 'deleted'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetDocumentHistoriesQueryVariables = Exact<{
  input?: Maybe<InputHistories>;
}>;


export type GetDocumentHistoriesQuery = (
  { __typename?: 'Query' }
  & { getDocumentHistories?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'path' | 'url' | 'type' | 'deleted'>
  )>>> }
);

export type CreateUpdateFileSizeMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateFileSize>;
}>;


export type CreateUpdateFileSizeMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateFileSize?: Maybe<(
    { __typename?: 'FileSize' }
    & Pick<FileSize, 'id' | 'size'>
  )> }
);

export type GetFileSizeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFileSizeQuery = (
  { __typename?: 'Query' }
  & { getFileSize?: Maybe<(
    { __typename?: 'FileSize' }
    & Pick<FileSize, 'id' | 'size'>
  )> }
);

export type DepartmentDocumentFragmentFragment = (
  { __typename?: 'DepartmentDocument' }
  & Pick<DepartmentDocument, 'id' | 'name' | 'description' | 'path' | 'url' | 'departmentId' | 'categoryId' | 'createdAt' | 'updatedAt' | 'deleted'>
  & { department: (
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  ), category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'deleted'>
  ) }
);

export type DeleteGedOpsDocumentMutationVariables = Exact<{
  input?: Maybe<InputDeleteGedOpsDocument>;
}>;


export type DeleteGedOpsDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteGedOpsDocument?: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentDocument' }
    & DepartmentDocumentFragmentFragment
  )>>> }
);

export type CreateUpdateGedOpsDocumentMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateGedOpsDocument>;
}>;


export type CreateUpdateGedOpsDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateGedOpsDocument?: Maybe<(
    { __typename?: 'DepartmentDocument' }
    & DepartmentDocumentFragmentFragment
  )> }
);

export type GetGedOpsDocumentsQueryVariables = Exact<{
  input?: Maybe<InputGetGedOpsDocuments>;
}>;


export type GetGedOpsDocumentsQuery = (
  { __typename?: 'Query' }
  & { getGedOpsDocuments?: Maybe<(
    { __typename?: 'DepartmentDocumentPage' }
    & { data: Array<(
      { __typename?: 'DepartmentDocument' }
      & DepartmentDocumentFragmentFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetGedOpsDocumentQueryVariables = Exact<{
  input?: Maybe<InputGetGedOpsDocument>;
}>;


export type GetGedOpsDocumentQuery = (
  { __typename?: 'Query' }
  & { getGedOpsDocument?: Maybe<(
    { __typename?: 'DepartmentDocument' }
    & DepartmentDocumentFragmentFragment
  )> }
);

export type GetLogsQueryVariables = Exact<{
  input?: Maybe<InputGetLogs>;
}>;


export type GetLogsQuery = (
  { __typename?: 'Query' }
  & { getLogs?: Maybe<(
    { __typename?: 'LogsPage' }
    & { data: Array<(
      { __typename?: 'Logs' }
      & Pick<Logs, 'id' | 'siteId' | 'siteName' | 'modelId' | 'model' | 'authorId' | 'authorName' | 'method' | 'subModel' | 'message' | 'messageDecrypted' | 'createdAt'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type CreateUpdateMoyenMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateMoyen>;
}>;


export type CreateUpdateMoyenMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateMoyen?: Maybe<(
    { __typename?: 'Moyen' }
    & Pick<Moyen, 'id' | 'note'>
  )> }
);

export type DeleteMoyenMutationVariables = Exact<{
  input?: Maybe<InputDeleteMoyen>;
}>;


export type DeleteMoyenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMoyen'>
);

export type CreateUpdateMoyenInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateMoyenInStepper>;
}>;


export type CreateUpdateMoyenInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateMoyenInStepper?: Maybe<(
    { __typename?: 'Moyen' }
    & Pick<Moyen, 'id' | 'note'>
  )> }
);

export type GetMoyenBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetMoyenBySite>;
}>;


export type GetMoyenBySiteQuery = (
  { __typename?: 'Query' }
  & { getMoyenBySite?: Maybe<(
    { __typename?: 'Moyen' }
    & Pick<Moyen, 'id' | 'note' | 'noteDecrypted' | 'siteId'>
  )> }
);

export type GetAllMoyenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMoyenQuery = (
  { __typename?: 'Query' }
  & { getAllMoyen?: Maybe<Array<Maybe<(
    { __typename?: 'Moyen' }
    & Pick<Moyen, 'id' | 'note' | 'noteDecrypted' | 'siteId'>
  )>>> }
);

export type GetMoyensQueryVariables = Exact<{
  input?: Maybe<InputGetAllMoyen>;
}>;


export type GetMoyensQuery = (
  { __typename?: 'Query' }
  & { getMoyens?: Maybe<(
    { __typename?: 'MoyenPage' }
    & { data: Array<(
      { __typename?: 'Moyen' }
      & Pick<Moyen, 'id' | 'note' | 'noteDecrypted' | 'siteId'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetMoyenHistoryQueryVariables = Exact<{
  input?: Maybe<InputMoyenHistory>;
}>;


export type GetMoyenHistoryQuery = (
  { __typename?: 'Query' }
  & { getMoyenHistory?: Maybe<(
    { __typename?: 'Moyen' }
    & Pick<Moyen, 'id' | 'note' | 'noteDecrypted' | 'siteId'>
  )> }
);

export type NotificationFragmentFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'isRead' | 'receiver_type' | 'createdAt' | 'notificationType'>
  & { department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )>, sender?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
    & { userGroup?: Maybe<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name'>
    )> }
  )>, site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export type UpdateNotificationByIdMutationVariables = Exact<{
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
}>;


export type UpdateNotificationByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotificationById'>
);

export type GetCurrentUserNotificationsQueryVariables = Exact<{
  input?: Maybe<InputGetUserNotifications>;
}>;


export type GetCurrentUserNotificationsQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserNotifications?: Maybe<(
    { __typename?: 'NotificationPage' }
    & { data: Array<Maybe<(
      { __typename?: 'Notification' }
      & NotificationFragmentFragment
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    ) }
  )> }
);

export type GetUpdatedNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetUpdatedNotificationsSubscription = (
  { __typename?: 'Subscription' }
  & { getUpdatedNotifications?: Maybe<(
    { __typename?: 'NotificationPage' }
    & { data: Array<Maybe<(
      { __typename?: 'Notification' }
      & NotificationFragmentFragment
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    ) }
  )> }
);

export type GetAllPermissionQueryVariables = Exact<{
  input?: Maybe<InputGetAllPermissions>;
}>;


export type GetAllPermissionQuery = (
  { __typename?: 'Query' }
  & { getAllPermissions?: Maybe<(
    { __typename?: 'PermissionPageCursor' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'EdgePermission' }
      & { node?: Maybe<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name' | 'hidden'>
      )> }
    )>>> }
  )> }
);

export type PlanFragmentFragment = (
  { __typename?: 'Plan' }
  & Pick<Plan, 'id' | 'name' | 'path' | 'url' | 'isImage' | 'order' | 'siteId' | 'tagId' | 'deleted'>
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type CreateUpdatePlanMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdatePlan>;
}>;


export type CreateUpdatePlanMutation = (
  { __typename?: 'Mutation' }
  & { createUpdatePlan?: Maybe<Array<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )>> }
);

export type DeletePlanMutationVariables = Exact<{
  input?: Maybe<InputDeletePlan>;
}>;


export type DeletePlanMutation = (
  { __typename?: 'Mutation' }
  & { deletePlan?: Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )> }
);

export type SavePlansOrderMutationVariables = Exact<{
  input?: Maybe<InputSavePlansOrder>;
}>;


export type SavePlansOrderMutation = (
  { __typename?: 'Mutation' }
  & { savePlansOrder?: Maybe<Array<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )>> }
);

export type CreateUpdatePlanInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdatePlanInStepper>;
}>;


export type CreateUpdatePlanInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createUpdatePlanInStepper?: Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )> }
);

export type GetPlansBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetPlansBySite>;
}>;


export type GetPlansBySiteQuery = (
  { __typename?: 'Query' }
  & { getPlansBySite?: Maybe<(
    { __typename?: 'PlanPage' }
    & { data: Array<(
      { __typename?: 'Plan' }
      & PlanFragmentFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetAllPlansBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetAllPlansBySite>;
}>;


export type GetAllPlansBySiteQuery = (
  { __typename?: 'Query' }
  & { getAllPlansBySite?: Maybe<Array<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )>> }
);

export type GetPlanHistoriesQueryVariables = Exact<{
  input?: Maybe<InputPlanHistories>;
}>;


export type GetPlanHistoriesQuery = (
  { __typename?: 'Query' }
  & { getPlanHistories?: Maybe<Array<Maybe<(
    { __typename?: 'Plan' }
    & PlanFragmentFragment
  )>>> }
);

export type SiteFragmentFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'name' | 'logoUrl' | 'communeId' | 'createdAt' | 'updatedAt' | 'status' | 'isFromBatiregistre' | 'managePasswordBySupervisor' | 'planHistories' | 'documentHistories' | 'contactHistories' | 'etare' | 'referenceEtare' | 'sensible' | 'password' | 'passwordDecrypted' | 'address' | 'riskLevel' | 'activity' | 'ranking' | 'coordinates' | 'reference' | 'town' | 'zipCode' | 'bienHistory' | 'latitude' | 'longitude'>
  & { authorizedGroups: Array<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
  )>, siteHistories?: Maybe<Array<Maybe<(
    { __typename?: 'SiteHistory' }
    & Pick<SiteHistory, 'id' | 'field' | 'status' | 'value'>
  )>>>, commune: (
    { __typename?: 'Commune' }
    & Pick<Commune, 'id' | 'name'>
    & { department?: Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    )> }
  ), siteType?: Maybe<(
    { __typename?: 'SiteType' }
    & Pick<SiteType, 'id' | 'name'>
  )>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'lastName' | 'firstName'>
    & { userGroup?: Maybe<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name'>
    )> }
  )> }
);

export type EditRequestSiteListFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'name' | 'etare' | 'createdAt'>
  & { siteHistories?: Maybe<Array<Maybe<(
    { __typename?: 'SiteHistory' }
    & Pick<SiteHistory, 'id' | 'field' | 'status' | 'value'>
    & { site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id'>
    )>, author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
      & { userGroup?: Maybe<(
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'name'>
      )> }
    ) }
  )>>>, commune: (
    { __typename?: 'Commune' }
    & Pick<Commune, 'id'>
    & { department?: Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    )> }
  ), author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'lastName' | 'firstName'>
    & { userGroup?: Maybe<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name'>
    )> }
  )> }
);

export type CheckReferenceMutationVariables = Exact<{
  input?: Maybe<InputCheckReference>;
}>;


export type CheckReferenceMutation = (
  { __typename?: 'Mutation' }
  & { checkReference?: Maybe<(
    { __typename?: 'checkReferenceReturn' }
    & Pick<CheckReferenceReturn, 'isDuplicated' | 'reference'>
  )> }
);

export type CreateSiteFullInfoMutationVariables = Exact<{
  input?: Maybe<InputCreateSiteFullInfo>;
}>;


export type CreateSiteFullInfoMutation = (
  { __typename?: 'Mutation' }
  & { createSiteFullInfo?: Maybe<(
    { __typename?: 'Site' }
    & SiteFragmentFragment
  )> }
);

export type CreateUpdateSiteMutationVariables = Exact<{
  input?: Maybe<InputCreateSite>;
}>;


export type CreateUpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateSite?: Maybe<(
    { __typename?: 'Site' }
    & SiteFragmentFragment
  )> }
);

export type DeleteSitesMutationVariables = Exact<{
  input?: Maybe<InputDeleteSite>;
}>;


export type DeleteSitesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSite'>
);

export type AcceptCreateRequestMutationVariables = Exact<{
  input?: Maybe<InputAcceptCreateWithoutAuthoriGroupId>;
}>;


export type AcceptCreateRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptCreateRequest'>
);

export type AcceptEditRequestMutationVariables = Exact<{
  input?: Maybe<InputAcceptCreateRequest>;
}>;


export type AcceptEditRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptEditRequest'>
);

export type RejectRequestMutationVariables = Exact<{
  input?: Maybe<InputAcceptCreateRequest>;
}>;


export type RejectRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectRequest'>
);

export type DeleteSiteFileOnAwsMutationVariables = Exact<{
  input?: Maybe<InputSiteFile>;
}>;


export type DeleteSiteFileOnAwsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteFileOnAWS'>
);

export type UpdateSensibilityMutationVariables = Exact<{
  input?: Maybe<InputUpdateSensibility>;
}>;


export type UpdateSensibilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSensibility'>
);

export type CreateOrUpdateSiteGeneralInfoMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateSiteGeneralInfo>;
}>;


export type CreateOrUpdateSiteGeneralInfoMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateSiteGeneralInfo?: Maybe<(
    { __typename?: 'Site' }
    & SiteFragmentFragment
  )> }
);

export type UpdateCoordinatesMutationVariables = Exact<{
  input?: Maybe<InputUpdateCoordinates>;
}>;


export type UpdateCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { updateCoordinates?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'logoUrl' | 'communeId' | 'createdAt' | 'status' | 'isFromBatiregistre' | 'managePasswordBySupervisor' | 'planHistories' | 'documentHistories' | 'contactHistories' | 'etare' | 'referenceEtare' | 'sensible' | 'password' | 'passwordDecrypted' | 'address' | 'riskLevel' | 'activity' | 'ranking' | 'coordinates' | 'reference' | 'town' | 'zipCode' | 'bienHistory' | 'latitude' | 'longitude'>
    & { authorizedGroups: Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name'>
    )>, siteHistories?: Maybe<Array<Maybe<(
      { __typename?: 'SiteHistory' }
      & Pick<SiteHistory, 'id' | 'field' | 'status' | 'value'>
    )>>>, commune: (
      { __typename?: 'Commune' }
      & Pick<Commune, 'id' | 'name'>
      & { department?: Maybe<(
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      )> }
    ), siteType?: Maybe<(
      { __typename?: 'SiteType' }
      & Pick<SiteType, 'id' | 'name'>
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lastName' | 'firstName'>
      & { userGroup?: Maybe<(
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetAllSiteQueryVariables = Exact<{
  input?: Maybe<InputGetAllSite>;
}>;


export type GetAllSiteQuery = (
  { __typename?: 'Query' }
  & { allSites?: Maybe<(
    { __typename?: 'SitePage' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )>, data: Array<(
      { __typename?: 'Site' }
      & SiteFragmentFragment
    )> }
  )> }
);

export type GetSiteByIdQueryVariables = Exact<{
  input?: Maybe<InputGetSiteById>;
}>;


export type GetSiteByIdQuery = (
  { __typename?: 'Query' }
  & { getSiteById?: Maybe<(
    { __typename?: 'Site' }
    & SiteFragmentFragment
  )> }
);

export type GetAllCreationRequestQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCreationRequestQuery = (
  { __typename?: 'Query' }
  & { getAllCreationRequest?: Maybe<Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'logoUrl' | 'communeId' | 'createdAt' | 'status' | 'isFromBatiregistre' | 'managePasswordBySupervisor' | 'planHistories' | 'documentHistories' | 'contactHistories' | 'etare' | 'referenceEtare' | 'sensible' | 'password' | 'passwordDecrypted' | 'address' | 'riskLevel' | 'activity' | 'ranking' | 'coordinates' | 'reference' | 'town' | 'zipCode' | 'bienHistory'>
    & { authorizedGroups: Array<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name'>
    )>, siteHistories?: Maybe<Array<Maybe<(
      { __typename?: 'SiteHistory' }
      & Pick<SiteHistory, 'id' | 'field' | 'status' | 'value'>
    )>>>, commune: (
      { __typename?: 'Commune' }
      & Pick<Commune, 'id' | 'name'>
      & { department?: Maybe<(
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      )> }
    ), siteType?: Maybe<(
      { __typename?: 'SiteType' }
      & Pick<SiteType, 'id' | 'name'>
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'lastName' | 'firstName'>
      & { userGroup?: Maybe<(
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'id' | 'name'>
      )> }
    )> }
  )>> }
);

export type GetAllEditRequestQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEditRequestQuery = (
  { __typename?: 'Query' }
  & { getAllEditRequest?: Maybe<Array<Maybe<(
    { __typename?: 'SiteHistoryPage' }
    & Pick<SiteHistoryPage, 'editorGroupName' | 'editorId'>
    & { sites?: Maybe<Array<Maybe<(
      { __typename?: 'Site' }
      & EditRequestSiteListFragment
    )>>> }
  )>>> }
);

export type CheckHistoriesDataQueryVariables = Exact<{
  input?: Maybe<InputHistoryData>;
}>;


export type CheckHistoriesDataQuery = (
  { __typename?: 'Query' }
  & { checkHistoriesData?: Maybe<(
    { __typename?: 'HistoryDataReturn' }
    & Pick<HistoryDataReturn, 'hasPlanHistories' | 'hasContactHistories' | 'hasDocumentHistories' | 'hasCurrentRiskHistories' | 'hasSpecificRiskHistories' | 'hasMoyenHistories' | 'hasBienHistories'>
  )> }
);

export type GetValidationRequestCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetValidationRequestCountQuery = (
  { __typename?: 'Query' }
  & { getValidationRequestCount?: Maybe<(
    { __typename?: 'ValidationRequestCountReturn' }
    & Pick<ValidationRequestCountReturn, 'create' | 'edit' | 'total'>
  )> }
);

export type GetExportSitesQueryVariables = Exact<{
  input?: Maybe<InputGetExportSites>;
}>;


export type GetExportSitesQuery = (
  { __typename?: 'Query' }
  & { getExportSites?: Maybe<(
    { __typename?: 'ExportSites' }
    & Pick<ExportSites, 'file'>
  )> }
);

export type GetExportSiteBySiteIdQueryVariables = Exact<{
  input?: Maybe<InputGetExportSiteBySiteId>;
}>;


export type GetExportSiteBySiteIdQuery = (
  { __typename?: 'Query' }
  & { getExportSiteBySiteId?: Maybe<(
    { __typename?: 'ExportSiteBySiteId' }
    & Pick<ExportSiteBySiteId, 'file'>
  )> }
);

export type CreateNewSiteSubscriptionSubscriptionVariables = Exact<{
  input?: Maybe<InputCreateSiteSubscription>;
}>;


export type CreateNewSiteSubscriptionSubscription = (
  { __typename?: 'Subscription' }
  & { createNewSiteSubscription: (
    { __typename?: 'Notification' }
    & NotificationFragmentFragment
  ) }
);

export type GetValidationRequestCountSubscriptionSubscriptionVariables = Exact<{
  input?: Maybe<InputGetAllValidationRequestCount>;
}>;


export type GetValidationRequestCountSubscriptionSubscription = (
  { __typename?: 'Subscription' }
  & { getValidationRequestCount?: Maybe<(
    { __typename?: 'ValidationRequestCountReturn' }
    & Pick<ValidationRequestCountReturn, 'create' | 'edit' | 'total'>
  )> }
);

export type SiteTypeFragmentFragment = (
  { __typename?: 'SiteType' }
  & Pick<SiteType, 'id' | 'name' | 'deleted' | 'updatedAt' | 'createdAt'>
);

export type DeleteSiteTypesMutationVariables = Exact<{
  input?: Maybe<InputDeleteSiteTypes>;
}>;


export type DeleteSiteTypesMutation = (
  { __typename?: 'Mutation' }
  & { deleteSiteTypes?: Maybe<Array<(
    { __typename?: 'SiteType' }
    & SiteTypeFragmentFragment
  )>> }
);

export type CreateUpdateSiteTypeMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateSiteType>;
}>;


export type CreateUpdateSiteTypeMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateSiteType?: Maybe<(
    { __typename?: 'SiteType' }
    & SiteTypeFragmentFragment
  )> }
);

export type GetSiteTypesQueryVariables = Exact<{
  input?: Maybe<InputGetSiteTypes>;
}>;


export type GetSiteTypesQuery = (
  { __typename?: 'Query' }
  & { getSiteTypes?: Maybe<(
    { __typename?: 'SiteTypePage' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )>, data: Array<(
      { __typename?: 'SiteType' }
      & SiteTypeFragmentFragment
    )> }
  )> }
);

export type GetAllSiteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSiteTypesQuery = (
  { __typename?: 'Query' }
  & { getAllSiteTypes?: Maybe<Array<(
    { __typename?: 'SiteType' }
    & SiteTypeFragmentFragment
  )>> }
);

export type DeleteSpecificRiskToSiteMutationVariables = Exact<{
  input?: Maybe<InputDeleteSpecificRiskToSite>;
}>;


export type DeleteSpecificRiskToSiteMutation = (
  { __typename?: 'Mutation' }
  & { deleteSpecificRiskToSite?: Maybe<Array<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'deleted'>
  )>> }
);

export type CreateUpdateSpecificRiskToSiteMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateSpecificRiskToSite>;
}>;


export type CreateUpdateSpecificRiskToSiteMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateSpecificRiskToSite?: Maybe<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'deleted'>
    & { specificRisk: (
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )> }
);

export type CreateSpecificRisksToSiteInStepperMutationVariables = Exact<{
  input?: Maybe<InputCreateSpecificRisksToSiteInStepper>;
}>;


export type CreateSpecificRisksToSiteInStepperMutation = (
  { __typename?: 'Mutation' }
  & { createSpecificRisksToSiteInStepper?: Maybe<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'siteId' | 'specificRiskId' | 'createdAt' | 'updatedAt' | 'deleted'>
    & { specificRisk: (
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )> }
);

export type UpdateSpecificRisksToSiteInStepperMutationVariables = Exact<{
  input?: Maybe<InputUpdateSpecificRisksToSiteInStepper>;
}>;


export type UpdateSpecificRisksToSiteInStepperMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecificRisksToSiteInStepper?: Maybe<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'siteId' | 'specificRiskId' | 'createdAt' | 'updatedAt' | 'deleted'>
    & { specificRisk: (
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )> }
);

export type GetSpecificRisksToSiteQueryVariables = Exact<{
  input?: Maybe<InputGetSpecificRisksToSite>;
}>;


export type GetSpecificRisksToSiteQuery = (
  { __typename?: 'Query' }
  & { getSpecificRisksToSite?: Maybe<(
    { __typename?: 'SpecificRiskToSitePage' }
    & { data: Array<(
      { __typename?: 'SpecificRiskToSite' }
      & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'deleted'>
      & { specificRisk: (
        { __typename?: 'SpecificRisk' }
        & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
      ) }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetNotAddedSpecificRisksToSiteQueryVariables = Exact<{
  input?: Maybe<InputGetNotAddedSpecificRisksToSite>;
}>;


export type GetNotAddedSpecificRisksToSiteQuery = (
  { __typename?: 'Query' }
  & { getNotAddedSpecificRisksToSite?: Maybe<Array<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )>> }
);

export type GetSpecificRiskHistoriesQueryVariables = Exact<{
  input?: Maybe<InputSpecificRiskHistories>;
}>;


export type GetSpecificRiskHistoriesQuery = (
  { __typename?: 'Query' }
  & { getSpecificRiskHistories?: Maybe<Array<Maybe<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'deleted'>
    & { specificRisk: (
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )>>> }
);

export type GetAllSpecificRiskBySiteQueryVariables = Exact<{
  input?: Maybe<InputGetAllSpecificRiskBySite>;
}>;


export type GetAllSpecificRiskBySiteQuery = (
  { __typename?: 'Query' }
  & { getAllSpecificRiskBySite?: Maybe<Array<(
    { __typename?: 'SpecificRiskToSite' }
    & Pick<SpecificRiskToSite, 'id' | 'description' | 'descriptionDecrypted' | 'siteId' | 'specificRiskId' | 'createdAt' | 'updatedAt' | 'deleted'>
    & { specificRisk: (
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'path' | 'logoUrl' | 'createdAt' | 'updatedAt' | 'deleted'>
    ) }
  )>> }
);

export type TagFragmentFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'deleted' | 'updatedAt' | 'createdAt'>
);

export type DeleteTagsMutationVariables = Exact<{
  input?: Maybe<InputDeleteTags>;
}>;


export type DeleteTagsMutation = (
  { __typename?: 'Mutation' }
  & { deleteTags?: Maybe<Array<(
    { __typename?: 'Tag' }
    & TagFragmentFragment
  )>> }
);

export type CreateUpdateTagMutationVariables = Exact<{
  input?: Maybe<InputCreateUpdateTag>;
}>;


export type CreateUpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateTag?: Maybe<(
    { __typename?: 'Tag' }
    & TagFragmentFragment
  )> }
);

export type GetTagsQueryVariables = Exact<{
  input?: Maybe<InputGetTags>;
}>;


export type GetTagsQuery = (
  { __typename?: 'Query' }
  & { getTags?: Maybe<(
    { __typename?: 'TagPage' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )>, data: Array<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  )> }
);

export type RecoveryUserDepartmentFromUserToDepartmentRelationMutationVariables = Exact<{ [key: string]: never; }>;


export type RecoveryUserDepartmentFromUserToDepartmentRelationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoveryUserDepartmentFromUserToDepartmentRelation'>
);

export type CreateUpdateUserGroupMutationVariables = Exact<{
  input?: Maybe<InputCreateUserGroup>;
}>;


export type CreateUpdateUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { createUpdateUserGroup?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
  )> }
);

export type AddUserToGroupMutationVariables = Exact<{
  input?: Maybe<InputAddUserToGroup>;
}>;


export type AddUserToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserToGroup?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
  )> }
);

export type RemoveUserFromGroupMutationVariables = Exact<{
  input?: Maybe<InputRemoveUserFromGroup>;
}>;


export type RemoveUserFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromGroup?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
  )> }
);

export type DeleteUserGroupMutationVariables = Exact<{
  input?: Maybe<InputDeleteGroup>;
}>;


export type DeleteUserGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserGroup?: Maybe<(
    { __typename?: 'DeleteGroupPayload' }
    & Pick<DeleteGroupPayload, 'success'>
  )> }
);

export type GetAllGroupsQueryVariables = Exact<{
  input?: Maybe<InputGetGroups>;
}>;


export type GetAllGroupsQuery = (
  { __typename?: 'Query' }
  & { getAllGroups?: Maybe<(
    { __typename?: 'UserGroupPageCursor' }
    & Pick<UserGroupPageCursor, 'total'>
    & { edges: Array<(
      { __typename?: 'EdgeUserGroup' }
      & Pick<EdgeUserGroup, 'cursor'>
      & { node: (
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'id' | 'name' | 'otherUserGroupIdList'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'name'>
        )> }
      ) }
    )>, pageCursorInfo?: Maybe<(
      { __typename?: 'UserGroupPageCursorInfo' }
      & Pick<UserGroupPageCursorInfo, 'startCursor' | 'endCursor' | 'hasNextPage'>
    )> }
  )> }
);

export type UserGroupListByConnectedUserGroupQueryVariables = Exact<{ [key: string]: never; }>;


export type UserGroupListByConnectedUserGroupQuery = (
  { __typename?: 'Query' }
  & { userGroupListByConnectedUserGroup?: Maybe<Array<Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'name'>
    )> }
  )>>> }
);

export type GetGroupQueryVariables = Exact<{
  input?: Maybe<InputGetGroup>;
}>;


export type GetGroupQuery = (
  { __typename?: 'Query' }
  & { getGroup?: Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type GroupToAuthorizedQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupToAuthorizedQuery = (
  { __typename?: 'Query' }
  & { groupToAuthorized?: Maybe<Array<Maybe<(
    { __typename?: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name'>
  )>>> }
);

export type CreatePresignedUrlMutationVariables = Exact<{
  input?: Maybe<InputPresigneUrl>;
}>;


export type CreatePresignedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createPresignedUrlPut?: Maybe<(
    { __typename?: 'PresignedUrl' }
    & Pick<PresignedUrl, 'filePath'>
  )> }
);

export type CreatePresignedUrlGetMutationVariables = Exact<{
  input?: Maybe<InputPresigneUrl>;
}>;


export type CreatePresignedUrlGetMutation = (
  { __typename?: 'Mutation' }
  & { createPresignedUrlGet?: Maybe<(
    { __typename?: 'PresignedUrl' }
    & Pick<PresignedUrl, 'filePath'>
  )> }
);

export type DeleteCurrentRiskMutationVariables = Exact<{
  input?: Maybe<InputDeleteCurrentRisk>;
}>;


export type DeleteCurrentRiskMutation = (
  { __typename?: 'Mutation' }
  & { deleteCurrentRisk?: Maybe<Array<Maybe<(
    { __typename?: 'CurrentRisk' }
    & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl'>
  )>>> }
);

export type CreateCurrentRiskMutationVariables = Exact<{
  input?: Maybe<InputCreateCurrentRisk>;
}>;


export type CreateCurrentRiskMutation = (
  { __typename?: 'Mutation' }
  & { createCurrentRisk?: Maybe<(
    { __typename?: 'CurrentRisk' }
    & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'updatedAt' | 'createdAt' | 'deleted'>
  )> }
);

export type UpdateCurrentRiskMutationVariables = Exact<{
  input?: Maybe<InputUpdateCurrentRisk>;
}>;


export type UpdateCurrentRiskMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentRisk?: Maybe<(
    { __typename?: 'CurrentRisk' }
    & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'updatedAt' | 'createdAt' | 'deleted'>
  )> }
);

export type GetAllCurrentRisksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCurrentRisksQuery = (
  { __typename?: 'Query' }
  & { getAllCurrentRisks?: Maybe<Array<Maybe<(
    { __typename?: 'CurrentRisk' }
    & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt'>
  )>>> }
);

export type GetCurrentRisksQueryVariables = Exact<{
  input?: Maybe<InputGetCurrentRisks>;
}>;


export type GetCurrentRisksQuery = (
  { __typename?: 'Query' }
  & { getCurrentRisks?: Maybe<(
    { __typename?: 'CurrentRiskPage' }
    & { data: Array<(
      { __typename?: 'CurrentRisk' }
      & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'path'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetCurrentRiskQueryVariables = Exact<{
  input?: Maybe<InputGetCurrentRisk>;
}>;


export type GetCurrentRiskQuery = (
  { __typename?: 'Query' }
  & { getCurrentRisk?: Maybe<(
    { __typename?: 'CurrentRisk' }
    & Pick<CurrentRisk, 'id' | 'name' | 'logoUrl' | 'path'>
  )> }
);

export type DeleteSpecificRiskMutationVariables = Exact<{
  input?: Maybe<InputDeleteSpecificRisk>;
}>;


export type DeleteSpecificRiskMutation = (
  { __typename?: 'Mutation' }
  & { deleteSpecificRisk?: Maybe<Array<Maybe<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )>>> }
);

export type CreateSpecificRiskMutationVariables = Exact<{
  input?: Maybe<InputCreateSpecificRisk>;
}>;


export type CreateSpecificRiskMutation = (
  { __typename?: 'Mutation' }
  & { createSpecificRisk?: Maybe<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )> }
);

export type UpdateSpecificRiskMutationVariables = Exact<{
  input?: Maybe<InputUpdateSpecificRisk>;
}>;


export type UpdateSpecificRiskMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecificRisk?: Maybe<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )> }
);

export type GetSpecificRisksQueryVariables = Exact<{
  input?: Maybe<InputGetSpecificRisks>;
}>;


export type GetSpecificRisksQuery = (
  { __typename?: 'Query' }
  & { getSpecificRisks?: Maybe<(
    { __typename?: 'SpecificRiskPage' }
    & { data: Array<(
      { __typename?: 'SpecificRisk' }
      & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetSpecificRiskQueryVariables = Exact<{
  input?: Maybe<InputGetSpecificRisk>;
}>;


export type GetSpecificRiskQuery = (
  { __typename?: 'Query' }
  & { getSpecificRisk?: Maybe<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )> }
);

export type GetAllSpecificRisksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSpecificRisksQuery = (
  { __typename?: 'Query' }
  & { getAllSpecificRisks?: Maybe<Array<(
    { __typename?: 'SpecificRisk' }
    & Pick<SpecificRisk, 'id' | 'name' | 'logoUrl' | 'path' | 'createdAt' | 'updatedAt' | 'deleted'>
  )>> }
);

export type LoginMutationVariables = Exact<{
  input?: Maybe<InputLogin>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  input?: Maybe<InputForgotPassword>;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordPayload' }
    & Pick<ForgotPasswordPayload, 'resetEmailSent'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  input?: Maybe<InputResetPassword>;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'passwordReseted'>
  )> }
);

export type UpdateUserProfilMutationVariables = Exact<{
  input?: Maybe<InputUpdateUserProfil>;
}>;


export type UpdateUserProfilMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfil?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'accountName' | 'password' | 'getCreateNotification' | 'getUpdateNotification'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input?: Maybe<IInputCreateUser>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input?: Maybe<IInputUpdateUser>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  input?: Maybe<InputDeleteUser>;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'accountName' | 'email' | 'password' | 'getCreateNotification' | 'getUpdateNotification'>
    & { userGroup?: Maybe<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'deleted'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name'>
      )> }
    )>, departments: Array<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    )>, defaultDepartments?: Maybe<Array<Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    )>>>, departmentUser: Array<(
      { __typename?: 'DepartmentUser' }
      & Pick<DepartmentUser, 'id' | 'readonly'>
      & { department: (
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  input?: Maybe<InputFilterUsers>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserPage' }
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
      & { userGroup?: Maybe<(
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'id' | 'name' | 'deleted'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'name'>
        )> }
      )>, departments: Array<(
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name' | 'departmentCode'>
      )>, departmentUser: Array<(
        { __typename?: 'DepartmentUser' }
        & Pick<DepartmentUser, 'id' | 'readonly'>
        & { department: (
          { __typename?: 'Department' }
          & Pick<Department, 'id' | 'name'>
        ) }
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  input?: Maybe<InputUser>;
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { getUserById?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'deleted' | 'isAdmin' | 'createdAt' | 'updatedAt'>
    & { userGroup?: Maybe<(
      { __typename?: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'deleted'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name'>
      )> }
    )>, departments: Array<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name' | 'departmentCode'>
    )>, defaultDepartments?: Maybe<Array<Maybe<(
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    )>>>, departmentUser: Array<(
      { __typename?: 'DepartmentUser' }
      & Pick<DepartmentUser, 'id' | 'readonly'>
      & { department: (
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type GetUsersByCurrentUserQueryVariables = Exact<{
  input?: Maybe<InputGetUsersByCurrentUser>;
}>;


export type GetUsersByCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getUsersByCurrentUser?: Maybe<(
    { __typename?: 'UserPage' }
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'lastName' | 'firstName' | 'accountName' | 'email' | 'accountStatus' | 'isAdmin'>
      & { userGroup?: Maybe<(
        { __typename?: 'UserGroup' }
        & Pick<UserGroup, 'id' | 'name' | 'deleted'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'currentPage' | 'totalCount' | 'hasNextPage'>
    )> }
  )> }
);

export const BienFragmentFragmentDoc = gql`
    fragment BienFragment on Bien {
  id
  name
  level
  priority
  fait
  location
  url
  siteId
}
    `;
export const CategoryFragmentFragmentDoc = gql`
    fragment CategoryFragment on Category {
  id
  name
  deleted
  updatedAt
  createdAt
}
    `;
export const DeviceFragmentFragmentDoc = gql`
    fragment DeviceFragment on Device {
  id
  name
  serialNumber
  enabled
  customName
}
    `;
export const DepartmentDocumentFragmentFragmentDoc = gql`
    fragment DepartmentDocumentFragment on DepartmentDocument {
  id
  name
  description
  path
  url
  departmentId
  categoryId
  department {
    id
    name
  }
  category {
    id
    name
    deleted
  }
  createdAt
  updatedAt
  deleted
}
    `;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  isRead
  department {
    id
    name
  }
  receiver_type
  createdAt
  notificationType
  sender {
    firstName
    lastName
    userGroup {
      id
      name
    }
  }
  site {
    id
    name
  }
}
    `;
export const PlanFragmentFragmentDoc = gql`
    fragment PlanFragment on Plan {
  id
  name
  path
  url
  isImage
  order
  siteId
  tagId
  deleted
  tag {
    id
    name
  }
}
    `;
export const SiteFragmentFragmentDoc = gql`
    fragment SiteFragment on Site {
  id
  name
  logoUrl
  communeId
  createdAt
  updatedAt
  status
  isFromBatiregistre
  managePasswordBySupervisor
  authorizedGroups {
    id
    name
  }
  siteHistories {
    id
    field
    status
    value
  }
  planHistories
  documentHistories
  contactHistories
  commune {
    id
    name
    department {
      id
      name
    }
  }
  siteType {
    id
    name
  }
  etare
  referenceEtare
  sensible
  password
  passwordDecrypted
  address
  riskLevel
  activity
  ranking
  coordinates
  reference
  town
  author {
    lastName
    firstName
    userGroup {
      id
      name
    }
  }
  zipCode
  bienHistory
  latitude
  longitude
}
    `;
export const EditRequestSiteListFragmentDoc = gql`
    fragment EditRequestSiteList on Site {
  id
  name
  etare
  createdAt
  siteHistories {
    id
    field
    status
    value
    site {
      id
    }
    author {
      firstName
      lastName
      userGroup {
        name
      }
    }
  }
  commune {
    id
    department {
      id
      name
    }
  }
  author {
    lastName
    firstName
    userGroup {
      id
      name
    }
  }
}
    `;
export const SiteTypeFragmentFragmentDoc = gql`
    fragment SiteTypeFragment on SiteType {
  id
  name
  deleted
  updatedAt
  createdAt
}
    `;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  deleted
  updatedAt
  createdAt
}
    `;
export const CreateUpdateBienDocument = gql`
    mutation CreateUpdateBien($input: InputCreateUpdateBien) {
  createUpdateBien(input: $input) {
    ...BienFragment
  }
}
    ${BienFragmentFragmentDoc}`;
export type CreateUpdateBienMutationFn = Apollo.MutationFunction<CreateUpdateBienMutation, CreateUpdateBienMutationVariables>;

/**
 * __useCreateUpdateBienMutation__
 *
 * To run a mutation, you first call `useCreateUpdateBienMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateBienMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateBienMutation, { data, loading, error }] = useCreateUpdateBienMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateBienMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateBienMutation, CreateUpdateBienMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateBienMutation, CreateUpdateBienMutationVariables>(CreateUpdateBienDocument, options);
      }
export type CreateUpdateBienMutationHookResult = ReturnType<typeof useCreateUpdateBienMutation>;
export type CreateUpdateBienMutationResult = Apollo.MutationResult<CreateUpdateBienMutation>;
export type CreateUpdateBienMutationOptions = Apollo.BaseMutationOptions<CreateUpdateBienMutation, CreateUpdateBienMutationVariables>;
export const DeleteBienDocument = gql`
    mutation DeleteBien($input: InputDeleteBien) {
  deleteBien(input: $input) {
    ...BienFragment
  }
}
    ${BienFragmentFragmentDoc}`;
export type DeleteBienMutationFn = Apollo.MutationFunction<DeleteBienMutation, DeleteBienMutationVariables>;

/**
 * __useDeleteBienMutation__
 *
 * To run a mutation, you first call `useDeleteBienMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBienMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBienMutation, { data, loading, error }] = useDeleteBienMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBienMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBienMutation, DeleteBienMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBienMutation, DeleteBienMutationVariables>(DeleteBienDocument, options);
      }
export type DeleteBienMutationHookResult = ReturnType<typeof useDeleteBienMutation>;
export type DeleteBienMutationResult = Apollo.MutationResult<DeleteBienMutation>;
export type DeleteBienMutationOptions = Apollo.BaseMutationOptions<DeleteBienMutation, DeleteBienMutationVariables>;
export const CreateUpdateBienInStepperDocument = gql`
    mutation CreateUpdateBienInStepper($input: InputCreateUpdateBienInStepper) {
  createUpdateBienInStepper(input: $input) {
    ...BienFragment
  }
}
    ${BienFragmentFragmentDoc}`;
export type CreateUpdateBienInStepperMutationFn = Apollo.MutationFunction<CreateUpdateBienInStepperMutation, CreateUpdateBienInStepperMutationVariables>;

/**
 * __useCreateUpdateBienInStepperMutation__
 *
 * To run a mutation, you first call `useCreateUpdateBienInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateBienInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateBienInStepperMutation, { data, loading, error }] = useCreateUpdateBienInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateBienInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateBienInStepperMutation, CreateUpdateBienInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateBienInStepperMutation, CreateUpdateBienInStepperMutationVariables>(CreateUpdateBienInStepperDocument, options);
      }
export type CreateUpdateBienInStepperMutationHookResult = ReturnType<typeof useCreateUpdateBienInStepperMutation>;
export type CreateUpdateBienInStepperMutationResult = Apollo.MutationResult<CreateUpdateBienInStepperMutation>;
export type CreateUpdateBienInStepperMutationOptions = Apollo.BaseMutationOptions<CreateUpdateBienInStepperMutation, CreateUpdateBienInStepperMutationVariables>;
export const GetAllBienDocument = gql`
    query GetAllBien($input: InputGetAllBien) {
  getAllBien(input: $input) {
    data {
      ...BienFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${BienFragmentFragmentDoc}`;

/**
 * __useGetAllBienQuery__
 *
 * To run a query within a React component, call `useGetAllBienQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBienQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBienQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllBienQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBienQuery, GetAllBienQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBienQuery, GetAllBienQueryVariables>(GetAllBienDocument, options);
      }
export function useGetAllBienLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBienQuery, GetAllBienQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBienQuery, GetAllBienQueryVariables>(GetAllBienDocument, options);
        }
export type GetAllBienQueryHookResult = ReturnType<typeof useGetAllBienQuery>;
export type GetAllBienLazyQueryHookResult = ReturnType<typeof useGetAllBienLazyQuery>;
export type GetAllBienQueryResult = Apollo.QueryResult<GetAllBienQuery, GetAllBienQueryVariables>;
export const GetBienByIdDocument = gql`
    query GetBienById($input: InputGetBienById) {
  getBienById(input: $input) {
    ...BienFragment
  }
}
    ${BienFragmentFragmentDoc}`;

/**
 * __useGetBienByIdQuery__
 *
 * To run a query within a React component, call `useGetBienByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBienByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBienByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBienByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetBienByIdQuery, GetBienByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBienByIdQuery, GetBienByIdQueryVariables>(GetBienByIdDocument, options);
      }
export function useGetBienByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBienByIdQuery, GetBienByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBienByIdQuery, GetBienByIdQueryVariables>(GetBienByIdDocument, options);
        }
export type GetBienByIdQueryHookResult = ReturnType<typeof useGetBienByIdQuery>;
export type GetBienByIdLazyQueryHookResult = ReturnType<typeof useGetBienByIdLazyQuery>;
export type GetBienByIdQueryResult = Apollo.QueryResult<GetBienByIdQuery, GetBienByIdQueryVariables>;
export const GetBienBySiteDocument = gql`
    query GetBienBySite($input: InputGetBienBySite) {
  getBienBySite(input: $input) {
    data {
      ...BienFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${BienFragmentFragmentDoc}`;

/**
 * __useGetBienBySiteQuery__
 *
 * To run a query within a React component, call `useGetBienBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBienBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBienBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBienBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetBienBySiteQuery, GetBienBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBienBySiteQuery, GetBienBySiteQueryVariables>(GetBienBySiteDocument, options);
      }
export function useGetBienBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBienBySiteQuery, GetBienBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBienBySiteQuery, GetBienBySiteQueryVariables>(GetBienBySiteDocument, options);
        }
export type GetBienBySiteQueryHookResult = ReturnType<typeof useGetBienBySiteQuery>;
export type GetBienBySiteLazyQueryHookResult = ReturnType<typeof useGetBienBySiteLazyQuery>;
export type GetBienBySiteQueryResult = Apollo.QueryResult<GetBienBySiteQuery, GetBienBySiteQueryVariables>;
export const GetBienHistoryDocument = gql`
    query GetBienHistory($input: InputBienHistory) {
  getBienHistory(input: $input) {
    ...BienFragment
  }
}
    ${BienFragmentFragmentDoc}`;

/**
 * __useGetBienHistoryQuery__
 *
 * To run a query within a React component, call `useGetBienHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBienHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBienHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBienHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetBienHistoryQuery, GetBienHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBienHistoryQuery, GetBienHistoryQueryVariables>(GetBienHistoryDocument, options);
      }
export function useGetBienHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBienHistoryQuery, GetBienHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBienHistoryQuery, GetBienHistoryQueryVariables>(GetBienHistoryDocument, options);
        }
export type GetBienHistoryQueryHookResult = ReturnType<typeof useGetBienHistoryQuery>;
export type GetBienHistoryLazyQueryHookResult = ReturnType<typeof useGetBienHistoryLazyQuery>;
export type GetBienHistoryQueryResult = Apollo.QueryResult<GetBienHistoryQuery, GetBienHistoryQueryVariables>;
export const DeleteCategoriesDocument = gql`
    mutation DeleteCategories($input: InputDeleteCategories) {
  deleteCategories(input: $input) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;
export type DeleteCategoriesMutationFn = Apollo.MutationFunction<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;

/**
 * __useDeleteCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoriesMutation, { data, loading, error }] = useDeleteCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>(DeleteCategoriesDocument, options);
      }
export type DeleteCategoriesMutationHookResult = ReturnType<typeof useDeleteCategoriesMutation>;
export type DeleteCategoriesMutationResult = Apollo.MutationResult<DeleteCategoriesMutation>;
export type DeleteCategoriesMutationOptions = Apollo.BaseMutationOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;
export const CreateUpdateCategoryDocument = gql`
    mutation CreateUpdateCategory($input: InputCreateUpdateCategory) {
  createUpdateCategory(input: $input) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;
export type CreateUpdateCategoryMutationFn = Apollo.MutationFunction<CreateUpdateCategoryMutation, CreateUpdateCategoryMutationVariables>;

/**
 * __useCreateUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCategoryMutation, { data, loading, error }] = useCreateUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateCategoryMutation, CreateUpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateCategoryMutation, CreateUpdateCategoryMutationVariables>(CreateUpdateCategoryDocument, options);
      }
export type CreateUpdateCategoryMutationHookResult = ReturnType<typeof useCreateUpdateCategoryMutation>;
export type CreateUpdateCategoryMutationResult = Apollo.MutationResult<CreateUpdateCategoryMutation>;
export type CreateUpdateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateUpdateCategoryMutation, CreateUpdateCategoryMutationVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($input: InputGetCategories) {
  getCategories(input: $input) {
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
    data {
      ...CategoryFragment
    }
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetAllCategoriesDocument = gql`
    query GetAllCategories {
  getAllCategories {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const CreateUpdateCommuneDocument = gql`
    mutation createUpdateCommune($input: InputCreateUpdateCommune) {
  createUpdateCommune(input: $input) {
    id
    name
    zipCode
    regionCode
    departmentCode
    departmentId
  }
}
    `;
export type CreateUpdateCommuneMutationFn = Apollo.MutationFunction<CreateUpdateCommuneMutation, CreateUpdateCommuneMutationVariables>;

/**
 * __useCreateUpdateCommuneMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCommuneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCommuneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCommuneMutation, { data, loading, error }] = useCreateUpdateCommuneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateCommuneMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateCommuneMutation, CreateUpdateCommuneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateCommuneMutation, CreateUpdateCommuneMutationVariables>(CreateUpdateCommuneDocument, options);
      }
export type CreateUpdateCommuneMutationHookResult = ReturnType<typeof useCreateUpdateCommuneMutation>;
export type CreateUpdateCommuneMutationResult = Apollo.MutationResult<CreateUpdateCommuneMutation>;
export type CreateUpdateCommuneMutationOptions = Apollo.BaseMutationOptions<CreateUpdateCommuneMutation, CreateUpdateCommuneMutationVariables>;
export const DeleteCommunesDocument = gql`
    mutation deleteCommunes($input: InputDeleteCommune) {
  deleteCommunes(input: $input) {
    id
  }
}
    `;
export type DeleteCommunesMutationFn = Apollo.MutationFunction<DeleteCommunesMutation, DeleteCommunesMutationVariables>;

/**
 * __useDeleteCommunesMutation__
 *
 * To run a mutation, you first call `useDeleteCommunesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunesMutation, { data, loading, error }] = useDeleteCommunesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommunesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunesMutation, DeleteCommunesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunesMutation, DeleteCommunesMutationVariables>(DeleteCommunesDocument, options);
      }
export type DeleteCommunesMutationHookResult = ReturnType<typeof useDeleteCommunesMutation>;
export type DeleteCommunesMutationResult = Apollo.MutationResult<DeleteCommunesMutation>;
export type DeleteCommunesMutationOptions = Apollo.BaseMutationOptions<DeleteCommunesMutation, DeleteCommunesMutationVariables>;
export const GetAllCommunesByDepartmentDocument = gql`
    query getAllCommunesByDepartment($input: InputGetAllCommunesByDepartment) {
  getAllCommunesByDepartment(input: $input) {
    id
    name
    zipCode
    regionCode
    departmentId
    department {
      name
    }
  }
}
    `;

/**
 * __useGetAllCommunesByDepartmentQuery__
 *
 * To run a query within a React component, call `useGetAllCommunesByDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCommunesByDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCommunesByDepartmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllCommunesByDepartmentQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCommunesByDepartmentQuery, GetAllCommunesByDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCommunesByDepartmentQuery, GetAllCommunesByDepartmentQueryVariables>(GetAllCommunesByDepartmentDocument, options);
      }
export function useGetAllCommunesByDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCommunesByDepartmentQuery, GetAllCommunesByDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCommunesByDepartmentQuery, GetAllCommunesByDepartmentQueryVariables>(GetAllCommunesByDepartmentDocument, options);
        }
export type GetAllCommunesByDepartmentQueryHookResult = ReturnType<typeof useGetAllCommunesByDepartmentQuery>;
export type GetAllCommunesByDepartmentLazyQueryHookResult = ReturnType<typeof useGetAllCommunesByDepartmentLazyQuery>;
export type GetAllCommunesByDepartmentQueryResult = Apollo.QueryResult<GetAllCommunesByDepartmentQuery, GetAllCommunesByDepartmentQueryVariables>;
export const GetAllCommunesDocument = gql`
    query getAllCommunes($input: InputGetCommunes) {
  getAllCommunes(input: $input) {
    data {
      id
      name
      zipCode
      regionCode
      departmentId
      department {
        name
      }
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAllCommunesQuery__
 *
 * To run a query within a React component, call `useGetAllCommunesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCommunesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCommunesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllCommunesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCommunesQuery, GetAllCommunesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCommunesQuery, GetAllCommunesQueryVariables>(GetAllCommunesDocument, options);
      }
export function useGetAllCommunesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCommunesQuery, GetAllCommunesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCommunesQuery, GetAllCommunesQueryVariables>(GetAllCommunesDocument, options);
        }
export type GetAllCommunesQueryHookResult = ReturnType<typeof useGetAllCommunesQuery>;
export type GetAllCommunesLazyQueryHookResult = ReturnType<typeof useGetAllCommunesLazyQuery>;
export type GetAllCommunesQueryResult = Apollo.QueryResult<GetAllCommunesQuery, GetAllCommunesQueryVariables>;
export const GetCommunesDocument = gql`
    query getCommunes($input: InputGetCommunes) {
  getCommunes(input: $input) {
    data {
      id
      name
      zipCode
      regionCode
      departmentId
      department {
        name
      }
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCommunesQuery__
 *
 * To run a query within a React component, call `useGetCommunesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommunesQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunesQuery, GetCommunesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunesQuery, GetCommunesQueryVariables>(GetCommunesDocument, options);
      }
export function useGetCommunesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunesQuery, GetCommunesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunesQuery, GetCommunesQueryVariables>(GetCommunesDocument, options);
        }
export type GetCommunesQueryHookResult = ReturnType<typeof useGetCommunesQuery>;
export type GetCommunesLazyQueryHookResult = ReturnType<typeof useGetCommunesLazyQuery>;
export type GetCommunesQueryResult = Apollo.QueryResult<GetCommunesQuery, GetCommunesQueryVariables>;
export const CreateUpdateContactDocument = gql`
    mutation CreateUpdateContact($input: InputCreateUpdateContact) {
  createUpdateContact(input: $input) {
    id
    firstName
    lastName
    occupation
    email
    occupation
    dayPhone
    nightPhone
  }
}
    `;
export type CreateUpdateContactMutationFn = Apollo.MutationFunction<CreateUpdateContactMutation, CreateUpdateContactMutationVariables>;

/**
 * __useCreateUpdateContactMutation__
 *
 * To run a mutation, you first call `useCreateUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateContactMutation, { data, loading, error }] = useCreateUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateContactMutation, CreateUpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateContactMutation, CreateUpdateContactMutationVariables>(CreateUpdateContactDocument, options);
      }
export type CreateUpdateContactMutationHookResult = ReturnType<typeof useCreateUpdateContactMutation>;
export type CreateUpdateContactMutationResult = Apollo.MutationResult<CreateUpdateContactMutation>;
export type CreateUpdateContactMutationOptions = Apollo.BaseMutationOptions<CreateUpdateContactMutation, CreateUpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($input: InputDeleteContact) {
  deleteContact(input: $input) {
    id
    firstName
    lastName
    occupation
    email
    occupation
    dayPhone
    nightPhone
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const CreateUpdateContactInStepperDocument = gql`
    mutation CreateUpdateContactInStepper($input: InputCreateUpdateContactInStepper) {
  createUpdateContactInStepper(input: $input) {
    id
    firstName
    lastName
    occupation
    email
    occupation
    dayPhone
    nightPhone
  }
}
    `;
export type CreateUpdateContactInStepperMutationFn = Apollo.MutationFunction<CreateUpdateContactInStepperMutation, CreateUpdateContactInStepperMutationVariables>;

/**
 * __useCreateUpdateContactInStepperMutation__
 *
 * To run a mutation, you first call `useCreateUpdateContactInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateContactInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateContactInStepperMutation, { data, loading, error }] = useCreateUpdateContactInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateContactInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateContactInStepperMutation, CreateUpdateContactInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateContactInStepperMutation, CreateUpdateContactInStepperMutationVariables>(CreateUpdateContactInStepperDocument, options);
      }
export type CreateUpdateContactInStepperMutationHookResult = ReturnType<typeof useCreateUpdateContactInStepperMutation>;
export type CreateUpdateContactInStepperMutationResult = Apollo.MutationResult<CreateUpdateContactInStepperMutation>;
export type CreateUpdateContactInStepperMutationOptions = Apollo.BaseMutationOptions<CreateUpdateContactInStepperMutation, CreateUpdateContactInStepperMutationVariables>;
export const GetContactsBySiteDocument = gql`
    query GetContactsBySite($input: InputGetContactsBySite) {
  contactsBySite(input: $input) {
    data {
      id
      firstName
      lastName
      occupation
      email
      occupation
      dayPhone
      nightPhone
      deleted
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetContactsBySiteQuery__
 *
 * To run a query within a React component, call `useGetContactsBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactsBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsBySiteQuery, GetContactsBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsBySiteQuery, GetContactsBySiteQueryVariables>(GetContactsBySiteDocument, options);
      }
export function useGetContactsBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsBySiteQuery, GetContactsBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsBySiteQuery, GetContactsBySiteQueryVariables>(GetContactsBySiteDocument, options);
        }
export type GetContactsBySiteQueryHookResult = ReturnType<typeof useGetContactsBySiteQuery>;
export type GetContactsBySiteLazyQueryHookResult = ReturnType<typeof useGetContactsBySiteLazyQuery>;
export type GetContactsBySiteQueryResult = Apollo.QueryResult<GetContactsBySiteQuery, GetContactsBySiteQueryVariables>;
export const GetContactHistoriesDocument = gql`
    query getContactHistories($input: InputHistories) {
  getContactHistories(input: $input) {
    id
    firstName
    lastName
    occupation
    email
    occupation
    dayPhone
    nightPhone
    deleted
  }
}
    `;

/**
 * __useGetContactHistoriesQuery__
 *
 * To run a query within a React component, call `useGetContactHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetContactHistoriesQuery, GetContactHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactHistoriesQuery, GetContactHistoriesQueryVariables>(GetContactHistoriesDocument, options);
      }
export function useGetContactHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactHistoriesQuery, GetContactHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactHistoriesQuery, GetContactHistoriesQueryVariables>(GetContactHistoriesDocument, options);
        }
export type GetContactHistoriesQueryHookResult = ReturnType<typeof useGetContactHistoriesQuery>;
export type GetContactHistoriesLazyQueryHookResult = ReturnType<typeof useGetContactHistoriesLazyQuery>;
export type GetContactHistoriesQueryResult = Apollo.QueryResult<GetContactHistoriesQuery, GetContactHistoriesQueryVariables>;
export const CreateCurrentRiskToSiteDocument = gql`
    mutation CreateCurrentRiskToSite($input: InputCreateCurrentRiskToSite) {
  createCurrentRiskToSite(input: $input) {
    id
    description
    level
  }
}
    `;
export type CreateCurrentRiskToSiteMutationFn = Apollo.MutationFunction<CreateCurrentRiskToSiteMutation, CreateCurrentRiskToSiteMutationVariables>;

/**
 * __useCreateCurrentRiskToSiteMutation__
 *
 * To run a mutation, you first call `useCreateCurrentRiskToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentRiskToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentRiskToSiteMutation, { data, loading, error }] = useCreateCurrentRiskToSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrentRiskToSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentRiskToSiteMutation, CreateCurrentRiskToSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentRiskToSiteMutation, CreateCurrentRiskToSiteMutationVariables>(CreateCurrentRiskToSiteDocument, options);
      }
export type CreateCurrentRiskToSiteMutationHookResult = ReturnType<typeof useCreateCurrentRiskToSiteMutation>;
export type CreateCurrentRiskToSiteMutationResult = Apollo.MutationResult<CreateCurrentRiskToSiteMutation>;
export type CreateCurrentRiskToSiteMutationOptions = Apollo.BaseMutationOptions<CreateCurrentRiskToSiteMutation, CreateCurrentRiskToSiteMutationVariables>;
export const CreateCurrentRiskToSiteListDocument = gql`
    mutation CreateCurrentRiskToSiteList($input: InputCreateCurrentRiskToSiteList) {
  createCurrentRiskToSiteList(input: $input) {
    id
    description
    level
  }
}
    `;
export type CreateCurrentRiskToSiteListMutationFn = Apollo.MutationFunction<CreateCurrentRiskToSiteListMutation, CreateCurrentRiskToSiteListMutationVariables>;

/**
 * __useCreateCurrentRiskToSiteListMutation__
 *
 * To run a mutation, you first call `useCreateCurrentRiskToSiteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentRiskToSiteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentRiskToSiteListMutation, { data, loading, error }] = useCreateCurrentRiskToSiteListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrentRiskToSiteListMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentRiskToSiteListMutation, CreateCurrentRiskToSiteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentRiskToSiteListMutation, CreateCurrentRiskToSiteListMutationVariables>(CreateCurrentRiskToSiteListDocument, options);
      }
export type CreateCurrentRiskToSiteListMutationHookResult = ReturnType<typeof useCreateCurrentRiskToSiteListMutation>;
export type CreateCurrentRiskToSiteListMutationResult = Apollo.MutationResult<CreateCurrentRiskToSiteListMutation>;
export type CreateCurrentRiskToSiteListMutationOptions = Apollo.BaseMutationOptions<CreateCurrentRiskToSiteListMutation, CreateCurrentRiskToSiteListMutationVariables>;
export const UpdateCurrentRiskToSiteDocument = gql`
    mutation UpdateCurrentRiskToSite($input: InputUpdateCurrentRiskToSite) {
  updateCurrentRiskToSite(input: $input) {
    id
    description
    level
  }
}
    `;
export type UpdateCurrentRiskToSiteMutationFn = Apollo.MutationFunction<UpdateCurrentRiskToSiteMutation, UpdateCurrentRiskToSiteMutationVariables>;

/**
 * __useUpdateCurrentRiskToSiteMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentRiskToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentRiskToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentRiskToSiteMutation, { data, loading, error }] = useUpdateCurrentRiskToSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentRiskToSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentRiskToSiteMutation, UpdateCurrentRiskToSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentRiskToSiteMutation, UpdateCurrentRiskToSiteMutationVariables>(UpdateCurrentRiskToSiteDocument, options);
      }
export type UpdateCurrentRiskToSiteMutationHookResult = ReturnType<typeof useUpdateCurrentRiskToSiteMutation>;
export type UpdateCurrentRiskToSiteMutationResult = Apollo.MutationResult<UpdateCurrentRiskToSiteMutation>;
export type UpdateCurrentRiskToSiteMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentRiskToSiteMutation, UpdateCurrentRiskToSiteMutationVariables>;
export const UpdateCurrentRiskToSiteListDocument = gql`
    mutation UpdateCurrentRiskToSiteList($input: InputUpdateCurrentRiskToSiteList) {
  updateCurrentRiskToSiteList(input: $input) {
    id
    description
    level
  }
}
    `;
export type UpdateCurrentRiskToSiteListMutationFn = Apollo.MutationFunction<UpdateCurrentRiskToSiteListMutation, UpdateCurrentRiskToSiteListMutationVariables>;

/**
 * __useUpdateCurrentRiskToSiteListMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentRiskToSiteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentRiskToSiteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentRiskToSiteListMutation, { data, loading, error }] = useUpdateCurrentRiskToSiteListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentRiskToSiteListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentRiskToSiteListMutation, UpdateCurrentRiskToSiteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentRiskToSiteListMutation, UpdateCurrentRiskToSiteListMutationVariables>(UpdateCurrentRiskToSiteListDocument, options);
      }
export type UpdateCurrentRiskToSiteListMutationHookResult = ReturnType<typeof useUpdateCurrentRiskToSiteListMutation>;
export type UpdateCurrentRiskToSiteListMutationResult = Apollo.MutationResult<UpdateCurrentRiskToSiteListMutation>;
export type UpdateCurrentRiskToSiteListMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentRiskToSiteListMutation, UpdateCurrentRiskToSiteListMutationVariables>;
export const CreateCurrentRiskToSiteForAllSiteDocument = gql`
    mutation CreateCurrentRiskToSiteForAllSite {
  createCurrentRiskToSiteForAllSite {
    id
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type CreateCurrentRiskToSiteForAllSiteMutationFn = Apollo.MutationFunction<CreateCurrentRiskToSiteForAllSiteMutation, CreateCurrentRiskToSiteForAllSiteMutationVariables>;

/**
 * __useCreateCurrentRiskToSiteForAllSiteMutation__
 *
 * To run a mutation, you first call `useCreateCurrentRiskToSiteForAllSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentRiskToSiteForAllSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentRiskToSiteForAllSiteMutation, { data, loading, error }] = useCreateCurrentRiskToSiteForAllSiteMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCurrentRiskToSiteForAllSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentRiskToSiteForAllSiteMutation, CreateCurrentRiskToSiteForAllSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentRiskToSiteForAllSiteMutation, CreateCurrentRiskToSiteForAllSiteMutationVariables>(CreateCurrentRiskToSiteForAllSiteDocument, options);
      }
export type CreateCurrentRiskToSiteForAllSiteMutationHookResult = ReturnType<typeof useCreateCurrentRiskToSiteForAllSiteMutation>;
export type CreateCurrentRiskToSiteForAllSiteMutationResult = Apollo.MutationResult<CreateCurrentRiskToSiteForAllSiteMutation>;
export type CreateCurrentRiskToSiteForAllSiteMutationOptions = Apollo.BaseMutationOptions<CreateCurrentRiskToSiteForAllSiteMutation, CreateCurrentRiskToSiteForAllSiteMutationVariables>;
export const GetCurrentRisksToSiteDocument = gql`
    query GetCurrentRisksToSite($input: InputGetCurrentRisksToSite) {
  getCurrentRisksToSite(input: $input) {
    id
    description
    descriptionDecrypted
    level
    siteId
    currentRiskId
    currentRisk {
      id
      name
      path
      logoUrl
      createdAt
      updatedAt
      deleted
    }
  }
}
    `;

/**
 * __useGetCurrentRisksToSiteQuery__
 *
 * To run a query within a React component, call `useGetCurrentRisksToSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRisksToSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRisksToSiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentRisksToSiteQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRisksToSiteQuery, GetCurrentRisksToSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRisksToSiteQuery, GetCurrentRisksToSiteQueryVariables>(GetCurrentRisksToSiteDocument, options);
      }
export function useGetCurrentRisksToSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRisksToSiteQuery, GetCurrentRisksToSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRisksToSiteQuery, GetCurrentRisksToSiteQueryVariables>(GetCurrentRisksToSiteDocument, options);
        }
export type GetCurrentRisksToSiteQueryHookResult = ReturnType<typeof useGetCurrentRisksToSiteQuery>;
export type GetCurrentRisksToSiteLazyQueryHookResult = ReturnType<typeof useGetCurrentRisksToSiteLazyQuery>;
export type GetCurrentRisksToSiteQueryResult = Apollo.QueryResult<GetCurrentRisksToSiteQuery, GetCurrentRisksToSiteQueryVariables>;
export const GetCurrentRiskHistoriesDocument = gql`
    query GetCurrentRiskHistories($input: InputCurrentRiskHistories) {
  getCurrentRiskHistories(input: $input) {
    id
    description
    descriptionDecrypted
    level
    siteId
    currentRiskId
    createdAt
    currentRisk {
      id
      name
      logoUrl
      path
      createdAt
    }
  }
}
    `;

/**
 * __useGetCurrentRiskHistoriesQuery__
 *
 * To run a query within a React component, call `useGetCurrentRiskHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRiskHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRiskHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentRiskHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRiskHistoriesQuery, GetCurrentRiskHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRiskHistoriesQuery, GetCurrentRiskHistoriesQueryVariables>(GetCurrentRiskHistoriesDocument, options);
      }
export function useGetCurrentRiskHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRiskHistoriesQuery, GetCurrentRiskHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRiskHistoriesQuery, GetCurrentRiskHistoriesQueryVariables>(GetCurrentRiskHistoriesDocument, options);
        }
export type GetCurrentRiskHistoriesQueryHookResult = ReturnType<typeof useGetCurrentRiskHistoriesQuery>;
export type GetCurrentRiskHistoriesLazyQueryHookResult = ReturnType<typeof useGetCurrentRiskHistoriesLazyQuery>;
export type GetCurrentRiskHistoriesQueryResult = Apollo.QueryResult<GetCurrentRiskHistoriesQuery, GetCurrentRiskHistoriesQueryVariables>;
export const CreateUpdateDangerousMaterialDocument = gql`
    mutation createUpdateDangerousMaterial($input: InputCreateUpdateDangerousMaterial) {
  createUpdateDangerousMaterial(input: $input) {
    id
    title
    description
    codeONU
  }
}
    `;
export type CreateUpdateDangerousMaterialMutationFn = Apollo.MutationFunction<CreateUpdateDangerousMaterialMutation, CreateUpdateDangerousMaterialMutationVariables>;

/**
 * __useCreateUpdateDangerousMaterialMutation__
 *
 * To run a mutation, you first call `useCreateUpdateDangerousMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateDangerousMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateDangerousMaterialMutation, { data, loading, error }] = useCreateUpdateDangerousMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateDangerousMaterialMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateDangerousMaterialMutation, CreateUpdateDangerousMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateDangerousMaterialMutation, CreateUpdateDangerousMaterialMutationVariables>(CreateUpdateDangerousMaterialDocument, options);
      }
export type CreateUpdateDangerousMaterialMutationHookResult = ReturnType<typeof useCreateUpdateDangerousMaterialMutation>;
export type CreateUpdateDangerousMaterialMutationResult = Apollo.MutationResult<CreateUpdateDangerousMaterialMutation>;
export type CreateUpdateDangerousMaterialMutationOptions = Apollo.BaseMutationOptions<CreateUpdateDangerousMaterialMutation, CreateUpdateDangerousMaterialMutationVariables>;
export const DeleteDangerousMaterialsDocument = gql`
    mutation deleteDangerousMaterials($input: InputDeleteDangerousMaterial) {
  deleteDangerousMaterials(input: $input) {
    id
  }
}
    `;
export type DeleteDangerousMaterialsMutationFn = Apollo.MutationFunction<DeleteDangerousMaterialsMutation, DeleteDangerousMaterialsMutationVariables>;

/**
 * __useDeleteDangerousMaterialsMutation__
 *
 * To run a mutation, you first call `useDeleteDangerousMaterialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDangerousMaterialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDangerousMaterialsMutation, { data, loading, error }] = useDeleteDangerousMaterialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDangerousMaterialsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDangerousMaterialsMutation, DeleteDangerousMaterialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDangerousMaterialsMutation, DeleteDangerousMaterialsMutationVariables>(DeleteDangerousMaterialsDocument, options);
      }
export type DeleteDangerousMaterialsMutationHookResult = ReturnType<typeof useDeleteDangerousMaterialsMutation>;
export type DeleteDangerousMaterialsMutationResult = Apollo.MutationResult<DeleteDangerousMaterialsMutation>;
export type DeleteDangerousMaterialsMutationOptions = Apollo.BaseMutationOptions<DeleteDangerousMaterialsMutation, DeleteDangerousMaterialsMutationVariables>;
export const GetAllDangerousMaterialDocument = gql`
    query getAllDangerousMaterial($input: InputGetAllDangerousMaterial) {
  getAllDangerousMaterial(input: $input) {
    data {
      id
      title
      description
      codeONU
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAllDangerousMaterialQuery__
 *
 * To run a query within a React component, call `useGetAllDangerousMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDangerousMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDangerousMaterialQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllDangerousMaterialQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDangerousMaterialQuery, GetAllDangerousMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDangerousMaterialQuery, GetAllDangerousMaterialQueryVariables>(GetAllDangerousMaterialDocument, options);
      }
export function useGetAllDangerousMaterialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDangerousMaterialQuery, GetAllDangerousMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDangerousMaterialQuery, GetAllDangerousMaterialQueryVariables>(GetAllDangerousMaterialDocument, options);
        }
export type GetAllDangerousMaterialQueryHookResult = ReturnType<typeof useGetAllDangerousMaterialQuery>;
export type GetAllDangerousMaterialLazyQueryHookResult = ReturnType<typeof useGetAllDangerousMaterialLazyQuery>;
export type GetAllDangerousMaterialQueryResult = Apollo.QueryResult<GetAllDangerousMaterialQuery, GetAllDangerousMaterialQueryVariables>;
export const CreateUpdateDepartmentDocument = gql`
    mutation createUpdateDepartment($input: InputCreateUpdateDepartment) {
  createUpdateDepartment(input: $input) {
    id
    name
    departmentCode
    displayBatiRegistre
  }
}
    `;
export type CreateUpdateDepartmentMutationFn = Apollo.MutationFunction<CreateUpdateDepartmentMutation, CreateUpdateDepartmentMutationVariables>;

/**
 * __useCreateUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateDepartmentMutation, { data, loading, error }] = useCreateUpdateDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateDepartmentMutation, CreateUpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateDepartmentMutation, CreateUpdateDepartmentMutationVariables>(CreateUpdateDepartmentDocument, options);
      }
export type CreateUpdateDepartmentMutationHookResult = ReturnType<typeof useCreateUpdateDepartmentMutation>;
export type CreateUpdateDepartmentMutationResult = Apollo.MutationResult<CreateUpdateDepartmentMutation>;
export type CreateUpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateUpdateDepartmentMutation, CreateUpdateDepartmentMutationVariables>;
export const DeleteDepartmentsDocument = gql`
    mutation deleteDepartments($input: InputDeleteDepartment) {
  deleteDepartments(input: $input) {
    id
  }
}
    `;
export type DeleteDepartmentsMutationFn = Apollo.MutationFunction<DeleteDepartmentsMutation, DeleteDepartmentsMutationVariables>;

/**
 * __useDeleteDepartmentsMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentsMutation, { data, loading, error }] = useDeleteDepartmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDepartmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentsMutation, DeleteDepartmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentsMutation, DeleteDepartmentsMutationVariables>(DeleteDepartmentsDocument, options);
      }
export type DeleteDepartmentsMutationHookResult = ReturnType<typeof useDeleteDepartmentsMutation>;
export type DeleteDepartmentsMutationResult = Apollo.MutationResult<DeleteDepartmentsMutation>;
export type DeleteDepartmentsMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentsMutation, DeleteDepartmentsMutationVariables>;
export const DuplicateDepartmentWithEtareDocument = gql`
    mutation DuplicateDepartmentWithEtare {
  duplicateDepartmentWithEtare
}
    `;
export type DuplicateDepartmentWithEtareMutationFn = Apollo.MutationFunction<DuplicateDepartmentWithEtareMutation, DuplicateDepartmentWithEtareMutationVariables>;

/**
 * __useDuplicateDepartmentWithEtareMutation__
 *
 * To run a mutation, you first call `useDuplicateDepartmentWithEtareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDepartmentWithEtareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDepartmentWithEtareMutation, { data, loading, error }] = useDuplicateDepartmentWithEtareMutation({
 *   variables: {
 *   },
 * });
 */
export function useDuplicateDepartmentWithEtareMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateDepartmentWithEtareMutation, DuplicateDepartmentWithEtareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateDepartmentWithEtareMutation, DuplicateDepartmentWithEtareMutationVariables>(DuplicateDepartmentWithEtareDocument, options);
      }
export type DuplicateDepartmentWithEtareMutationHookResult = ReturnType<typeof useDuplicateDepartmentWithEtareMutation>;
export type DuplicateDepartmentWithEtareMutationResult = Apollo.MutationResult<DuplicateDepartmentWithEtareMutation>;
export type DuplicateDepartmentWithEtareMutationOptions = Apollo.BaseMutationOptions<DuplicateDepartmentWithEtareMutation, DuplicateDepartmentWithEtareMutationVariables>;
export const GetAllDepartmentDocument = gql`
    query getAllDepartment($input: InputGetAllDepartment) {
  getAllDepartment(input: $input) {
    data {
      id
      name
      departmentCode
      displayBatiRegistre
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAllDepartmentQuery__
 *
 * To run a query within a React component, call `useGetAllDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepartmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllDepartmentQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDepartmentQuery, GetAllDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDepartmentQuery, GetAllDepartmentQueryVariables>(GetAllDepartmentDocument, options);
      }
export function useGetAllDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDepartmentQuery, GetAllDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDepartmentQuery, GetAllDepartmentQueryVariables>(GetAllDepartmentDocument, options);
        }
export type GetAllDepartmentQueryHookResult = ReturnType<typeof useGetAllDepartmentQuery>;
export type GetAllDepartmentLazyQueryHookResult = ReturnType<typeof useGetAllDepartmentLazyQuery>;
export type GetAllDepartmentQueryResult = Apollo.QueryResult<GetAllDepartmentQuery, GetAllDepartmentQueryVariables>;
export const GetAllDepartmentsByCurrentUserDocument = gql`
    query getAllDepartmentsByCurrentUser($input: InputGetDepartment) {
  getAllDepartmentsByCurrentUser(input: $input) {
    id
    name
    departmentCode
  }
}
    `;

/**
 * __useGetAllDepartmentsByCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetAllDepartmentsByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepartmentsByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepartmentsByCurrentUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllDepartmentsByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDepartmentsByCurrentUserQuery, GetAllDepartmentsByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDepartmentsByCurrentUserQuery, GetAllDepartmentsByCurrentUserQueryVariables>(GetAllDepartmentsByCurrentUserDocument, options);
      }
export function useGetAllDepartmentsByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDepartmentsByCurrentUserQuery, GetAllDepartmentsByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDepartmentsByCurrentUserQuery, GetAllDepartmentsByCurrentUserQueryVariables>(GetAllDepartmentsByCurrentUserDocument, options);
        }
export type GetAllDepartmentsByCurrentUserQueryHookResult = ReturnType<typeof useGetAllDepartmentsByCurrentUserQuery>;
export type GetAllDepartmentsByCurrentUserLazyQueryHookResult = ReturnType<typeof useGetAllDepartmentsByCurrentUserLazyQuery>;
export type GetAllDepartmentsByCurrentUserQueryResult = Apollo.QueryResult<GetAllDepartmentsByCurrentUserQuery, GetAllDepartmentsByCurrentUserQueryVariables>;
export const GetAllDepartmentsByUserIdDocument = gql`
    query getAllDepartmentsByUserId($input: InputGetAllDepartmentsByUserId) {
  getAllDepartmentsByUserId(input: $input) {
    id
    name
    departmentCode
  }
}
    `;

/**
 * __useGetAllDepartmentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAllDepartmentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepartmentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepartmentsByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllDepartmentsByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDepartmentsByUserIdQuery, GetAllDepartmentsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDepartmentsByUserIdQuery, GetAllDepartmentsByUserIdQueryVariables>(GetAllDepartmentsByUserIdDocument, options);
      }
export function useGetAllDepartmentsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDepartmentsByUserIdQuery, GetAllDepartmentsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDepartmentsByUserIdQuery, GetAllDepartmentsByUserIdQueryVariables>(GetAllDepartmentsByUserIdDocument, options);
        }
export type GetAllDepartmentsByUserIdQueryHookResult = ReturnType<typeof useGetAllDepartmentsByUserIdQuery>;
export type GetAllDepartmentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetAllDepartmentsByUserIdLazyQuery>;
export type GetAllDepartmentsByUserIdQueryResult = Apollo.QueryResult<GetAllDepartmentsByUserIdQuery, GetAllDepartmentsByUserIdQueryVariables>;
export const AllDepartmentDocument = gql`
    query AllDepartment($input: InputGetAllDepartment) {
  allDepartment(input: $input) {
    data {
      id
      name
      departmentCode
      displayBatiRegistre
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllDepartmentQuery__
 *
 * To run a query within a React component, call `useAllDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDepartmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllDepartmentQuery(baseOptions?: Apollo.QueryHookOptions<AllDepartmentQuery, AllDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDepartmentQuery, AllDepartmentQueryVariables>(AllDepartmentDocument, options);
      }
export function useAllDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDepartmentQuery, AllDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDepartmentQuery, AllDepartmentQueryVariables>(AllDepartmentDocument, options);
        }
export type AllDepartmentQueryHookResult = ReturnType<typeof useAllDepartmentQuery>;
export type AllDepartmentLazyQueryHookResult = ReturnType<typeof useAllDepartmentLazyQuery>;
export type AllDepartmentQueryResult = Apollo.QueryResult<AllDepartmentQuery, AllDepartmentQueryVariables>;
export const GetUserDepartmentsDocument = gql`
    query GetUserDepartments {
  getUserDepartments {
    id
  }
}
    `;

/**
 * __useGetUserDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetUserDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDepartmentsQuery, GetUserDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDepartmentsQuery, GetUserDepartmentsQueryVariables>(GetUserDepartmentsDocument, options);
      }
export function useGetUserDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDepartmentsQuery, GetUserDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDepartmentsQuery, GetUserDepartmentsQueryVariables>(GetUserDepartmentsDocument, options);
        }
export type GetUserDepartmentsQueryHookResult = ReturnType<typeof useGetUserDepartmentsQuery>;
export type GetUserDepartmentsLazyQueryHookResult = ReturnType<typeof useGetUserDepartmentsLazyQuery>;
export type GetUserDepartmentsQueryResult = Apollo.QueryResult<GetUserDepartmentsQuery, GetUserDepartmentsQueryVariables>;
export const GetDepartmentByIdDocument = gql`
    query getDepartmentById($input: InputGetDepartmentById) {
  getDepartmentById(input: $input) {
    id
    name
    departmentCode
  }
}
    `;

/**
 * __useGetDepartmentByIdQuery__
 *
 * To run a query within a React component, call `useGetDepartmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDepartmentByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentByIdQuery, GetDepartmentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentByIdQuery, GetDepartmentByIdQueryVariables>(GetDepartmentByIdDocument, options);
      }
export function useGetDepartmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentByIdQuery, GetDepartmentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentByIdQuery, GetDepartmentByIdQueryVariables>(GetDepartmentByIdDocument, options);
        }
export type GetDepartmentByIdQueryHookResult = ReturnType<typeof useGetDepartmentByIdQuery>;
export type GetDepartmentByIdLazyQueryHookResult = ReturnType<typeof useGetDepartmentByIdLazyQuery>;
export type GetDepartmentByIdQueryResult = Apollo.QueryResult<GetDepartmentByIdQuery, GetDepartmentByIdQueryVariables>;
export const GetSelectedUserDefaultDepartmentDocument = gql`
    query GetSelectedUserDefaultDepartment($input: InputGetAllReadOnlyDepartmentsByUserId) {
  getSelectedUserDefaultDepartment(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useGetSelectedUserDefaultDepartmentQuery__
 *
 * To run a query within a React component, call `useGetSelectedUserDefaultDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedUserDefaultDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedUserDefaultDepartmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSelectedUserDefaultDepartmentQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectedUserDefaultDepartmentQuery, GetSelectedUserDefaultDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectedUserDefaultDepartmentQuery, GetSelectedUserDefaultDepartmentQueryVariables>(GetSelectedUserDefaultDepartmentDocument, options);
      }
export function useGetSelectedUserDefaultDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectedUserDefaultDepartmentQuery, GetSelectedUserDefaultDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectedUserDefaultDepartmentQuery, GetSelectedUserDefaultDepartmentQueryVariables>(GetSelectedUserDefaultDepartmentDocument, options);
        }
export type GetSelectedUserDefaultDepartmentQueryHookResult = ReturnType<typeof useGetSelectedUserDefaultDepartmentQuery>;
export type GetSelectedUserDefaultDepartmentLazyQueryHookResult = ReturnType<typeof useGetSelectedUserDefaultDepartmentLazyQuery>;
export type GetSelectedUserDefaultDepartmentQueryResult = Apollo.QueryResult<GetSelectedUserDefaultDepartmentQuery, GetSelectedUserDefaultDepartmentQueryVariables>;
export const GetAllReadOnlyDepartmentsByUserIdDocument = gql`
    query getAllReadOnlyDepartmentsByUserId($input: InputGetAllReadOnlyDepartmentsByUserId) {
  getAllReadOnlyDepartmentsByUserId(input: $input) {
    id
    name
    departmentCode
  }
}
    `;

/**
 * __useGetAllReadOnlyDepartmentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAllReadOnlyDepartmentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReadOnlyDepartmentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReadOnlyDepartmentsByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllReadOnlyDepartmentsByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReadOnlyDepartmentsByUserIdQuery, GetAllReadOnlyDepartmentsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReadOnlyDepartmentsByUserIdQuery, GetAllReadOnlyDepartmentsByUserIdQueryVariables>(GetAllReadOnlyDepartmentsByUserIdDocument, options);
      }
export function useGetAllReadOnlyDepartmentsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReadOnlyDepartmentsByUserIdQuery, GetAllReadOnlyDepartmentsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReadOnlyDepartmentsByUserIdQuery, GetAllReadOnlyDepartmentsByUserIdQueryVariables>(GetAllReadOnlyDepartmentsByUserIdDocument, options);
        }
export type GetAllReadOnlyDepartmentsByUserIdQueryHookResult = ReturnType<typeof useGetAllReadOnlyDepartmentsByUserIdQuery>;
export type GetAllReadOnlyDepartmentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetAllReadOnlyDepartmentsByUserIdLazyQuery>;
export type GetAllReadOnlyDepartmentsByUserIdQueryResult = Apollo.QueryResult<GetAllReadOnlyDepartmentsByUserIdQuery, GetAllReadOnlyDepartmentsByUserIdQueryVariables>;
export const UpdateDeviceStatusDocument = gql`
    mutation UpdateDeviceStatus($input: InputUpdateDeviceStatus) {
  updateDeviceStatus(input: $input) {
    ...DeviceFragment
  }
}
    ${DeviceFragmentFragmentDoc}`;
export type UpdateDeviceStatusMutationFn = Apollo.MutationFunction<UpdateDeviceStatusMutation, UpdateDeviceStatusMutationVariables>;

/**
 * __useUpdateDeviceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceStatusMutation, { data, loading, error }] = useUpdateDeviceStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceStatusMutation, UpdateDeviceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceStatusMutation, UpdateDeviceStatusMutationVariables>(UpdateDeviceStatusDocument, options);
      }
export type UpdateDeviceStatusMutationHookResult = ReturnType<typeof useUpdateDeviceStatusMutation>;
export type UpdateDeviceStatusMutationResult = Apollo.MutationResult<UpdateDeviceStatusMutation>;
export type UpdateDeviceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceStatusMutation, UpdateDeviceStatusMutationVariables>;
export const UpdateDeviceCustomNameDocument = gql`
    mutation updateDeviceCustomName($input: InputCustomName) {
  updateDeviceCustomName(input: $input) {
    ...DeviceFragment
  }
}
    ${DeviceFragmentFragmentDoc}`;
export type UpdateDeviceCustomNameMutationFn = Apollo.MutationFunction<UpdateDeviceCustomNameMutation, UpdateDeviceCustomNameMutationVariables>;

/**
 * __useUpdateDeviceCustomNameMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceCustomNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceCustomNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceCustomNameMutation, { data, loading, error }] = useUpdateDeviceCustomNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceCustomNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceCustomNameMutation, UpdateDeviceCustomNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceCustomNameMutation, UpdateDeviceCustomNameMutationVariables>(UpdateDeviceCustomNameDocument, options);
      }
export type UpdateDeviceCustomNameMutationHookResult = ReturnType<typeof useUpdateDeviceCustomNameMutation>;
export type UpdateDeviceCustomNameMutationResult = Apollo.MutationResult<UpdateDeviceCustomNameMutation>;
export type UpdateDeviceCustomNameMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceCustomNameMutation, UpdateDeviceCustomNameMutationVariables>;
export const GetDevicesDocument = gql`
    query GetDevices($input: InputGetDevices) {
  getDevices(input: $input) {
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
    data {
      ...DeviceFragment
    }
  }
}
    ${DeviceFragmentFragmentDoc}`;

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDevicesQuery(baseOptions?: Apollo.QueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
      }
export function useGetDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
        }
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>;
export type GetDevicesLazyQueryHookResult = ReturnType<typeof useGetDevicesLazyQuery>;
export type GetDevicesQueryResult = Apollo.QueryResult<GetDevicesQuery, GetDevicesQueryVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($input: InputDeleteDocument) {
  deleteDocument(input: $input) {
    id
    name
    url
    path
    type
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const CreateUpdateDocumentDocument = gql`
    mutation CreateUpdateDocument($input: InputCreateUpdateDocument) {
  createUpdateDocument(input: $input) {
    id
    name
    url
    path
    type
  }
}
    `;
export type CreateUpdateDocumentMutationFn = Apollo.MutationFunction<CreateUpdateDocumentMutation, CreateUpdateDocumentMutationVariables>;

/**
 * __useCreateUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateDocumentMutation, { data, loading, error }] = useCreateUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateDocumentMutation, CreateUpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateDocumentMutation, CreateUpdateDocumentMutationVariables>(CreateUpdateDocumentDocument, options);
      }
export type CreateUpdateDocumentMutationHookResult = ReturnType<typeof useCreateUpdateDocumentMutation>;
export type CreateUpdateDocumentMutationResult = Apollo.MutationResult<CreateUpdateDocumentMutation>;
export type CreateUpdateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateUpdateDocumentMutation, CreateUpdateDocumentMutationVariables>;
export const CreateUpdateDocumentInStepperDocument = gql`
    mutation CreateUpdateDocumentInStepper($input: InputCreateUpdateDocumentInStepper) {
  createUpdateDocumentInStepper(input: $input) {
    id
    name
    url
    path
    type
  }
}
    `;
export type CreateUpdateDocumentInStepperMutationFn = Apollo.MutationFunction<CreateUpdateDocumentInStepperMutation, CreateUpdateDocumentInStepperMutationVariables>;

/**
 * __useCreateUpdateDocumentInStepperMutation__
 *
 * To run a mutation, you first call `useCreateUpdateDocumentInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateDocumentInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateDocumentInStepperMutation, { data, loading, error }] = useCreateUpdateDocumentInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateDocumentInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateDocumentInStepperMutation, CreateUpdateDocumentInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateDocumentInStepperMutation, CreateUpdateDocumentInStepperMutationVariables>(CreateUpdateDocumentInStepperDocument, options);
      }
export type CreateUpdateDocumentInStepperMutationHookResult = ReturnType<typeof useCreateUpdateDocumentInStepperMutation>;
export type CreateUpdateDocumentInStepperMutationResult = Apollo.MutationResult<CreateUpdateDocumentInStepperMutation>;
export type CreateUpdateDocumentInStepperMutationOptions = Apollo.BaseMutationOptions<CreateUpdateDocumentInStepperMutation, CreateUpdateDocumentInStepperMutationVariables>;
export const GetDocumentsBySiteDocument = gql`
    query GetDocumentsBySite($input: InputGetDocumentsBySite) {
  getDocumentsBySite(input: $input) {
    data {
      id
      name
      path
      url
      type
      deleted
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDocumentsBySiteQuery__
 *
 * To run a query within a React component, call `useGetDocumentsBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDocumentsBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentsBySiteQuery, GetDocumentsBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsBySiteQuery, GetDocumentsBySiteQueryVariables>(GetDocumentsBySiteDocument, options);
      }
export function useGetDocumentsBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsBySiteQuery, GetDocumentsBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsBySiteQuery, GetDocumentsBySiteQueryVariables>(GetDocumentsBySiteDocument, options);
        }
export type GetDocumentsBySiteQueryHookResult = ReturnType<typeof useGetDocumentsBySiteQuery>;
export type GetDocumentsBySiteLazyQueryHookResult = ReturnType<typeof useGetDocumentsBySiteLazyQuery>;
export type GetDocumentsBySiteQueryResult = Apollo.QueryResult<GetDocumentsBySiteQuery, GetDocumentsBySiteQueryVariables>;
export const GetDocumentHistoriesDocument = gql`
    query getDocumentHistories($input: InputHistories) {
  getDocumentHistories(input: $input) {
    id
    name
    path
    url
    type
    deleted
  }
}
    `;

/**
 * __useGetDocumentHistoriesQuery__
 *
 * To run a query within a React component, call `useGetDocumentHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDocumentHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentHistoriesQuery, GetDocumentHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentHistoriesQuery, GetDocumentHistoriesQueryVariables>(GetDocumentHistoriesDocument, options);
      }
export function useGetDocumentHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentHistoriesQuery, GetDocumentHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentHistoriesQuery, GetDocumentHistoriesQueryVariables>(GetDocumentHistoriesDocument, options);
        }
export type GetDocumentHistoriesQueryHookResult = ReturnType<typeof useGetDocumentHistoriesQuery>;
export type GetDocumentHistoriesLazyQueryHookResult = ReturnType<typeof useGetDocumentHistoriesLazyQuery>;
export type GetDocumentHistoriesQueryResult = Apollo.QueryResult<GetDocumentHistoriesQuery, GetDocumentHistoriesQueryVariables>;
export const CreateUpdateFileSizeDocument = gql`
    mutation createUpdateFileSize($input: InputCreateUpdateFileSize) {
  createUpdateFileSize(input: $input) {
    id
    size
  }
}
    `;
export type CreateUpdateFileSizeMutationFn = Apollo.MutationFunction<CreateUpdateFileSizeMutation, CreateUpdateFileSizeMutationVariables>;

/**
 * __useCreateUpdateFileSizeMutation__
 *
 * To run a mutation, you first call `useCreateUpdateFileSizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateFileSizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateFileSizeMutation, { data, loading, error }] = useCreateUpdateFileSizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateFileSizeMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateFileSizeMutation, CreateUpdateFileSizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateFileSizeMutation, CreateUpdateFileSizeMutationVariables>(CreateUpdateFileSizeDocument, options);
      }
export type CreateUpdateFileSizeMutationHookResult = ReturnType<typeof useCreateUpdateFileSizeMutation>;
export type CreateUpdateFileSizeMutationResult = Apollo.MutationResult<CreateUpdateFileSizeMutation>;
export type CreateUpdateFileSizeMutationOptions = Apollo.BaseMutationOptions<CreateUpdateFileSizeMutation, CreateUpdateFileSizeMutationVariables>;
export const GetFileSizeDocument = gql`
    query getFileSize {
  getFileSize {
    id
    size
  }
}
    `;

/**
 * __useGetFileSizeQuery__
 *
 * To run a query within a React component, call `useGetFileSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileSizeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileSizeQuery(baseOptions?: Apollo.QueryHookOptions<GetFileSizeQuery, GetFileSizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileSizeQuery, GetFileSizeQueryVariables>(GetFileSizeDocument, options);
      }
export function useGetFileSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileSizeQuery, GetFileSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileSizeQuery, GetFileSizeQueryVariables>(GetFileSizeDocument, options);
        }
export type GetFileSizeQueryHookResult = ReturnType<typeof useGetFileSizeQuery>;
export type GetFileSizeLazyQueryHookResult = ReturnType<typeof useGetFileSizeLazyQuery>;
export type GetFileSizeQueryResult = Apollo.QueryResult<GetFileSizeQuery, GetFileSizeQueryVariables>;
export const DeleteGedOpsDocumentDocument = gql`
    mutation DeleteGedOpsDocument($input: InputDeleteGedOpsDocument) {
  deleteGedOpsDocument(input: $input) {
    ...DepartmentDocumentFragment
  }
}
    ${DepartmentDocumentFragmentFragmentDoc}`;
export type DeleteGedOpsDocumentMutationFn = Apollo.MutationFunction<DeleteGedOpsDocumentMutation, DeleteGedOpsDocumentMutationVariables>;

/**
 * __useDeleteGedOpsDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteGedOpsDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGedOpsDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGedOpsDocumentMutation, { data, loading, error }] = useDeleteGedOpsDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGedOpsDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGedOpsDocumentMutation, DeleteGedOpsDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGedOpsDocumentMutation, DeleteGedOpsDocumentMutationVariables>(DeleteGedOpsDocumentDocument, options);
      }
export type DeleteGedOpsDocumentMutationHookResult = ReturnType<typeof useDeleteGedOpsDocumentMutation>;
export type DeleteGedOpsDocumentMutationResult = Apollo.MutationResult<DeleteGedOpsDocumentMutation>;
export type DeleteGedOpsDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteGedOpsDocumentMutation, DeleteGedOpsDocumentMutationVariables>;
export const CreateUpdateGedOpsDocumentDocument = gql`
    mutation CreateUpdateGedOpsDocument($input: InputCreateUpdateGedOpsDocument) {
  createUpdateGedOpsDocument(input: $input) {
    ...DepartmentDocumentFragment
  }
}
    ${DepartmentDocumentFragmentFragmentDoc}`;
export type CreateUpdateGedOpsDocumentMutationFn = Apollo.MutationFunction<CreateUpdateGedOpsDocumentMutation, CreateUpdateGedOpsDocumentMutationVariables>;

/**
 * __useCreateUpdateGedOpsDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGedOpsDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGedOpsDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGedOpsDocumentMutation, { data, loading, error }] = useCreateUpdateGedOpsDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateGedOpsDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateGedOpsDocumentMutation, CreateUpdateGedOpsDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateGedOpsDocumentMutation, CreateUpdateGedOpsDocumentMutationVariables>(CreateUpdateGedOpsDocumentDocument, options);
      }
export type CreateUpdateGedOpsDocumentMutationHookResult = ReturnType<typeof useCreateUpdateGedOpsDocumentMutation>;
export type CreateUpdateGedOpsDocumentMutationResult = Apollo.MutationResult<CreateUpdateGedOpsDocumentMutation>;
export type CreateUpdateGedOpsDocumentMutationOptions = Apollo.BaseMutationOptions<CreateUpdateGedOpsDocumentMutation, CreateUpdateGedOpsDocumentMutationVariables>;
export const GetGedOpsDocumentsDocument = gql`
    query GetGedOpsDocuments($input: InputGetGedOpsDocuments) {
  getGedOpsDocuments(input: $input) {
    data {
      ...DepartmentDocumentFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${DepartmentDocumentFragmentFragmentDoc}`;

/**
 * __useGetGedOpsDocumentsQuery__
 *
 * To run a query within a React component, call `useGetGedOpsDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGedOpsDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGedOpsDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGedOpsDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetGedOpsDocumentsQuery, GetGedOpsDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGedOpsDocumentsQuery, GetGedOpsDocumentsQueryVariables>(GetGedOpsDocumentsDocument, options);
      }
export function useGetGedOpsDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGedOpsDocumentsQuery, GetGedOpsDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGedOpsDocumentsQuery, GetGedOpsDocumentsQueryVariables>(GetGedOpsDocumentsDocument, options);
        }
export type GetGedOpsDocumentsQueryHookResult = ReturnType<typeof useGetGedOpsDocumentsQuery>;
export type GetGedOpsDocumentsLazyQueryHookResult = ReturnType<typeof useGetGedOpsDocumentsLazyQuery>;
export type GetGedOpsDocumentsQueryResult = Apollo.QueryResult<GetGedOpsDocumentsQuery, GetGedOpsDocumentsQueryVariables>;
export const GetGedOpsDocumentDocument = gql`
    query GetGedOpsDocument($input: InputGetGedOpsDocument) {
  getGedOpsDocument(input: $input) {
    ...DepartmentDocumentFragment
  }
}
    ${DepartmentDocumentFragmentFragmentDoc}`;

/**
 * __useGetGedOpsDocumentQuery__
 *
 * To run a query within a React component, call `useGetGedOpsDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGedOpsDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGedOpsDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGedOpsDocumentQuery(baseOptions?: Apollo.QueryHookOptions<GetGedOpsDocumentQuery, GetGedOpsDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGedOpsDocumentQuery, GetGedOpsDocumentQueryVariables>(GetGedOpsDocumentDocument, options);
      }
export function useGetGedOpsDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGedOpsDocumentQuery, GetGedOpsDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGedOpsDocumentQuery, GetGedOpsDocumentQueryVariables>(GetGedOpsDocumentDocument, options);
        }
export type GetGedOpsDocumentQueryHookResult = ReturnType<typeof useGetGedOpsDocumentQuery>;
export type GetGedOpsDocumentLazyQueryHookResult = ReturnType<typeof useGetGedOpsDocumentLazyQuery>;
export type GetGedOpsDocumentQueryResult = Apollo.QueryResult<GetGedOpsDocumentQuery, GetGedOpsDocumentQueryVariables>;
export const GetLogsDocument = gql`
    query getLogs($input: InputGetLogs) {
  getLogs(input: $input) {
    data {
      id
      siteId
      siteName
      modelId
      model
      authorId
      authorName
      method
      subModel
      message
      messageDecrypted
      createdAt
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
      }
export function useGetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<GetLogsQuery, GetLogsQueryVariables>;
export const CreateUpdateMoyenDocument = gql`
    mutation CreateUpdateMoyen($input: InputCreateUpdateMoyen) {
  createUpdateMoyen(input: $input) {
    id
    note
  }
}
    `;
export type CreateUpdateMoyenMutationFn = Apollo.MutationFunction<CreateUpdateMoyenMutation, CreateUpdateMoyenMutationVariables>;

/**
 * __useCreateUpdateMoyenMutation__
 *
 * To run a mutation, you first call `useCreateUpdateMoyenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateMoyenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateMoyenMutation, { data, loading, error }] = useCreateUpdateMoyenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateMoyenMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateMoyenMutation, CreateUpdateMoyenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateMoyenMutation, CreateUpdateMoyenMutationVariables>(CreateUpdateMoyenDocument, options);
      }
export type CreateUpdateMoyenMutationHookResult = ReturnType<typeof useCreateUpdateMoyenMutation>;
export type CreateUpdateMoyenMutationResult = Apollo.MutationResult<CreateUpdateMoyenMutation>;
export type CreateUpdateMoyenMutationOptions = Apollo.BaseMutationOptions<CreateUpdateMoyenMutation, CreateUpdateMoyenMutationVariables>;
export const DeleteMoyenDocument = gql`
    mutation deleteMoyen($input: InputDeleteMoyen) {
  deleteMoyen(input: $input)
}
    `;
export type DeleteMoyenMutationFn = Apollo.MutationFunction<DeleteMoyenMutation, DeleteMoyenMutationVariables>;

/**
 * __useDeleteMoyenMutation__
 *
 * To run a mutation, you first call `useDeleteMoyenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMoyenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMoyenMutation, { data, loading, error }] = useDeleteMoyenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMoyenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMoyenMutation, DeleteMoyenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMoyenMutation, DeleteMoyenMutationVariables>(DeleteMoyenDocument, options);
      }
export type DeleteMoyenMutationHookResult = ReturnType<typeof useDeleteMoyenMutation>;
export type DeleteMoyenMutationResult = Apollo.MutationResult<DeleteMoyenMutation>;
export type DeleteMoyenMutationOptions = Apollo.BaseMutationOptions<DeleteMoyenMutation, DeleteMoyenMutationVariables>;
export const CreateUpdateMoyenInStepperDocument = gql`
    mutation CreateUpdateMoyenInStepper($input: InputCreateUpdateMoyenInStepper) {
  createUpdateMoyenInStepper(input: $input) {
    id
    note
  }
}
    `;
export type CreateUpdateMoyenInStepperMutationFn = Apollo.MutationFunction<CreateUpdateMoyenInStepperMutation, CreateUpdateMoyenInStepperMutationVariables>;

/**
 * __useCreateUpdateMoyenInStepperMutation__
 *
 * To run a mutation, you first call `useCreateUpdateMoyenInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateMoyenInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateMoyenInStepperMutation, { data, loading, error }] = useCreateUpdateMoyenInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateMoyenInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateMoyenInStepperMutation, CreateUpdateMoyenInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateMoyenInStepperMutation, CreateUpdateMoyenInStepperMutationVariables>(CreateUpdateMoyenInStepperDocument, options);
      }
export type CreateUpdateMoyenInStepperMutationHookResult = ReturnType<typeof useCreateUpdateMoyenInStepperMutation>;
export type CreateUpdateMoyenInStepperMutationResult = Apollo.MutationResult<CreateUpdateMoyenInStepperMutation>;
export type CreateUpdateMoyenInStepperMutationOptions = Apollo.BaseMutationOptions<CreateUpdateMoyenInStepperMutation, CreateUpdateMoyenInStepperMutationVariables>;
export const GetMoyenBySiteDocument = gql`
    query GetMoyenBySite($input: InputGetMoyenBySite) {
  getMoyenBySite(input: $input) {
    id
    note
    noteDecrypted
    siteId
  }
}
    `;

/**
 * __useGetMoyenBySiteQuery__
 *
 * To run a query within a React component, call `useGetMoyenBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoyenBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoyenBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMoyenBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetMoyenBySiteQuery, GetMoyenBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoyenBySiteQuery, GetMoyenBySiteQueryVariables>(GetMoyenBySiteDocument, options);
      }
export function useGetMoyenBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoyenBySiteQuery, GetMoyenBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoyenBySiteQuery, GetMoyenBySiteQueryVariables>(GetMoyenBySiteDocument, options);
        }
export type GetMoyenBySiteQueryHookResult = ReturnType<typeof useGetMoyenBySiteQuery>;
export type GetMoyenBySiteLazyQueryHookResult = ReturnType<typeof useGetMoyenBySiteLazyQuery>;
export type GetMoyenBySiteQueryResult = Apollo.QueryResult<GetMoyenBySiteQuery, GetMoyenBySiteQueryVariables>;
export const GetAllMoyenDocument = gql`
    query GetAllMoyen {
  getAllMoyen {
    id
    note
    noteDecrypted
    siteId
  }
}
    `;

/**
 * __useGetAllMoyenQuery__
 *
 * To run a query within a React component, call `useGetAllMoyenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMoyenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMoyenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMoyenQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMoyenQuery, GetAllMoyenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMoyenQuery, GetAllMoyenQueryVariables>(GetAllMoyenDocument, options);
      }
export function useGetAllMoyenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMoyenQuery, GetAllMoyenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMoyenQuery, GetAllMoyenQueryVariables>(GetAllMoyenDocument, options);
        }
export type GetAllMoyenQueryHookResult = ReturnType<typeof useGetAllMoyenQuery>;
export type GetAllMoyenLazyQueryHookResult = ReturnType<typeof useGetAllMoyenLazyQuery>;
export type GetAllMoyenQueryResult = Apollo.QueryResult<GetAllMoyenQuery, GetAllMoyenQueryVariables>;
export const GetMoyensDocument = gql`
    query GetMoyens($input: InputGetAllMoyen) {
  getMoyens(input: $input) {
    data {
      id
      note
      noteDecrypted
      siteId
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetMoyensQuery__
 *
 * To run a query within a React component, call `useGetMoyensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoyensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoyensQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMoyensQuery(baseOptions?: Apollo.QueryHookOptions<GetMoyensQuery, GetMoyensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoyensQuery, GetMoyensQueryVariables>(GetMoyensDocument, options);
      }
export function useGetMoyensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoyensQuery, GetMoyensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoyensQuery, GetMoyensQueryVariables>(GetMoyensDocument, options);
        }
export type GetMoyensQueryHookResult = ReturnType<typeof useGetMoyensQuery>;
export type GetMoyensLazyQueryHookResult = ReturnType<typeof useGetMoyensLazyQuery>;
export type GetMoyensQueryResult = Apollo.QueryResult<GetMoyensQuery, GetMoyensQueryVariables>;
export const GetMoyenHistoryDocument = gql`
    query getMoyenHistory($input: InputMoyenHistory) {
  getMoyenHistory(input: $input) {
    id
    note
    noteDecrypted
    siteId
  }
}
    `;

/**
 * __useGetMoyenHistoryQuery__
 *
 * To run a query within a React component, call `useGetMoyenHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoyenHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoyenHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMoyenHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetMoyenHistoryQuery, GetMoyenHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoyenHistoryQuery, GetMoyenHistoryQueryVariables>(GetMoyenHistoryDocument, options);
      }
export function useGetMoyenHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoyenHistoryQuery, GetMoyenHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoyenHistoryQuery, GetMoyenHistoryQueryVariables>(GetMoyenHistoryDocument, options);
        }
export type GetMoyenHistoryQueryHookResult = ReturnType<typeof useGetMoyenHistoryQuery>;
export type GetMoyenHistoryLazyQueryHookResult = ReturnType<typeof useGetMoyenHistoryLazyQuery>;
export type GetMoyenHistoryQueryResult = Apollo.QueryResult<GetMoyenHistoryQuery, GetMoyenHistoryQueryVariables>;
export const UpdateNotificationByIdDocument = gql`
    mutation updateNotificationById($id: String!, $isRead: Boolean!) {
  updateNotificationById(id: $id, isRead: $isRead)
}
    `;
export type UpdateNotificationByIdMutationFn = Apollo.MutationFunction<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>;

/**
 * __useUpdateNotificationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationByIdMutation, { data, loading, error }] = useUpdateNotificationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isRead: // value for 'isRead'
 *   },
 * });
 */
export function useUpdateNotificationByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>(UpdateNotificationByIdDocument, options);
      }
export type UpdateNotificationByIdMutationHookResult = ReturnType<typeof useUpdateNotificationByIdMutation>;
export type UpdateNotificationByIdMutationResult = Apollo.MutationResult<UpdateNotificationByIdMutation>;
export type UpdateNotificationByIdMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationByIdMutation, UpdateNotificationByIdMutationVariables>;
export const GetCurrentUserNotificationsDocument = gql`
    query getCurrentUserNotifications($input: InputGetUserNotifications) {
  getCurrentUserNotifications(input: $input) {
    data {
      ...NotificationFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetCurrentUserNotificationsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>(GetCurrentUserNotificationsDocument, options);
      }
export function useGetCurrentUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>(GetCurrentUserNotificationsDocument, options);
        }
export type GetCurrentUserNotificationsQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsQuery>;
export type GetCurrentUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsLazyQuery>;
export type GetCurrentUserNotificationsQueryResult = Apollo.QueryResult<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>;
export const GetUpdatedNotificationsDocument = gql`
    subscription getUpdatedNotifications {
  getUpdatedNotifications {
    data {
      ...NotificationFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetUpdatedNotificationsSubscription__
 *
 * To run a query within a React component, call `useGetUpdatedNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUpdatedNotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUpdatedNotificationsSubscription, GetUpdatedNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUpdatedNotificationsSubscription, GetUpdatedNotificationsSubscriptionVariables>(GetUpdatedNotificationsDocument, options);
      }
export type GetUpdatedNotificationsSubscriptionHookResult = ReturnType<typeof useGetUpdatedNotificationsSubscription>;
export type GetUpdatedNotificationsSubscriptionResult = Apollo.SubscriptionResult<GetUpdatedNotificationsSubscription>;
export const GetAllPermissionDocument = gql`
    query GetAllPermission($input: InputGetAllPermissions) {
  getAllPermissions(input: $input) {
    edges {
      node {
        id
        name
        hidden
      }
    }
  }
}
    `;

/**
 * __useGetAllPermissionQuery__
 *
 * To run a query within a React component, call `useGetAllPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPermissionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllPermissionQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(GetAllPermissionDocument, options);
      }
export function useGetAllPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(GetAllPermissionDocument, options);
        }
export type GetAllPermissionQueryHookResult = ReturnType<typeof useGetAllPermissionQuery>;
export type GetAllPermissionLazyQueryHookResult = ReturnType<typeof useGetAllPermissionLazyQuery>;
export type GetAllPermissionQueryResult = Apollo.QueryResult<GetAllPermissionQuery, GetAllPermissionQueryVariables>;
export const CreateUpdatePlanDocument = gql`
    mutation CreateUpdatePlan($input: InputCreateUpdatePlan) {
  createUpdatePlan(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type CreateUpdatePlanMutationFn = Apollo.MutationFunction<CreateUpdatePlanMutation, CreateUpdatePlanMutationVariables>;

/**
 * __useCreateUpdatePlanMutation__
 *
 * To run a mutation, you first call `useCreateUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdatePlanMutation, { data, loading, error }] = useCreateUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdatePlanMutation, CreateUpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdatePlanMutation, CreateUpdatePlanMutationVariables>(CreateUpdatePlanDocument, options);
      }
export type CreateUpdatePlanMutationHookResult = ReturnType<typeof useCreateUpdatePlanMutation>;
export type CreateUpdatePlanMutationResult = Apollo.MutationResult<CreateUpdatePlanMutation>;
export type CreateUpdatePlanMutationOptions = Apollo.BaseMutationOptions<CreateUpdatePlanMutation, CreateUpdatePlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation deletePlan($input: InputDeletePlan) {
  deletePlan(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const SavePlansOrderDocument = gql`
    mutation SavePlansOrder($input: InputSavePlansOrder) {
  savePlansOrder(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type SavePlansOrderMutationFn = Apollo.MutationFunction<SavePlansOrderMutation, SavePlansOrderMutationVariables>;

/**
 * __useSavePlansOrderMutation__
 *
 * To run a mutation, you first call `useSavePlansOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlansOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlansOrderMutation, { data, loading, error }] = useSavePlansOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePlansOrderMutation(baseOptions?: Apollo.MutationHookOptions<SavePlansOrderMutation, SavePlansOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePlansOrderMutation, SavePlansOrderMutationVariables>(SavePlansOrderDocument, options);
      }
export type SavePlansOrderMutationHookResult = ReturnType<typeof useSavePlansOrderMutation>;
export type SavePlansOrderMutationResult = Apollo.MutationResult<SavePlansOrderMutation>;
export type SavePlansOrderMutationOptions = Apollo.BaseMutationOptions<SavePlansOrderMutation, SavePlansOrderMutationVariables>;
export const CreateUpdatePlanInStepperDocument = gql`
    mutation CreateUpdatePlanInStepper($input: InputCreateUpdatePlanInStepper) {
  createUpdatePlanInStepper(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;
export type CreateUpdatePlanInStepperMutationFn = Apollo.MutationFunction<CreateUpdatePlanInStepperMutation, CreateUpdatePlanInStepperMutationVariables>;

/**
 * __useCreateUpdatePlanInStepperMutation__
 *
 * To run a mutation, you first call `useCreateUpdatePlanInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdatePlanInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdatePlanInStepperMutation, { data, loading, error }] = useCreateUpdatePlanInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdatePlanInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdatePlanInStepperMutation, CreateUpdatePlanInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdatePlanInStepperMutation, CreateUpdatePlanInStepperMutationVariables>(CreateUpdatePlanInStepperDocument, options);
      }
export type CreateUpdatePlanInStepperMutationHookResult = ReturnType<typeof useCreateUpdatePlanInStepperMutation>;
export type CreateUpdatePlanInStepperMutationResult = Apollo.MutationResult<CreateUpdatePlanInStepperMutation>;
export type CreateUpdatePlanInStepperMutationOptions = Apollo.BaseMutationOptions<CreateUpdatePlanInStepperMutation, CreateUpdatePlanInStepperMutationVariables>;
export const GetPlansBySiteDocument = gql`
    query GetPlansBySite($input: InputGetPlansBySite) {
  getPlansBySite(input: $input) {
    data {
      ...PlanFragment
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useGetPlansBySiteQuery__
 *
 * To run a query within a React component, call `useGetPlansBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlansBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansBySiteQuery, GetPlansBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansBySiteQuery, GetPlansBySiteQueryVariables>(GetPlansBySiteDocument, options);
      }
export function useGetPlansBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansBySiteQuery, GetPlansBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansBySiteQuery, GetPlansBySiteQueryVariables>(GetPlansBySiteDocument, options);
        }
export type GetPlansBySiteQueryHookResult = ReturnType<typeof useGetPlansBySiteQuery>;
export type GetPlansBySiteLazyQueryHookResult = ReturnType<typeof useGetPlansBySiteLazyQuery>;
export type GetPlansBySiteQueryResult = Apollo.QueryResult<GetPlansBySiteQuery, GetPlansBySiteQueryVariables>;
export const GetAllPlansBySiteDocument = gql`
    query GetAllPlansBySite($input: InputGetAllPlansBySite) {
  getAllPlansBySite(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useGetAllPlansBySiteQuery__
 *
 * To run a query within a React component, call `useGetAllPlansBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlansBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlansBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllPlansBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPlansBySiteQuery, GetAllPlansBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPlansBySiteQuery, GetAllPlansBySiteQueryVariables>(GetAllPlansBySiteDocument, options);
      }
export function useGetAllPlansBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPlansBySiteQuery, GetAllPlansBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPlansBySiteQuery, GetAllPlansBySiteQueryVariables>(GetAllPlansBySiteDocument, options);
        }
export type GetAllPlansBySiteQueryHookResult = ReturnType<typeof useGetAllPlansBySiteQuery>;
export type GetAllPlansBySiteLazyQueryHookResult = ReturnType<typeof useGetAllPlansBySiteLazyQuery>;
export type GetAllPlansBySiteQueryResult = Apollo.QueryResult<GetAllPlansBySiteQuery, GetAllPlansBySiteQueryVariables>;
export const GetPlanHistoriesDocument = gql`
    query getPlanHistories($input: InputPlanHistories) {
  getPlanHistories(input: $input) {
    ...PlanFragment
  }
}
    ${PlanFragmentFragmentDoc}`;

/**
 * __useGetPlanHistoriesQuery__
 *
 * To run a query within a React component, call `useGetPlanHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlanHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanHistoriesQuery, GetPlanHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanHistoriesQuery, GetPlanHistoriesQueryVariables>(GetPlanHistoriesDocument, options);
      }
export function useGetPlanHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanHistoriesQuery, GetPlanHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanHistoriesQuery, GetPlanHistoriesQueryVariables>(GetPlanHistoriesDocument, options);
        }
export type GetPlanHistoriesQueryHookResult = ReturnType<typeof useGetPlanHistoriesQuery>;
export type GetPlanHistoriesLazyQueryHookResult = ReturnType<typeof useGetPlanHistoriesLazyQuery>;
export type GetPlanHistoriesQueryResult = Apollo.QueryResult<GetPlanHistoriesQuery, GetPlanHistoriesQueryVariables>;
export const CheckReferenceDocument = gql`
    mutation checkReference($input: InputCheckReference) {
  checkReference(input: $input) {
    isDuplicated
    reference
  }
}
    `;
export type CheckReferenceMutationFn = Apollo.MutationFunction<CheckReferenceMutation, CheckReferenceMutationVariables>;

/**
 * __useCheckReferenceMutation__
 *
 * To run a mutation, you first call `useCheckReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkReferenceMutation, { data, loading, error }] = useCheckReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckReferenceMutation(baseOptions?: Apollo.MutationHookOptions<CheckReferenceMutation, CheckReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckReferenceMutation, CheckReferenceMutationVariables>(CheckReferenceDocument, options);
      }
export type CheckReferenceMutationHookResult = ReturnType<typeof useCheckReferenceMutation>;
export type CheckReferenceMutationResult = Apollo.MutationResult<CheckReferenceMutation>;
export type CheckReferenceMutationOptions = Apollo.BaseMutationOptions<CheckReferenceMutation, CheckReferenceMutationVariables>;
export const CreateSiteFullInfoDocument = gql`
    mutation createSiteFullInfo($input: InputCreateSiteFullInfo) {
  createSiteFullInfo(input: $input) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;
export type CreateSiteFullInfoMutationFn = Apollo.MutationFunction<CreateSiteFullInfoMutation, CreateSiteFullInfoMutationVariables>;

/**
 * __useCreateSiteFullInfoMutation__
 *
 * To run a mutation, you first call `useCreateSiteFullInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteFullInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteFullInfoMutation, { data, loading, error }] = useCreateSiteFullInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteFullInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteFullInfoMutation, CreateSiteFullInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteFullInfoMutation, CreateSiteFullInfoMutationVariables>(CreateSiteFullInfoDocument, options);
      }
export type CreateSiteFullInfoMutationHookResult = ReturnType<typeof useCreateSiteFullInfoMutation>;
export type CreateSiteFullInfoMutationResult = Apollo.MutationResult<CreateSiteFullInfoMutation>;
export type CreateSiteFullInfoMutationOptions = Apollo.BaseMutationOptions<CreateSiteFullInfoMutation, CreateSiteFullInfoMutationVariables>;
export const CreateUpdateSiteDocument = gql`
    mutation CreateUpdateSite($input: InputCreateSite) {
  createUpdateSite(input: $input) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;
export type CreateUpdateSiteMutationFn = Apollo.MutationFunction<CreateUpdateSiteMutation, CreateUpdateSiteMutationVariables>;

/**
 * __useCreateUpdateSiteMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSiteMutation, { data, loading, error }] = useCreateUpdateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateSiteMutation, CreateUpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateSiteMutation, CreateUpdateSiteMutationVariables>(CreateUpdateSiteDocument, options);
      }
export type CreateUpdateSiteMutationHookResult = ReturnType<typeof useCreateUpdateSiteMutation>;
export type CreateUpdateSiteMutationResult = Apollo.MutationResult<CreateUpdateSiteMutation>;
export type CreateUpdateSiteMutationOptions = Apollo.BaseMutationOptions<CreateUpdateSiteMutation, CreateUpdateSiteMutationVariables>;
export const DeleteSitesDocument = gql`
    mutation deleteSites($input: InputDeleteSite) {
  deleteSite(input: $input)
}
    `;
export type DeleteSitesMutationFn = Apollo.MutationFunction<DeleteSitesMutation, DeleteSitesMutationVariables>;

/**
 * __useDeleteSitesMutation__
 *
 * To run a mutation, you first call `useDeleteSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSitesMutation, { data, loading, error }] = useDeleteSitesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSitesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSitesMutation, DeleteSitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSitesMutation, DeleteSitesMutationVariables>(DeleteSitesDocument, options);
      }
export type DeleteSitesMutationHookResult = ReturnType<typeof useDeleteSitesMutation>;
export type DeleteSitesMutationResult = Apollo.MutationResult<DeleteSitesMutation>;
export type DeleteSitesMutationOptions = Apollo.BaseMutationOptions<DeleteSitesMutation, DeleteSitesMutationVariables>;
export const AcceptCreateRequestDocument = gql`
    mutation acceptCreateRequest($input: InputAcceptCreateWithoutAuthoriGroupId) {
  acceptCreateRequest(input: $input)
}
    `;
export type AcceptCreateRequestMutationFn = Apollo.MutationFunction<AcceptCreateRequestMutation, AcceptCreateRequestMutationVariables>;

/**
 * __useAcceptCreateRequestMutation__
 *
 * To run a mutation, you first call `useAcceptCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCreateRequestMutation, { data, loading, error }] = useAcceptCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCreateRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCreateRequestMutation, AcceptCreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCreateRequestMutation, AcceptCreateRequestMutationVariables>(AcceptCreateRequestDocument, options);
      }
export type AcceptCreateRequestMutationHookResult = ReturnType<typeof useAcceptCreateRequestMutation>;
export type AcceptCreateRequestMutationResult = Apollo.MutationResult<AcceptCreateRequestMutation>;
export type AcceptCreateRequestMutationOptions = Apollo.BaseMutationOptions<AcceptCreateRequestMutation, AcceptCreateRequestMutationVariables>;
export const AcceptEditRequestDocument = gql`
    mutation acceptEditRequest($input: InputAcceptCreateRequest) {
  acceptEditRequest(input: $input)
}
    `;
export type AcceptEditRequestMutationFn = Apollo.MutationFunction<AcceptEditRequestMutation, AcceptEditRequestMutationVariables>;

/**
 * __useAcceptEditRequestMutation__
 *
 * To run a mutation, you first call `useAcceptEditRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEditRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEditRequestMutation, { data, loading, error }] = useAcceptEditRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptEditRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptEditRequestMutation, AcceptEditRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptEditRequestMutation, AcceptEditRequestMutationVariables>(AcceptEditRequestDocument, options);
      }
export type AcceptEditRequestMutationHookResult = ReturnType<typeof useAcceptEditRequestMutation>;
export type AcceptEditRequestMutationResult = Apollo.MutationResult<AcceptEditRequestMutation>;
export type AcceptEditRequestMutationOptions = Apollo.BaseMutationOptions<AcceptEditRequestMutation, AcceptEditRequestMutationVariables>;
export const RejectRequestDocument = gql`
    mutation rejectRequest($input: InputAcceptCreateRequest) {
  rejectRequest(input: $input)
}
    `;
export type RejectRequestMutationFn = Apollo.MutationFunction<RejectRequestMutation, RejectRequestMutationVariables>;

/**
 * __useRejectRequestMutation__
 *
 * To run a mutation, you first call `useRejectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRequestMutation, { data, loading, error }] = useRejectRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectRequestMutation, RejectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectRequestMutation, RejectRequestMutationVariables>(RejectRequestDocument, options);
      }
export type RejectRequestMutationHookResult = ReturnType<typeof useRejectRequestMutation>;
export type RejectRequestMutationResult = Apollo.MutationResult<RejectRequestMutation>;
export type RejectRequestMutationOptions = Apollo.BaseMutationOptions<RejectRequestMutation, RejectRequestMutationVariables>;
export const DeleteSiteFileOnAwsDocument = gql`
    mutation deleteSiteFileOnAWS($input: InputSiteFile) {
  deleteSiteFileOnAWS(input: $input)
}
    `;
export type DeleteSiteFileOnAwsMutationFn = Apollo.MutationFunction<DeleteSiteFileOnAwsMutation, DeleteSiteFileOnAwsMutationVariables>;

/**
 * __useDeleteSiteFileOnAwsMutation__
 *
 * To run a mutation, you first call `useDeleteSiteFileOnAwsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteFileOnAwsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteFileOnAwsMutation, { data, loading, error }] = useDeleteSiteFileOnAwsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteFileOnAwsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSiteFileOnAwsMutation, DeleteSiteFileOnAwsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSiteFileOnAwsMutation, DeleteSiteFileOnAwsMutationVariables>(DeleteSiteFileOnAwsDocument, options);
      }
export type DeleteSiteFileOnAwsMutationHookResult = ReturnType<typeof useDeleteSiteFileOnAwsMutation>;
export type DeleteSiteFileOnAwsMutationResult = Apollo.MutationResult<DeleteSiteFileOnAwsMutation>;
export type DeleteSiteFileOnAwsMutationOptions = Apollo.BaseMutationOptions<DeleteSiteFileOnAwsMutation, DeleteSiteFileOnAwsMutationVariables>;
export const UpdateSensibilityDocument = gql`
    mutation updateSensibility($input: InputUpdateSensibility) {
  updateSensibility(input: $input)
}
    `;
export type UpdateSensibilityMutationFn = Apollo.MutationFunction<UpdateSensibilityMutation, UpdateSensibilityMutationVariables>;

/**
 * __useUpdateSensibilityMutation__
 *
 * To run a mutation, you first call `useUpdateSensibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensibilityMutation, { data, loading, error }] = useUpdateSensibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensibilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensibilityMutation, UpdateSensibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensibilityMutation, UpdateSensibilityMutationVariables>(UpdateSensibilityDocument, options);
      }
export type UpdateSensibilityMutationHookResult = ReturnType<typeof useUpdateSensibilityMutation>;
export type UpdateSensibilityMutationResult = Apollo.MutationResult<UpdateSensibilityMutation>;
export type UpdateSensibilityMutationOptions = Apollo.BaseMutationOptions<UpdateSensibilityMutation, UpdateSensibilityMutationVariables>;
export const CreateOrUpdateSiteGeneralInfoDocument = gql`
    mutation createOrUpdateSiteGeneralInfo($input: InputCreateUpdateSiteGeneralInfo) {
  createOrUpdateSiteGeneralInfo(input: $input) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;
export type CreateOrUpdateSiteGeneralInfoMutationFn = Apollo.MutationFunction<CreateOrUpdateSiteGeneralInfoMutation, CreateOrUpdateSiteGeneralInfoMutationVariables>;

/**
 * __useCreateOrUpdateSiteGeneralInfoMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSiteGeneralInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSiteGeneralInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSiteGeneralInfoMutation, { data, loading, error }] = useCreateOrUpdateSiteGeneralInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateSiteGeneralInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSiteGeneralInfoMutation, CreateOrUpdateSiteGeneralInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSiteGeneralInfoMutation, CreateOrUpdateSiteGeneralInfoMutationVariables>(CreateOrUpdateSiteGeneralInfoDocument, options);
      }
export type CreateOrUpdateSiteGeneralInfoMutationHookResult = ReturnType<typeof useCreateOrUpdateSiteGeneralInfoMutation>;
export type CreateOrUpdateSiteGeneralInfoMutationResult = Apollo.MutationResult<CreateOrUpdateSiteGeneralInfoMutation>;
export type CreateOrUpdateSiteGeneralInfoMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateSiteGeneralInfoMutation, CreateOrUpdateSiteGeneralInfoMutationVariables>;
export const UpdateCoordinatesDocument = gql`
    mutation updateCoordinates($input: InputUpdateCoordinates) {
  updateCoordinates(input: $input) {
    id
    name
    logoUrl
    communeId
    createdAt
    status
    isFromBatiregistre
    managePasswordBySupervisor
    authorizedGroups {
      id
      name
    }
    siteHistories {
      id
      field
      status
      value
    }
    planHistories
    documentHistories
    contactHistories
    commune {
      id
      name
      department {
        id
        name
      }
    }
    siteType {
      id
      name
    }
    etare
    referenceEtare
    sensible
    password
    passwordDecrypted
    address
    riskLevel
    activity
    ranking
    coordinates
    reference
    town
    author {
      lastName
      firstName
      userGroup {
        id
        name
      }
    }
    zipCode
    bienHistory
    latitude
    longitude
  }
}
    `;
export type UpdateCoordinatesMutationFn = Apollo.MutationFunction<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>;

/**
 * __useUpdateCoordinatesMutation__
 *
 * To run a mutation, you first call `useUpdateCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoordinatesMutation, { data, loading, error }] = useUpdateCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>(UpdateCoordinatesDocument, options);
      }
export type UpdateCoordinatesMutationHookResult = ReturnType<typeof useUpdateCoordinatesMutation>;
export type UpdateCoordinatesMutationResult = Apollo.MutationResult<UpdateCoordinatesMutation>;
export type UpdateCoordinatesMutationOptions = Apollo.BaseMutationOptions<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>;
export const GetAllSiteDocument = gql`
    query getAllSite($input: InputGetAllSite) {
  allSites(input: $input) {
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
    data {
      ...SiteFragment
    }
  }
}
    ${SiteFragmentFragmentDoc}`;

/**
 * __useGetAllSiteQuery__
 *
 * To run a query within a React component, call `useGetAllSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllSiteQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSiteQuery, GetAllSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSiteQuery, GetAllSiteQueryVariables>(GetAllSiteDocument, options);
      }
export function useGetAllSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSiteQuery, GetAllSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSiteQuery, GetAllSiteQueryVariables>(GetAllSiteDocument, options);
        }
export type GetAllSiteQueryHookResult = ReturnType<typeof useGetAllSiteQuery>;
export type GetAllSiteLazyQueryHookResult = ReturnType<typeof useGetAllSiteLazyQuery>;
export type GetAllSiteQueryResult = Apollo.QueryResult<GetAllSiteQuery, GetAllSiteQueryVariables>;
export const GetSiteByIdDocument = gql`
    query GetSiteById($input: InputGetSiteById) {
  getSiteById(input: $input) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;

/**
 * __useGetSiteByIdQuery__
 *
 * To run a query within a React component, call `useGetSiteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSiteByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteByIdQuery, GetSiteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteByIdQuery, GetSiteByIdQueryVariables>(GetSiteByIdDocument, options);
      }
export function useGetSiteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteByIdQuery, GetSiteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteByIdQuery, GetSiteByIdQueryVariables>(GetSiteByIdDocument, options);
        }
export type GetSiteByIdQueryHookResult = ReturnType<typeof useGetSiteByIdQuery>;
export type GetSiteByIdLazyQueryHookResult = ReturnType<typeof useGetSiteByIdLazyQuery>;
export type GetSiteByIdQueryResult = Apollo.QueryResult<GetSiteByIdQuery, GetSiteByIdQueryVariables>;
export const GetAllCreationRequestDocument = gql`
    query GetAllCreationRequest {
  getAllCreationRequest {
    id
    name
    logoUrl
    communeId
    createdAt
    status
    isFromBatiregistre
    managePasswordBySupervisor
    authorizedGroups {
      id
      name
    }
    siteHistories {
      id
      field
      status
      value
    }
    planHistories
    documentHistories
    contactHistories
    commune {
      id
      name
      department {
        id
        name
      }
    }
    siteType {
      id
      name
    }
    etare
    referenceEtare
    sensible
    password
    passwordDecrypted
    address
    riskLevel
    activity
    ranking
    coordinates
    reference
    town
    author {
      lastName
      firstName
      userGroup {
        id
        name
      }
    }
    zipCode
    bienHistory
  }
}
    `;

/**
 * __useGetAllCreationRequestQuery__
 *
 * To run a query within a React component, call `useGetAllCreationRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCreationRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCreationRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCreationRequestQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCreationRequestQuery, GetAllCreationRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCreationRequestQuery, GetAllCreationRequestQueryVariables>(GetAllCreationRequestDocument, options);
      }
export function useGetAllCreationRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCreationRequestQuery, GetAllCreationRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCreationRequestQuery, GetAllCreationRequestQueryVariables>(GetAllCreationRequestDocument, options);
        }
export type GetAllCreationRequestQueryHookResult = ReturnType<typeof useGetAllCreationRequestQuery>;
export type GetAllCreationRequestLazyQueryHookResult = ReturnType<typeof useGetAllCreationRequestLazyQuery>;
export type GetAllCreationRequestQueryResult = Apollo.QueryResult<GetAllCreationRequestQuery, GetAllCreationRequestQueryVariables>;
export const GetAllEditRequestDocument = gql`
    query GetAllEditRequest {
  getAllEditRequest {
    sites {
      ...EditRequestSiteList
    }
    editorGroupName
    editorId
  }
}
    ${EditRequestSiteListFragmentDoc}`;

/**
 * __useGetAllEditRequestQuery__
 *
 * To run a query within a React component, call `useGetAllEditRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEditRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEditRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEditRequestQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEditRequestQuery, GetAllEditRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEditRequestQuery, GetAllEditRequestQueryVariables>(GetAllEditRequestDocument, options);
      }
export function useGetAllEditRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEditRequestQuery, GetAllEditRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEditRequestQuery, GetAllEditRequestQueryVariables>(GetAllEditRequestDocument, options);
        }
export type GetAllEditRequestQueryHookResult = ReturnType<typeof useGetAllEditRequestQuery>;
export type GetAllEditRequestLazyQueryHookResult = ReturnType<typeof useGetAllEditRequestLazyQuery>;
export type GetAllEditRequestQueryResult = Apollo.QueryResult<GetAllEditRequestQuery, GetAllEditRequestQueryVariables>;
export const CheckHistoriesDataDocument = gql`
    query checkHistoriesData($input: InputHistoryData) {
  checkHistoriesData(input: $input) {
    hasPlanHistories
    hasContactHistories
    hasDocumentHistories
    hasCurrentRiskHistories
    hasSpecificRiskHistories
    hasMoyenHistories
    hasBienHistories
  }
}
    `;

/**
 * __useCheckHistoriesDataQuery__
 *
 * To run a query within a React component, call `useCheckHistoriesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckHistoriesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckHistoriesDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckHistoriesDataQuery(baseOptions?: Apollo.QueryHookOptions<CheckHistoriesDataQuery, CheckHistoriesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckHistoriesDataQuery, CheckHistoriesDataQueryVariables>(CheckHistoriesDataDocument, options);
      }
export function useCheckHistoriesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckHistoriesDataQuery, CheckHistoriesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckHistoriesDataQuery, CheckHistoriesDataQueryVariables>(CheckHistoriesDataDocument, options);
        }
export type CheckHistoriesDataQueryHookResult = ReturnType<typeof useCheckHistoriesDataQuery>;
export type CheckHistoriesDataLazyQueryHookResult = ReturnType<typeof useCheckHistoriesDataLazyQuery>;
export type CheckHistoriesDataQueryResult = Apollo.QueryResult<CheckHistoriesDataQuery, CheckHistoriesDataQueryVariables>;
export const GetValidationRequestCountDocument = gql`
    query GetValidationRequestCount {
  getValidationRequestCount {
    create
    edit
    total
  }
}
    `;

/**
 * __useGetValidationRequestCountQuery__
 *
 * To run a query within a React component, call `useGetValidationRequestCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidationRequestCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidationRequestCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetValidationRequestCountQuery(baseOptions?: Apollo.QueryHookOptions<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>(GetValidationRequestCountDocument, options);
      }
export function useGetValidationRequestCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>(GetValidationRequestCountDocument, options);
        }
export type GetValidationRequestCountQueryHookResult = ReturnType<typeof useGetValidationRequestCountQuery>;
export type GetValidationRequestCountLazyQueryHookResult = ReturnType<typeof useGetValidationRequestCountLazyQuery>;
export type GetValidationRequestCountQueryResult = Apollo.QueryResult<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>;
export const GetExportSitesDocument = gql`
    query GetExportSites($input: InputGetExportSites) {
  getExportSites(input: $input) {
    file
  }
}
    `;

/**
 * __useGetExportSitesQuery__
 *
 * To run a query within a React component, call `useGetExportSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportSitesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExportSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetExportSitesQuery, GetExportSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExportSitesQuery, GetExportSitesQueryVariables>(GetExportSitesDocument, options);
      }
export function useGetExportSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportSitesQuery, GetExportSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExportSitesQuery, GetExportSitesQueryVariables>(GetExportSitesDocument, options);
        }
export type GetExportSitesQueryHookResult = ReturnType<typeof useGetExportSitesQuery>;
export type GetExportSitesLazyQueryHookResult = ReturnType<typeof useGetExportSitesLazyQuery>;
export type GetExportSitesQueryResult = Apollo.QueryResult<GetExportSitesQuery, GetExportSitesQueryVariables>;
export const GetExportSiteBySiteIdDocument = gql`
    query GetExportSiteBySiteId($input: InputGetExportSiteBySiteId) {
  getExportSiteBySiteId(input: $input) {
    file
  }
}
    `;

/**
 * __useGetExportSiteBySiteIdQuery__
 *
 * To run a query within a React component, call `useGetExportSiteBySiteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportSiteBySiteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportSiteBySiteIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExportSiteBySiteIdQuery(baseOptions?: Apollo.QueryHookOptions<GetExportSiteBySiteIdQuery, GetExportSiteBySiteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExportSiteBySiteIdQuery, GetExportSiteBySiteIdQueryVariables>(GetExportSiteBySiteIdDocument, options);
      }
export function useGetExportSiteBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportSiteBySiteIdQuery, GetExportSiteBySiteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExportSiteBySiteIdQuery, GetExportSiteBySiteIdQueryVariables>(GetExportSiteBySiteIdDocument, options);
        }
export type GetExportSiteBySiteIdQueryHookResult = ReturnType<typeof useGetExportSiteBySiteIdQuery>;
export type GetExportSiteBySiteIdLazyQueryHookResult = ReturnType<typeof useGetExportSiteBySiteIdLazyQuery>;
export type GetExportSiteBySiteIdQueryResult = Apollo.QueryResult<GetExportSiteBySiteIdQuery, GetExportSiteBySiteIdQueryVariables>;
export const CreateNewSiteSubscriptionDocument = gql`
    subscription createNewSiteSubscription($input: InputCreateSiteSubscription) {
  createNewSiteSubscription(input: $input) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useCreateNewSiteSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCreateNewSiteSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSiteSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateNewSiteSubscriptionSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewSiteSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CreateNewSiteSubscriptionSubscription, CreateNewSiteSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CreateNewSiteSubscriptionSubscription, CreateNewSiteSubscriptionSubscriptionVariables>(CreateNewSiteSubscriptionDocument, options);
      }
export type CreateNewSiteSubscriptionSubscriptionHookResult = ReturnType<typeof useCreateNewSiteSubscriptionSubscription>;
export type CreateNewSiteSubscriptionSubscriptionResult = Apollo.SubscriptionResult<CreateNewSiteSubscriptionSubscription>;
export const GetValidationRequestCountSubscriptionDocument = gql`
    subscription getValidationRequestCountSubscription($input: InputGetAllValidationRequestCount) {
  getValidationRequestCount(input: $input) {
    create
    edit
    total
  }
}
    `;

/**
 * __useGetValidationRequestCountSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetValidationRequestCountSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetValidationRequestCountSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidationRequestCountSubscriptionSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetValidationRequestCountSubscriptionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetValidationRequestCountSubscriptionSubscription, GetValidationRequestCountSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetValidationRequestCountSubscriptionSubscription, GetValidationRequestCountSubscriptionSubscriptionVariables>(GetValidationRequestCountSubscriptionDocument, options);
      }
export type GetValidationRequestCountSubscriptionSubscriptionHookResult = ReturnType<typeof useGetValidationRequestCountSubscriptionSubscription>;
export type GetValidationRequestCountSubscriptionSubscriptionResult = Apollo.SubscriptionResult<GetValidationRequestCountSubscriptionSubscription>;
export const DeleteSiteTypesDocument = gql`
    mutation DeleteSiteTypes($input: InputDeleteSiteTypes) {
  deleteSiteTypes(input: $input) {
    ...SiteTypeFragment
  }
}
    ${SiteTypeFragmentFragmentDoc}`;
export type DeleteSiteTypesMutationFn = Apollo.MutationFunction<DeleteSiteTypesMutation, DeleteSiteTypesMutationVariables>;

/**
 * __useDeleteSiteTypesMutation__
 *
 * To run a mutation, you first call `useDeleteSiteTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteTypesMutation, { data, loading, error }] = useDeleteSiteTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteTypesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSiteTypesMutation, DeleteSiteTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSiteTypesMutation, DeleteSiteTypesMutationVariables>(DeleteSiteTypesDocument, options);
      }
export type DeleteSiteTypesMutationHookResult = ReturnType<typeof useDeleteSiteTypesMutation>;
export type DeleteSiteTypesMutationResult = Apollo.MutationResult<DeleteSiteTypesMutation>;
export type DeleteSiteTypesMutationOptions = Apollo.BaseMutationOptions<DeleteSiteTypesMutation, DeleteSiteTypesMutationVariables>;
export const CreateUpdateSiteTypeDocument = gql`
    mutation CreateUpdateSiteType($input: InputCreateUpdateSiteType) {
  createUpdateSiteType(input: $input) {
    ...SiteTypeFragment
  }
}
    ${SiteTypeFragmentFragmentDoc}`;
export type CreateUpdateSiteTypeMutationFn = Apollo.MutationFunction<CreateUpdateSiteTypeMutation, CreateUpdateSiteTypeMutationVariables>;

/**
 * __useCreateUpdateSiteTypeMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSiteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSiteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSiteTypeMutation, { data, loading, error }] = useCreateUpdateSiteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSiteTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateSiteTypeMutation, CreateUpdateSiteTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateSiteTypeMutation, CreateUpdateSiteTypeMutationVariables>(CreateUpdateSiteTypeDocument, options);
      }
export type CreateUpdateSiteTypeMutationHookResult = ReturnType<typeof useCreateUpdateSiteTypeMutation>;
export type CreateUpdateSiteTypeMutationResult = Apollo.MutationResult<CreateUpdateSiteTypeMutation>;
export type CreateUpdateSiteTypeMutationOptions = Apollo.BaseMutationOptions<CreateUpdateSiteTypeMutation, CreateUpdateSiteTypeMutationVariables>;
export const GetSiteTypesDocument = gql`
    query GetSiteTypes($input: InputGetSiteTypes) {
  getSiteTypes(input: $input) {
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
    data {
      ...SiteTypeFragment
    }
  }
}
    ${SiteTypeFragmentFragmentDoc}`;

/**
 * __useGetSiteTypesQuery__
 *
 * To run a query within a React component, call `useGetSiteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSiteTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteTypesQuery, GetSiteTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteTypesQuery, GetSiteTypesQueryVariables>(GetSiteTypesDocument, options);
      }
export function useGetSiteTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteTypesQuery, GetSiteTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteTypesQuery, GetSiteTypesQueryVariables>(GetSiteTypesDocument, options);
        }
export type GetSiteTypesQueryHookResult = ReturnType<typeof useGetSiteTypesQuery>;
export type GetSiteTypesLazyQueryHookResult = ReturnType<typeof useGetSiteTypesLazyQuery>;
export type GetSiteTypesQueryResult = Apollo.QueryResult<GetSiteTypesQuery, GetSiteTypesQueryVariables>;
export const GetAllSiteTypesDocument = gql`
    query GetAllSiteTypes {
  getAllSiteTypes {
    ...SiteTypeFragment
  }
}
    ${SiteTypeFragmentFragmentDoc}`;

/**
 * __useGetAllSiteTypesQuery__
 *
 * To run a query within a React component, call `useGetAllSiteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSiteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSiteTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSiteTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSiteTypesQuery, GetAllSiteTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSiteTypesQuery, GetAllSiteTypesQueryVariables>(GetAllSiteTypesDocument, options);
      }
export function useGetAllSiteTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSiteTypesQuery, GetAllSiteTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSiteTypesQuery, GetAllSiteTypesQueryVariables>(GetAllSiteTypesDocument, options);
        }
export type GetAllSiteTypesQueryHookResult = ReturnType<typeof useGetAllSiteTypesQuery>;
export type GetAllSiteTypesLazyQueryHookResult = ReturnType<typeof useGetAllSiteTypesLazyQuery>;
export type GetAllSiteTypesQueryResult = Apollo.QueryResult<GetAllSiteTypesQuery, GetAllSiteTypesQueryVariables>;
export const DeleteSpecificRiskToSiteDocument = gql`
    mutation deleteSpecificRiskToSite($input: InputDeleteSpecificRiskToSite) {
  deleteSpecificRiskToSite(input: $input) {
    id
    description
    descriptionDecrypted
    deleted
  }
}
    `;
export type DeleteSpecificRiskToSiteMutationFn = Apollo.MutationFunction<DeleteSpecificRiskToSiteMutation, DeleteSpecificRiskToSiteMutationVariables>;

/**
 * __useDeleteSpecificRiskToSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSpecificRiskToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpecificRiskToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpecificRiskToSiteMutation, { data, loading, error }] = useDeleteSpecificRiskToSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSpecificRiskToSiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpecificRiskToSiteMutation, DeleteSpecificRiskToSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpecificRiskToSiteMutation, DeleteSpecificRiskToSiteMutationVariables>(DeleteSpecificRiskToSiteDocument, options);
      }
export type DeleteSpecificRiskToSiteMutationHookResult = ReturnType<typeof useDeleteSpecificRiskToSiteMutation>;
export type DeleteSpecificRiskToSiteMutationResult = Apollo.MutationResult<DeleteSpecificRiskToSiteMutation>;
export type DeleteSpecificRiskToSiteMutationOptions = Apollo.BaseMutationOptions<DeleteSpecificRiskToSiteMutation, DeleteSpecificRiskToSiteMutationVariables>;
export const CreateUpdateSpecificRiskToSiteDocument = gql`
    mutation createUpdateSpecificRiskToSite($input: InputCreateUpdateSpecificRiskToSite) {
  createUpdateSpecificRiskToSite(input: $input) {
    id
    description
    descriptionDecrypted
    deleted
    specificRisk {
      id
      name
      logoUrl
      path
      createdAt
      updatedAt
      deleted
    }
  }
}
    `;
export type CreateUpdateSpecificRiskToSiteMutationFn = Apollo.MutationFunction<CreateUpdateSpecificRiskToSiteMutation, CreateUpdateSpecificRiskToSiteMutationVariables>;

/**
 * __useCreateUpdateSpecificRiskToSiteMutation__
 *
 * To run a mutation, you first call `useCreateUpdateSpecificRiskToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateSpecificRiskToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateSpecificRiskToSiteMutation, { data, loading, error }] = useCreateUpdateSpecificRiskToSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateSpecificRiskToSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateSpecificRiskToSiteMutation, CreateUpdateSpecificRiskToSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateSpecificRiskToSiteMutation, CreateUpdateSpecificRiskToSiteMutationVariables>(CreateUpdateSpecificRiskToSiteDocument, options);
      }
export type CreateUpdateSpecificRiskToSiteMutationHookResult = ReturnType<typeof useCreateUpdateSpecificRiskToSiteMutation>;
export type CreateUpdateSpecificRiskToSiteMutationResult = Apollo.MutationResult<CreateUpdateSpecificRiskToSiteMutation>;
export type CreateUpdateSpecificRiskToSiteMutationOptions = Apollo.BaseMutationOptions<CreateUpdateSpecificRiskToSiteMutation, CreateUpdateSpecificRiskToSiteMutationVariables>;
export const CreateSpecificRisksToSiteInStepperDocument = gql`
    mutation CreateSpecificRisksToSiteInStepper($input: InputCreateSpecificRisksToSiteInStepper) {
  createSpecificRisksToSiteInStepper(input: $input) {
    id
    description
    descriptionDecrypted
    siteId
    specificRiskId
    specificRisk {
      id
      name
      logoUrl
      path
      createdAt
      updatedAt
      deleted
    }
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type CreateSpecificRisksToSiteInStepperMutationFn = Apollo.MutationFunction<CreateSpecificRisksToSiteInStepperMutation, CreateSpecificRisksToSiteInStepperMutationVariables>;

/**
 * __useCreateSpecificRisksToSiteInStepperMutation__
 *
 * To run a mutation, you first call `useCreateSpecificRisksToSiteInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpecificRisksToSiteInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpecificRisksToSiteInStepperMutation, { data, loading, error }] = useCreateSpecificRisksToSiteInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpecificRisksToSiteInStepperMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpecificRisksToSiteInStepperMutation, CreateSpecificRisksToSiteInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpecificRisksToSiteInStepperMutation, CreateSpecificRisksToSiteInStepperMutationVariables>(CreateSpecificRisksToSiteInStepperDocument, options);
      }
export type CreateSpecificRisksToSiteInStepperMutationHookResult = ReturnType<typeof useCreateSpecificRisksToSiteInStepperMutation>;
export type CreateSpecificRisksToSiteInStepperMutationResult = Apollo.MutationResult<CreateSpecificRisksToSiteInStepperMutation>;
export type CreateSpecificRisksToSiteInStepperMutationOptions = Apollo.BaseMutationOptions<CreateSpecificRisksToSiteInStepperMutation, CreateSpecificRisksToSiteInStepperMutationVariables>;
export const UpdateSpecificRisksToSiteInStepperDocument = gql`
    mutation UpdateSpecificRisksToSiteInStepper($input: InputUpdateSpecificRisksToSiteInStepper) {
  updateSpecificRisksToSiteInStepper(input: $input) {
    id
    description
    descriptionDecrypted
    siteId
    specificRiskId
    specificRisk {
      id
      name
      logoUrl
      path
      createdAt
      updatedAt
      deleted
    }
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type UpdateSpecificRisksToSiteInStepperMutationFn = Apollo.MutationFunction<UpdateSpecificRisksToSiteInStepperMutation, UpdateSpecificRisksToSiteInStepperMutationVariables>;

/**
 * __useUpdateSpecificRisksToSiteInStepperMutation__
 *
 * To run a mutation, you first call `useUpdateSpecificRisksToSiteInStepperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecificRisksToSiteInStepperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecificRisksToSiteInStepperMutation, { data, loading, error }] = useUpdateSpecificRisksToSiteInStepperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpecificRisksToSiteInStepperMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecificRisksToSiteInStepperMutation, UpdateSpecificRisksToSiteInStepperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecificRisksToSiteInStepperMutation, UpdateSpecificRisksToSiteInStepperMutationVariables>(UpdateSpecificRisksToSiteInStepperDocument, options);
      }
export type UpdateSpecificRisksToSiteInStepperMutationHookResult = ReturnType<typeof useUpdateSpecificRisksToSiteInStepperMutation>;
export type UpdateSpecificRisksToSiteInStepperMutationResult = Apollo.MutationResult<UpdateSpecificRisksToSiteInStepperMutation>;
export type UpdateSpecificRisksToSiteInStepperMutationOptions = Apollo.BaseMutationOptions<UpdateSpecificRisksToSiteInStepperMutation, UpdateSpecificRisksToSiteInStepperMutationVariables>;
export const GetSpecificRisksToSiteDocument = gql`
    query GetSpecificRisksToSite($input: InputGetSpecificRisksToSite) {
  getSpecificRisksToSite(input: $input) {
    data {
      id
      description
      descriptionDecrypted
      deleted
      specificRisk {
        id
        name
        logoUrl
        path
        createdAt
        updatedAt
        deleted
      }
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSpecificRisksToSiteQuery__
 *
 * To run a query within a React component, call `useGetSpecificRisksToSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecificRisksToSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecificRisksToSiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpecificRisksToSiteQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecificRisksToSiteQuery, GetSpecificRisksToSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecificRisksToSiteQuery, GetSpecificRisksToSiteQueryVariables>(GetSpecificRisksToSiteDocument, options);
      }
export function useGetSpecificRisksToSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecificRisksToSiteQuery, GetSpecificRisksToSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecificRisksToSiteQuery, GetSpecificRisksToSiteQueryVariables>(GetSpecificRisksToSiteDocument, options);
        }
export type GetSpecificRisksToSiteQueryHookResult = ReturnType<typeof useGetSpecificRisksToSiteQuery>;
export type GetSpecificRisksToSiteLazyQueryHookResult = ReturnType<typeof useGetSpecificRisksToSiteLazyQuery>;
export type GetSpecificRisksToSiteQueryResult = Apollo.QueryResult<GetSpecificRisksToSiteQuery, GetSpecificRisksToSiteQueryVariables>;
export const GetNotAddedSpecificRisksToSiteDocument = gql`
    query GetNotAddedSpecificRisksToSite($input: InputGetNotAddedSpecificRisksToSite) {
  getNotAddedSpecificRisksToSite(input: $input) {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;

/**
 * __useGetNotAddedSpecificRisksToSiteQuery__
 *
 * To run a query within a React component, call `useGetNotAddedSpecificRisksToSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotAddedSpecificRisksToSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotAddedSpecificRisksToSiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotAddedSpecificRisksToSiteQuery(baseOptions?: Apollo.QueryHookOptions<GetNotAddedSpecificRisksToSiteQuery, GetNotAddedSpecificRisksToSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotAddedSpecificRisksToSiteQuery, GetNotAddedSpecificRisksToSiteQueryVariables>(GetNotAddedSpecificRisksToSiteDocument, options);
      }
export function useGetNotAddedSpecificRisksToSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotAddedSpecificRisksToSiteQuery, GetNotAddedSpecificRisksToSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotAddedSpecificRisksToSiteQuery, GetNotAddedSpecificRisksToSiteQueryVariables>(GetNotAddedSpecificRisksToSiteDocument, options);
        }
export type GetNotAddedSpecificRisksToSiteQueryHookResult = ReturnType<typeof useGetNotAddedSpecificRisksToSiteQuery>;
export type GetNotAddedSpecificRisksToSiteLazyQueryHookResult = ReturnType<typeof useGetNotAddedSpecificRisksToSiteLazyQuery>;
export type GetNotAddedSpecificRisksToSiteQueryResult = Apollo.QueryResult<GetNotAddedSpecificRisksToSiteQuery, GetNotAddedSpecificRisksToSiteQueryVariables>;
export const GetSpecificRiskHistoriesDocument = gql`
    query getSpecificRiskHistories($input: InputSpecificRiskHistories) {
  getSpecificRiskHistories(input: $input) {
    id
    description
    descriptionDecrypted
    deleted
    specificRisk {
      id
      name
      logoUrl
      path
      createdAt
      updatedAt
      deleted
    }
  }
}
    `;

/**
 * __useGetSpecificRiskHistoriesQuery__
 *
 * To run a query within a React component, call `useGetSpecificRiskHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecificRiskHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecificRiskHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpecificRiskHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecificRiskHistoriesQuery, GetSpecificRiskHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecificRiskHistoriesQuery, GetSpecificRiskHistoriesQueryVariables>(GetSpecificRiskHistoriesDocument, options);
      }
export function useGetSpecificRiskHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecificRiskHistoriesQuery, GetSpecificRiskHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecificRiskHistoriesQuery, GetSpecificRiskHistoriesQueryVariables>(GetSpecificRiskHistoriesDocument, options);
        }
export type GetSpecificRiskHistoriesQueryHookResult = ReturnType<typeof useGetSpecificRiskHistoriesQuery>;
export type GetSpecificRiskHistoriesLazyQueryHookResult = ReturnType<typeof useGetSpecificRiskHistoriesLazyQuery>;
export type GetSpecificRiskHistoriesQueryResult = Apollo.QueryResult<GetSpecificRiskHistoriesQuery, GetSpecificRiskHistoriesQueryVariables>;
export const GetAllSpecificRiskBySiteDocument = gql`
    query getAllSpecificRiskBySite($input: InputGetAllSpecificRiskBySite) {
  getAllSpecificRiskBySite(input: $input) {
    id
    description
    descriptionDecrypted
    siteId
    specificRiskId
    specificRisk {
      id
      name
      path
      logoUrl
      createdAt
      updatedAt
      deleted
    }
    createdAt
    updatedAt
    deleted
  }
}
    `;

/**
 * __useGetAllSpecificRiskBySiteQuery__
 *
 * To run a query within a React component, call `useGetAllSpecificRiskBySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpecificRiskBySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpecificRiskBySiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllSpecificRiskBySiteQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSpecificRiskBySiteQuery, GetAllSpecificRiskBySiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpecificRiskBySiteQuery, GetAllSpecificRiskBySiteQueryVariables>(GetAllSpecificRiskBySiteDocument, options);
      }
export function useGetAllSpecificRiskBySiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpecificRiskBySiteQuery, GetAllSpecificRiskBySiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpecificRiskBySiteQuery, GetAllSpecificRiskBySiteQueryVariables>(GetAllSpecificRiskBySiteDocument, options);
        }
export type GetAllSpecificRiskBySiteQueryHookResult = ReturnType<typeof useGetAllSpecificRiskBySiteQuery>;
export type GetAllSpecificRiskBySiteLazyQueryHookResult = ReturnType<typeof useGetAllSpecificRiskBySiteLazyQuery>;
export type GetAllSpecificRiskBySiteQueryResult = Apollo.QueryResult<GetAllSpecificRiskBySiteQuery, GetAllSpecificRiskBySiteQueryVariables>;
export const DeleteTagsDocument = gql`
    mutation DeleteTags($input: InputDeleteTags) {
  deleteTags(input: $input) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type DeleteTagsMutationFn = Apollo.MutationFunction<DeleteTagsMutation, DeleteTagsMutationVariables>;

/**
 * __useDeleteTagsMutation__
 *
 * To run a mutation, you first call `useDeleteTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagsMutation, { data, loading, error }] = useDeleteTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagsMutation, DeleteTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagsMutation, DeleteTagsMutationVariables>(DeleteTagsDocument, options);
      }
export type DeleteTagsMutationHookResult = ReturnType<typeof useDeleteTagsMutation>;
export type DeleteTagsMutationResult = Apollo.MutationResult<DeleteTagsMutation>;
export type DeleteTagsMutationOptions = Apollo.BaseMutationOptions<DeleteTagsMutation, DeleteTagsMutationVariables>;
export const CreateUpdateTagDocument = gql`
    mutation CreateUpdateTag($input: InputCreateUpdateTag) {
  createUpdateTag(input: $input) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;
export type CreateUpdateTagMutationFn = Apollo.MutationFunction<CreateUpdateTagMutation, CreateUpdateTagMutationVariables>;

/**
 * __useCreateUpdateTagMutation__
 *
 * To run a mutation, you first call `useCreateUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateTagMutation, { data, loading, error }] = useCreateUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateTagMutation, CreateUpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateTagMutation, CreateUpdateTagMutationVariables>(CreateUpdateTagDocument, options);
      }
export type CreateUpdateTagMutationHookResult = ReturnType<typeof useCreateUpdateTagMutation>;
export type CreateUpdateTagMutationResult = Apollo.MutationResult<CreateUpdateTagMutation>;
export type CreateUpdateTagMutationOptions = Apollo.BaseMutationOptions<CreateUpdateTagMutation, CreateUpdateTagMutationVariables>;
export const GetTagsDocument = gql`
    query GetTags($input: InputGetTags) {
  getTags(input: $input) {
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
    data {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const RecoveryUserDepartmentFromUserToDepartmentRelationDocument = gql`
    mutation RecoveryUserDepartmentFromUserToDepartmentRelation {
  recoveryUserDepartmentFromUserToDepartmentRelation
}
    `;
export type RecoveryUserDepartmentFromUserToDepartmentRelationMutationFn = Apollo.MutationFunction<RecoveryUserDepartmentFromUserToDepartmentRelationMutation, RecoveryUserDepartmentFromUserToDepartmentRelationMutationVariables>;

/**
 * __useRecoveryUserDepartmentFromUserToDepartmentRelationMutation__
 *
 * To run a mutation, you first call `useRecoveryUserDepartmentFromUserToDepartmentRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoveryUserDepartmentFromUserToDepartmentRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoveryUserDepartmentFromUserToDepartmentRelationMutation, { data, loading, error }] = useRecoveryUserDepartmentFromUserToDepartmentRelationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecoveryUserDepartmentFromUserToDepartmentRelationMutation(baseOptions?: Apollo.MutationHookOptions<RecoveryUserDepartmentFromUserToDepartmentRelationMutation, RecoveryUserDepartmentFromUserToDepartmentRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoveryUserDepartmentFromUserToDepartmentRelationMutation, RecoveryUserDepartmentFromUserToDepartmentRelationMutationVariables>(RecoveryUserDepartmentFromUserToDepartmentRelationDocument, options);
      }
export type RecoveryUserDepartmentFromUserToDepartmentRelationMutationHookResult = ReturnType<typeof useRecoveryUserDepartmentFromUserToDepartmentRelationMutation>;
export type RecoveryUserDepartmentFromUserToDepartmentRelationMutationResult = Apollo.MutationResult<RecoveryUserDepartmentFromUserToDepartmentRelationMutation>;
export type RecoveryUserDepartmentFromUserToDepartmentRelationMutationOptions = Apollo.BaseMutationOptions<RecoveryUserDepartmentFromUserToDepartmentRelationMutation, RecoveryUserDepartmentFromUserToDepartmentRelationMutationVariables>;
export const CreateUpdateUserGroupDocument = gql`
    mutation CreateUpdateUserGroup($input: InputCreateUserGroup) {
  createUpdateUserGroup(input: $input) {
    id
    name
  }
}
    `;
export type CreateUpdateUserGroupMutationFn = Apollo.MutationFunction<CreateUpdateUserGroupMutation, CreateUpdateUserGroupMutationVariables>;

/**
 * __useCreateUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateUserGroupMutation, { data, loading, error }] = useCreateUpdateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateUserGroupMutation, CreateUpdateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateUserGroupMutation, CreateUpdateUserGroupMutationVariables>(CreateUpdateUserGroupDocument, options);
      }
export type CreateUpdateUserGroupMutationHookResult = ReturnType<typeof useCreateUpdateUserGroupMutation>;
export type CreateUpdateUserGroupMutationResult = Apollo.MutationResult<CreateUpdateUserGroupMutation>;
export type CreateUpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUpdateUserGroupMutation, CreateUpdateUserGroupMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation AddUserToGroup($input: InputAddUserToGroup) {
  addUserToGroup(input: $input) {
    id
    name
  }
}
    `;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, options);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation RemoveUserFromGroup($input: InputRemoveUserFromGroup) {
  removeUserFromGroup(input: $input) {
    id
    name
  }
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, options);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const DeleteUserGroupDocument = gql`
    mutation deleteUserGroup($input: InputDeleteGroup) {
  deleteUserGroup(input: $input) {
    success
  }
}
    `;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DeleteUserGroupDocument, options);
      }
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;
export const GetAllGroupsDocument = gql`
    query getAllGroups($input: InputGetGroups) {
  getAllGroups(input: $input) {
    total
    edges {
      node {
        id
        name
        permissions {
          id
          name
        }
        otherUserGroupIdList
      }
      cursor
    }
    pageCursorInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAllGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
      }
export function useGetAllGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export type GetAllGroupsQueryHookResult = ReturnType<typeof useGetAllGroupsQuery>;
export type GetAllGroupsLazyQueryHookResult = ReturnType<typeof useGetAllGroupsLazyQuery>;
export type GetAllGroupsQueryResult = Apollo.QueryResult<GetAllGroupsQuery, GetAllGroupsQueryVariables>;
export const UserGroupListByConnectedUserGroupDocument = gql`
    query userGroupListByConnectedUserGroup {
  userGroupListByConnectedUserGroup {
    id
    name
    permissions {
      id
      name
    }
  }
}
    `;

/**
 * __useUserGroupListByConnectedUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupListByConnectedUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupListByConnectedUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupListByConnectedUserGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGroupListByConnectedUserGroupQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupListByConnectedUserGroupQuery, UserGroupListByConnectedUserGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupListByConnectedUserGroupQuery, UserGroupListByConnectedUserGroupQueryVariables>(UserGroupListByConnectedUserGroupDocument, options);
      }
export function useUserGroupListByConnectedUserGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupListByConnectedUserGroupQuery, UserGroupListByConnectedUserGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupListByConnectedUserGroupQuery, UserGroupListByConnectedUserGroupQueryVariables>(UserGroupListByConnectedUserGroupDocument, options);
        }
export type UserGroupListByConnectedUserGroupQueryHookResult = ReturnType<typeof useUserGroupListByConnectedUserGroupQuery>;
export type UserGroupListByConnectedUserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupListByConnectedUserGroupLazyQuery>;
export type UserGroupListByConnectedUserGroupQueryResult = Apollo.QueryResult<UserGroupListByConnectedUserGroupQuery, UserGroupListByConnectedUserGroupQueryVariables>;
export const GetGroupDocument = gql`
    query GetGroup($input: InputGetGroup) {
  getGroup(input: $input) {
    id
    name
    users {
      id
      lastName
      firstName
      accountName
      email
      accountStatus
      deleted
      isAdmin
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGroupQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
      }
export function useGetGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
        }
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<typeof useGetGroupLazyQuery>;
export type GetGroupQueryResult = Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>;
export const GroupToAuthorizedDocument = gql`
    query groupToAuthorized {
  groupToAuthorized {
    id
    name
  }
}
    `;

/**
 * __useGroupToAuthorizedQuery__
 *
 * To run a query within a React component, call `useGroupToAuthorizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupToAuthorizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupToAuthorizedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupToAuthorizedQuery(baseOptions?: Apollo.QueryHookOptions<GroupToAuthorizedQuery, GroupToAuthorizedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupToAuthorizedQuery, GroupToAuthorizedQueryVariables>(GroupToAuthorizedDocument, options);
      }
export function useGroupToAuthorizedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupToAuthorizedQuery, GroupToAuthorizedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupToAuthorizedQuery, GroupToAuthorizedQueryVariables>(GroupToAuthorizedDocument, options);
        }
export type GroupToAuthorizedQueryHookResult = ReturnType<typeof useGroupToAuthorizedQuery>;
export type GroupToAuthorizedLazyQueryHookResult = ReturnType<typeof useGroupToAuthorizedLazyQuery>;
export type GroupToAuthorizedQueryResult = Apollo.QueryResult<GroupToAuthorizedQuery, GroupToAuthorizedQueryVariables>;
export const CreatePresignedUrlDocument = gql`
    mutation CreatePresignedUrl($input: InputPresigneUrl) {
  createPresignedUrlPut(input: $input) {
    filePath
  }
}
    `;
export type CreatePresignedUrlMutationFn = Apollo.MutationFunction<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>;

/**
 * __useCreatePresignedUrlMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUrlMutation, { data, loading, error }] = useCreatePresignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>(CreatePresignedUrlDocument, options);
      }
export type CreatePresignedUrlMutationHookResult = ReturnType<typeof useCreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationResult = Apollo.MutationResult<CreatePresignedUrlMutation>;
export type CreatePresignedUrlMutationOptions = Apollo.BaseMutationOptions<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>;
export const CreatePresignedUrlGetDocument = gql`
    mutation CreatePresignedUrlGet($input: InputPresigneUrl) {
  createPresignedUrlGet(input: $input) {
    filePath
  }
}
    `;
export type CreatePresignedUrlGetMutationFn = Apollo.MutationFunction<CreatePresignedUrlGetMutation, CreatePresignedUrlGetMutationVariables>;

/**
 * __useCreatePresignedUrlGetMutation__
 *
 * To run a mutation, you first call `useCreatePresignedUrlGetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedUrlGetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedUrlGetMutation, { data, loading, error }] = useCreatePresignedUrlGetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedUrlGetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignedUrlGetMutation, CreatePresignedUrlGetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignedUrlGetMutation, CreatePresignedUrlGetMutationVariables>(CreatePresignedUrlGetDocument, options);
      }
export type CreatePresignedUrlGetMutationHookResult = ReturnType<typeof useCreatePresignedUrlGetMutation>;
export type CreatePresignedUrlGetMutationResult = Apollo.MutationResult<CreatePresignedUrlGetMutation>;
export type CreatePresignedUrlGetMutationOptions = Apollo.BaseMutationOptions<CreatePresignedUrlGetMutation, CreatePresignedUrlGetMutationVariables>;
export const DeleteCurrentRiskDocument = gql`
    mutation deleteCurrentRisk($input: InputDeleteCurrentRisk) {
  deleteCurrentRisk(input: $input) {
    id
    name
    logoUrl
  }
}
    `;
export type DeleteCurrentRiskMutationFn = Apollo.MutationFunction<DeleteCurrentRiskMutation, DeleteCurrentRiskMutationVariables>;

/**
 * __useDeleteCurrentRiskMutation__
 *
 * To run a mutation, you first call `useDeleteCurrentRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrentRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrentRiskMutation, { data, loading, error }] = useDeleteCurrentRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCurrentRiskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCurrentRiskMutation, DeleteCurrentRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCurrentRiskMutation, DeleteCurrentRiskMutationVariables>(DeleteCurrentRiskDocument, options);
      }
export type DeleteCurrentRiskMutationHookResult = ReturnType<typeof useDeleteCurrentRiskMutation>;
export type DeleteCurrentRiskMutationResult = Apollo.MutationResult<DeleteCurrentRiskMutation>;
export type DeleteCurrentRiskMutationOptions = Apollo.BaseMutationOptions<DeleteCurrentRiskMutation, DeleteCurrentRiskMutationVariables>;
export const CreateCurrentRiskDocument = gql`
    mutation createCurrentRisk($input: InputCreateCurrentRisk) {
  createCurrentRisk(input: $input) {
    id
    name
    logoUrl
    updatedAt
    createdAt
    deleted
  }
}
    `;
export type CreateCurrentRiskMutationFn = Apollo.MutationFunction<CreateCurrentRiskMutation, CreateCurrentRiskMutationVariables>;

/**
 * __useCreateCurrentRiskMutation__
 *
 * To run a mutation, you first call `useCreateCurrentRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentRiskMutation, { data, loading, error }] = useCreateCurrentRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrentRiskMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentRiskMutation, CreateCurrentRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentRiskMutation, CreateCurrentRiskMutationVariables>(CreateCurrentRiskDocument, options);
      }
export type CreateCurrentRiskMutationHookResult = ReturnType<typeof useCreateCurrentRiskMutation>;
export type CreateCurrentRiskMutationResult = Apollo.MutationResult<CreateCurrentRiskMutation>;
export type CreateCurrentRiskMutationOptions = Apollo.BaseMutationOptions<CreateCurrentRiskMutation, CreateCurrentRiskMutationVariables>;
export const UpdateCurrentRiskDocument = gql`
    mutation updateCurrentRisk($input: InputUpdateCurrentRisk) {
  updateCurrentRisk(input: $input) {
    id
    name
    logoUrl
    updatedAt
    createdAt
    deleted
  }
}
    `;
export type UpdateCurrentRiskMutationFn = Apollo.MutationFunction<UpdateCurrentRiskMutation, UpdateCurrentRiskMutationVariables>;

/**
 * __useUpdateCurrentRiskMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentRiskMutation, { data, loading, error }] = useUpdateCurrentRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentRiskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentRiskMutation, UpdateCurrentRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentRiskMutation, UpdateCurrentRiskMutationVariables>(UpdateCurrentRiskDocument, options);
      }
export type UpdateCurrentRiskMutationHookResult = ReturnType<typeof useUpdateCurrentRiskMutation>;
export type UpdateCurrentRiskMutationResult = Apollo.MutationResult<UpdateCurrentRiskMutation>;
export type UpdateCurrentRiskMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentRiskMutation, UpdateCurrentRiskMutationVariables>;
export const GetAllCurrentRisksDocument = gql`
    query GetAllCurrentRisks {
  getAllCurrentRisks {
    id
    name
    logoUrl
    path
    createdAt
  }
}
    `;

/**
 * __useGetAllCurrentRisksQuery__
 *
 * To run a query within a React component, call `useGetAllCurrentRisksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCurrentRisksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCurrentRisksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCurrentRisksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCurrentRisksQuery, GetAllCurrentRisksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCurrentRisksQuery, GetAllCurrentRisksQueryVariables>(GetAllCurrentRisksDocument, options);
      }
export function useGetAllCurrentRisksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCurrentRisksQuery, GetAllCurrentRisksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCurrentRisksQuery, GetAllCurrentRisksQueryVariables>(GetAllCurrentRisksDocument, options);
        }
export type GetAllCurrentRisksQueryHookResult = ReturnType<typeof useGetAllCurrentRisksQuery>;
export type GetAllCurrentRisksLazyQueryHookResult = ReturnType<typeof useGetAllCurrentRisksLazyQuery>;
export type GetAllCurrentRisksQueryResult = Apollo.QueryResult<GetAllCurrentRisksQuery, GetAllCurrentRisksQueryVariables>;
export const GetCurrentRisksDocument = gql`
    query GetCurrentRisks($input: InputGetCurrentRisks) {
  getCurrentRisks(input: $input) {
    data {
      id
      name
      logoUrl
      path
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCurrentRisksQuery__
 *
 * To run a query within a React component, call `useGetCurrentRisksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRisksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRisksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentRisksQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRisksQuery, GetCurrentRisksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRisksQuery, GetCurrentRisksQueryVariables>(GetCurrentRisksDocument, options);
      }
export function useGetCurrentRisksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRisksQuery, GetCurrentRisksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRisksQuery, GetCurrentRisksQueryVariables>(GetCurrentRisksDocument, options);
        }
export type GetCurrentRisksQueryHookResult = ReturnType<typeof useGetCurrentRisksQuery>;
export type GetCurrentRisksLazyQueryHookResult = ReturnType<typeof useGetCurrentRisksLazyQuery>;
export type GetCurrentRisksQueryResult = Apollo.QueryResult<GetCurrentRisksQuery, GetCurrentRisksQueryVariables>;
export const GetCurrentRiskDocument = gql`
    query GetCurrentRisk($input: InputGetCurrentRisk) {
  getCurrentRisk(input: $input) {
    id
    name
    logoUrl
    path
  }
}
    `;

/**
 * __useGetCurrentRiskQuery__
 *
 * To run a query within a React component, call `useGetCurrentRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRiskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentRiskQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRiskQuery, GetCurrentRiskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRiskQuery, GetCurrentRiskQueryVariables>(GetCurrentRiskDocument, options);
      }
export function useGetCurrentRiskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRiskQuery, GetCurrentRiskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRiskQuery, GetCurrentRiskQueryVariables>(GetCurrentRiskDocument, options);
        }
export type GetCurrentRiskQueryHookResult = ReturnType<typeof useGetCurrentRiskQuery>;
export type GetCurrentRiskLazyQueryHookResult = ReturnType<typeof useGetCurrentRiskLazyQuery>;
export type GetCurrentRiskQueryResult = Apollo.QueryResult<GetCurrentRiskQuery, GetCurrentRiskQueryVariables>;
export const DeleteSpecificRiskDocument = gql`
    mutation deleteSpecificRisk($input: InputDeleteSpecificRisk) {
  deleteSpecificRisk(input: $input) {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type DeleteSpecificRiskMutationFn = Apollo.MutationFunction<DeleteSpecificRiskMutation, DeleteSpecificRiskMutationVariables>;

/**
 * __useDeleteSpecificRiskMutation__
 *
 * To run a mutation, you first call `useDeleteSpecificRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpecificRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpecificRiskMutation, { data, loading, error }] = useDeleteSpecificRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSpecificRiskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpecificRiskMutation, DeleteSpecificRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpecificRiskMutation, DeleteSpecificRiskMutationVariables>(DeleteSpecificRiskDocument, options);
      }
export type DeleteSpecificRiskMutationHookResult = ReturnType<typeof useDeleteSpecificRiskMutation>;
export type DeleteSpecificRiskMutationResult = Apollo.MutationResult<DeleteSpecificRiskMutation>;
export type DeleteSpecificRiskMutationOptions = Apollo.BaseMutationOptions<DeleteSpecificRiskMutation, DeleteSpecificRiskMutationVariables>;
export const CreateSpecificRiskDocument = gql`
    mutation createSpecificRisk($input: InputCreateSpecificRisk) {
  createSpecificRisk(input: $input) {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type CreateSpecificRiskMutationFn = Apollo.MutationFunction<CreateSpecificRiskMutation, CreateSpecificRiskMutationVariables>;

/**
 * __useCreateSpecificRiskMutation__
 *
 * To run a mutation, you first call `useCreateSpecificRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpecificRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpecificRiskMutation, { data, loading, error }] = useCreateSpecificRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpecificRiskMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpecificRiskMutation, CreateSpecificRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpecificRiskMutation, CreateSpecificRiskMutationVariables>(CreateSpecificRiskDocument, options);
      }
export type CreateSpecificRiskMutationHookResult = ReturnType<typeof useCreateSpecificRiskMutation>;
export type CreateSpecificRiskMutationResult = Apollo.MutationResult<CreateSpecificRiskMutation>;
export type CreateSpecificRiskMutationOptions = Apollo.BaseMutationOptions<CreateSpecificRiskMutation, CreateSpecificRiskMutationVariables>;
export const UpdateSpecificRiskDocument = gql`
    mutation updateSpecificRisk($input: InputUpdateSpecificRisk) {
  updateSpecificRisk(input: $input) {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;
export type UpdateSpecificRiskMutationFn = Apollo.MutationFunction<UpdateSpecificRiskMutation, UpdateSpecificRiskMutationVariables>;

/**
 * __useUpdateSpecificRiskMutation__
 *
 * To run a mutation, you first call `useUpdateSpecificRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecificRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecificRiskMutation, { data, loading, error }] = useUpdateSpecificRiskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpecificRiskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecificRiskMutation, UpdateSpecificRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecificRiskMutation, UpdateSpecificRiskMutationVariables>(UpdateSpecificRiskDocument, options);
      }
export type UpdateSpecificRiskMutationHookResult = ReturnType<typeof useUpdateSpecificRiskMutation>;
export type UpdateSpecificRiskMutationResult = Apollo.MutationResult<UpdateSpecificRiskMutation>;
export type UpdateSpecificRiskMutationOptions = Apollo.BaseMutationOptions<UpdateSpecificRiskMutation, UpdateSpecificRiskMutationVariables>;
export const GetSpecificRisksDocument = gql`
    query GetSpecificRisks($input: InputGetSpecificRisks) {
  getSpecificRisks(input: $input) {
    data {
      id
      name
      logoUrl
      path
      createdAt
      updatedAt
      deleted
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSpecificRisksQuery__
 *
 * To run a query within a React component, call `useGetSpecificRisksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecificRisksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecificRisksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpecificRisksQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecificRisksQuery, GetSpecificRisksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecificRisksQuery, GetSpecificRisksQueryVariables>(GetSpecificRisksDocument, options);
      }
export function useGetSpecificRisksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecificRisksQuery, GetSpecificRisksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecificRisksQuery, GetSpecificRisksQueryVariables>(GetSpecificRisksDocument, options);
        }
export type GetSpecificRisksQueryHookResult = ReturnType<typeof useGetSpecificRisksQuery>;
export type GetSpecificRisksLazyQueryHookResult = ReturnType<typeof useGetSpecificRisksLazyQuery>;
export type GetSpecificRisksQueryResult = Apollo.QueryResult<GetSpecificRisksQuery, GetSpecificRisksQueryVariables>;
export const GetSpecificRiskDocument = gql`
    query GetSpecificRisk($input: InputGetSpecificRisk) {
  getSpecificRisk(input: $input) {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;

/**
 * __useGetSpecificRiskQuery__
 *
 * To run a query within a React component, call `useGetSpecificRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecificRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecificRiskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpecificRiskQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecificRiskQuery, GetSpecificRiskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecificRiskQuery, GetSpecificRiskQueryVariables>(GetSpecificRiskDocument, options);
      }
export function useGetSpecificRiskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecificRiskQuery, GetSpecificRiskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecificRiskQuery, GetSpecificRiskQueryVariables>(GetSpecificRiskDocument, options);
        }
export type GetSpecificRiskQueryHookResult = ReturnType<typeof useGetSpecificRiskQuery>;
export type GetSpecificRiskLazyQueryHookResult = ReturnType<typeof useGetSpecificRiskLazyQuery>;
export type GetSpecificRiskQueryResult = Apollo.QueryResult<GetSpecificRiskQuery, GetSpecificRiskQueryVariables>;
export const GetAllSpecificRisksDocument = gql`
    query GetAllSpecificRisks {
  getAllSpecificRisks {
    id
    name
    logoUrl
    path
    createdAt
    updatedAt
    deleted
  }
}
    `;

/**
 * __useGetAllSpecificRisksQuery__
 *
 * To run a query within a React component, call `useGetAllSpecificRisksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSpecificRisksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSpecificRisksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSpecificRisksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSpecificRisksQuery, GetAllSpecificRisksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSpecificRisksQuery, GetAllSpecificRisksQueryVariables>(GetAllSpecificRisksDocument, options);
      }
export function useGetAllSpecificRisksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSpecificRisksQuery, GetAllSpecificRisksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSpecificRisksQuery, GetAllSpecificRisksQueryVariables>(GetAllSpecificRisksDocument, options);
        }
export type GetAllSpecificRisksQueryHookResult = ReturnType<typeof useGetAllSpecificRisksQuery>;
export type GetAllSpecificRisksLazyQueryHookResult = ReturnType<typeof useGetAllSpecificRisksLazyQuery>;
export type GetAllSpecificRisksQueryResult = Apollo.QueryResult<GetAllSpecificRisksQuery, GetAllSpecificRisksQueryVariables>;
export const LoginDocument = gql`
    mutation login($input: InputLogin) {
  login(input: $input) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: InputForgotPassword) {
  forgotPassword(input: $input) {
    resetEmailSent
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: InputResetPassword) {
  resetPassword(input: $input) {
    passwordReseted
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateUserProfilDocument = gql`
    mutation updateUserProfil($input: InputUpdateUserProfil) {
  updateUserProfil(input: $input) {
    firstName
    lastName
    email
    accountName
    password
    getCreateNotification
    getUpdateNotification
  }
}
    `;
export type UpdateUserProfilMutationFn = Apollo.MutationFunction<UpdateUserProfilMutation, UpdateUserProfilMutationVariables>;

/**
 * __useUpdateUserProfilMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfilMutation, { data, loading, error }] = useUpdateUserProfilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfilMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfilMutation, UpdateUserProfilMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfilMutation, UpdateUserProfilMutationVariables>(UpdateUserProfilDocument, options);
      }
export type UpdateUserProfilMutationHookResult = ReturnType<typeof useUpdateUserProfilMutation>;
export type UpdateUserProfilMutationResult = Apollo.MutationResult<UpdateUserProfilMutation>;
export type UpdateUserProfilMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfilMutation, UpdateUserProfilMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: IInputCreateUser) {
  createUser(input: $input) {
    id
    lastName
    firstName
    accountName
    email
    accountStatus
    deleted
    isAdmin
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: IInputUpdateUser) {
  updateUser(input: $input) {
    id
    lastName
    firstName
    accountName
    email
    accountStatus
    deleted
    isAdmin
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: InputDeleteUser) {
  deleteUser(input: $input) {
    id
    lastName
    firstName
    accountName
    email
    accountStatus
    deleted
    isAdmin
    createdAt
    updatedAt
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    firstName
    lastName
    accountName
    email
    password
    getCreateNotification
    getUpdateNotification
    userGroup {
      id
      name
      deleted
      permissions {
        id
        name
      }
    }
    departments {
      id
      name
    }
    defaultDepartments {
      id
      name
    }
    departmentUser {
      id
      readonly
      department {
        id
        name
      }
    }
    users {
      id
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($input: InputFilterUsers) {
  users(input: $input) {
    data {
      id
      lastName
      firstName
      accountName
      email
      accountStatus
      deleted
      isAdmin
      createdAt
      updatedAt
      userGroup {
        id
        name
        deleted
        permissions {
          id
          name
        }
      }
      departments {
        id
        name
        departmentCode
      }
      departmentUser {
        id
        readonly
        department {
          id
          name
        }
      }
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($input: InputUser) {
  getUserById(input: $input) {
    id
    lastName
    firstName
    accountName
    email
    accountStatus
    deleted
    isAdmin
    createdAt
    updatedAt
    userGroup {
      id
      name
      deleted
      permissions {
        id
        name
      }
    }
    departments {
      id
      name
      departmentCode
    }
    defaultDepartments {
      id
      name
    }
    departmentUser {
      id
      readonly
      department {
        id
        name
      }
    }
    users {
      id
    }
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUsersByCurrentUserDocument = gql`
    query getUsersByCurrentUser($input: InputGetUsersByCurrentUser) {
  getUsersByCurrentUser(input: $input) {
    data {
      id
      lastName
      firstName
      accountName
      email
      accountStatus
      isAdmin
      userGroup {
        id
        name
        deleted
      }
    }
    pageInfo {
      currentPage
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUsersByCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetUsersByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByCurrentUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersByCurrentUserQuery, GetUsersByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByCurrentUserQuery, GetUsersByCurrentUserQueryVariables>(GetUsersByCurrentUserDocument, options);
      }
export function useGetUsersByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByCurrentUserQuery, GetUsersByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByCurrentUserQuery, GetUsersByCurrentUserQueryVariables>(GetUsersByCurrentUserDocument, options);
        }
export type GetUsersByCurrentUserQueryHookResult = ReturnType<typeof useGetUsersByCurrentUserQuery>;
export type GetUsersByCurrentUserLazyQueryHookResult = ReturnType<typeof useGetUsersByCurrentUserLazyQuery>;
export type GetUsersByCurrentUserQueryResult = Apollo.QueryResult<GetUsersByCurrentUserQuery, GetUsersByCurrentUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    