import React, { useContext, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import { Badge, Box, Button, Divider, List, ListSubheader, Typography } from '@material-ui/core';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import {
  GetCurrentUserNotificationsQuery,
  GetCurrentUserNotificationsQueryVariables,
  Maybe,
  NotificationFragmentFragment,
  useCreateNewSiteSubscriptionSubscription,
  useGetAllDepartmentQuery
} from 'generated/graphql';
import { UserContext } from 'contexts/UserContext';
import NotificationItem from './NotificationItem';
import { GET_CURRENT_USER_NOTIFICATION } from 'gql/Notification/query';

type NotificationTypeFromGql = Maybe<{ __typename?: 'Notification' } & NotificationFragmentFragment>;

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const {
    me,
    notificationsData,
    loadMoreNotifications,
    reFetchNotification,
    currentPage
  } = useContext(UserContext);
  const { data: departments } = useGetAllDepartmentQuery({
    variables: {
      input: {
        searchParams: '',
        maxPage: 101,
        currentPage: 0
      }
    },
    fetchPolicy: 'cache-and-network'
  });
  const userDepartementIds =
    departments && departments?.getAllDepartment?.data.map((depart) => depart && depart.id);
  const userGroupName = me && me.userGroup && me.userGroup.name;
  useCreateNewSiteSubscriptionSubscription({
    variables: {
      input: { departementIds: userDepartementIds, userGroupName }
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const cachedData = client.readQuery<GetCurrentUserNotificationsQuery,
        GetCurrentUserNotificationsQueryVariables>({
        query: GET_CURRENT_USER_NOTIFICATION,
        variables: {
          input: { maxPage: 3, currentPage, searchParams: '' }
        }
      });

      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.createNewSiteSubscription
      ) {
        if (
          cachedData &&
          cachedData.getCurrentUserNotifications &&
          cachedData.getCurrentUserNotifications.data
        ) {
          const newCachedData = [
            ...cachedData.getCurrentUserNotifications?.data,
            subscriptionData.data.createNewSiteSubscription
          ];

          client.writeQuery<GetCurrentUserNotificationsQuery,
            GetCurrentUserNotificationsQueryVariables>({
            query: GET_CURRENT_USER_NOTIFICATION,
            data: {
              ...cachedData,
              getCurrentUserNotifications: {
                data: newCachedData,
                pageInfo: {
                  ...cachedData.getCurrentUserNotifications.pageInfo,
                  totalCount: cachedData.getCurrentUserNotifications?.pageInfo?.totalCount + 1
                }
              }
            },
            variables: {
              input: { maxPage: 3, currentPage, searchParams: '' }
            }
          });
        }
      }
    }
  });

  const totalUnRead = notificationsData?.getCurrentUserNotifications?.pageInfo?.totalCount;
  const notifications = notificationsData?.getCurrentUserNotifications?.data;

  const displayUserNotifications = (notification: NotificationTypeFromGql) => {
    let senderName = null
    if (notification?.sender) {
      senderName = notification?.sender?.firstName + ' ' + notification?.sender?.lastName;
    }
    const siteName = notification && notification.site?.name;
    let message = '';
    switch (notification?.notificationType) {
      case 'CREATION_REQUEST':
        message = senderName + ' a fait une demande de création du site ' + siteName;
        break;
      case 'MODIFICATION_REQUEST':
        message = senderName + ' a fait une demande de modification du site ' + siteName;
        break;
      case 'CREATION_REQUEST_ACCEPTED':
        message =
          'Votre demande de création du site ' + siteName + ' a été accepté par ' + senderName;
        break;
      case 'CREATION_REQUEST_CANCELED':
        message =
          'Votre demande de création du site ' + siteName + ' a été annulé par ' + senderName;
        break;
      case 'EDIT_REQUEST_ACCEPTED':
        message =
          'Votre demande de modification du site ' + siteName + ' a été accepté par ' + senderName;
        break;
      case 'EDIT_REQUEST_CANCELED':
        message =
          'Votre demande de modification du site ' + siteName + ' a été annulé par ' + senderName;
        break;
      case 'CREATION_ADMIN':
        if (senderName) {
          message = senderName + ' a effectué une création du site ' + siteName;
        } else {
          message = 'Un site a été créé depuis Batiregistre'
        }
        break;
      case 'MODIFICATION_ADMIN':
        if (senderName) {
          message = senderName + ' a effectué une modification du site ' + siteName;
        } else {
          message = 'Un site a été modifié depuis Batiregistre'
        }
        break;
      default:
        break;
    }
    return message;
  };

  return (
    <>
      <MIconButton
        ref={ anchorRef }
        onClick={ () => setOpen(true) }
        color={ open ? 'primary' : 'default' }
      >
        <Badge badgeContent={ totalUnRead } color="error">
          <Icon icon={ bellFill } width={ 20 } height={ 20 }/>
        </Badge>
      </MIconButton>

      <MenuPopover
        open={ open }
        onClose={ () => setOpen(false) }
        anchorEl={ anchorRef.current }
        sx={ { width: 360 } }
      >
        <Divider/>

        <Scrollbar sx={ { maxHeight: 600 } }>
          { Boolean(totalUnRead === 0) ? (
            <Typography style={ { display: 'flex', justifyContent: 'center', padding: 15 } }>
              Pas de notification
            </Typography>
          ) : (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={ { py: 1, px: 2.5, typography: 'overline' } }>
                  Nouveau
                </ListSubheader>
              }
            >
              { (notifications || []).map((notification) => {
                const senderName =
                  notification?.sender?.firstName + ' ' + notification?.sender?.lastName;

                return (
                  notification && (
                    <NotificationItem
                      key={ notification.id }
                      notification={ {
                        createdAt: notification.createdAt,
                        id: notification.id,
                        description: displayUserNotifications(notification),
                        title: notification.notificationType || '',
                        type: '',
                        isUnRead: notification.isRead,
                        avatar: '',
                        author: senderName
                      } }
                      groupId={ notification.sender?.userGroup?.id || '' }
                      groupName={ notification.sender?.userGroup?.name || '' }
                      siteId={ notification.site?.id || '' }
                      notificationType={ notification.notificationType || undefined }
                      reFetchNotification={ reFetchNotification }
                      currentPage={ currentPage }
                      setOpen={ setOpen }
                    />
                  )
                );
              }) }
            </List>
          ) }
        </Scrollbar>

        <Divider/>

        { Boolean(totalUnRead && totalUnRead > 3) && (
          <Box sx={ { p: 1 } }>
            <Button fullWidth onClick={ loadMoreNotifications }>
              Voir plus
            </Button>
          </Box>
        ) }
      </MenuPopover>
    </>
  );
}
