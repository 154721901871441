import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Container, Stack, Typography } from '@material-ui/core';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  return (
    <RootStyle title="Login | Batifire">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" textAlign="center" sx={ { px: 5, mt: 10 } }>
            Bonjour, bienvenue
          </Typography>
          <img
            src="/static/illustrations/illustration_logo_batifire.png"
            alt="login"
            width={ 350 }
            height={ 211 }
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={ { mb: 5 } }>
            <Box sx={ { flexGrow: 1 } }>
              <Typography variant="h4" gutterBottom>
                Connectez-vous à Batifire
              </Typography>
            </Box>
          </Stack>
          <LoginForm/>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
