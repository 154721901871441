import { Icon } from '@iconify/react';
import React, { useContext, useRef, useState } from 'react';
import personFill from '@iconify/icons-eva/person-fill';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import { Avatar, Box, Button, Divider, MenuItem, Typography } from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { AuthContext } from 'contexts/AuthContext';
import { useMeQuery, User } from 'generated/graphql';
import LoadingScreen from 'components/LoadingScreen';
import UserProfilDialog from 'components/_dashboard/user/UserProfilDialog';
import { setGeneralInfoInLocalStorageToInitial } from 'utils/util';

const MENU_OPTIONS = [{ label: 'Mon profil', icon: personFill, linkTo: '#' }];

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { setSession } = useContext(AuthContext);
  const { data, loading } = useMeQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });
  const navigate = useNavigate();
  const [openDialogProfil, setOpenDialogProfil] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenDialogProfil(false);
  };

  const handleLogout = () => {
    setSession(null);
    navigate('/');
    setGeneralInfoInLocalStorageToInitial();
    window.location.reload();
  };

  const handleOpenDialogProfil = () => {
    setOpen((prev) => !prev);
    setOpenDialogProfil((prev) => !prev);
  };

  if (loading) {
    return <LoadingScreen/>;
  }

  const handleCloseDialogProfil = () => {
    setOpenDialogProfil(false);
  };

  return (
    <>
      <MIconButton
        ref={ anchorRef }
        onClick={ handleOpen }
        sx={ {
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: '\'\'',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        } }
      >
        <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg"/>
      </MIconButton>

      <MenuPopover
        open={ open }
        onClose={ handleClose }
        anchorEl={ anchorRef.current }
        sx={ { width: 220 } }
      >
        <Box sx={ { my: 1.5, px: 2.5 } }>
          <Typography variant="subtitle1" noWrap>
            { data?.me?.accountName }
          </Typography>
          <Typography variant="body2" sx={ { color: 'text.secondary' } } noWrap>
            { data?.me?.email }
          </Typography>
        </Box>

        <Divider sx={ { my: 1 } }/>

        { MENU_OPTIONS.map((option) => (
          <MenuItem
            key={ option.label }
            onClick={ handleOpenDialogProfil }
            sx={ { typography: 'body2', py: 1, px: 2.5 } }
          >
            <Box
              component={ Icon }
              icon={ option.icon }
              sx={ {
                mr: 2,
                width: 24,
                height: 24
              } }
            />

            { option.label }
          </MenuItem>
        )) }

        <Box sx={ { p: 2, pt: 1.5 } }>
          <Button onClick={ handleLogout } fullWidth color="inherit" variant="outlined">
            Se déconnecter
          </Button>
        </Box>
      </MenuPopover>
      <UserProfilDialog
        open={ openDialogProfil }
        onClose={ handleCloseDialogProfil }
        user={ data?.me as User }
      />
    </>
  );
}
