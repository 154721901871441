import * as Yup from 'yup';
import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Alert, IconButton, InputAdornment, Link, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { AuthContext } from 'contexts/AuthContext';
import { useLoginMutation } from 'generated/graphql';
import { PATH_AUTH } from 'routes/paths';

// ----------------------------------------------------------------------
type InitialValues = {
  identifier: string;
  password: string;
  afterSubmit?: string;
};
export default function LoginForm() {
  const {setSession} = useContext(AuthContext);

  const isMountedRef = useIsMountedRef();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const [loginMutation, {loading}] = useLoginMutation();

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string().required(`Email / Nom de compte est obligatoire`),
    password: Yup.string().required('Mot de passe obligatoire')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      identifier: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, {setErrors, setSubmitting, resetForm}) => {
      loginMutation({
        variables: {
          input: {
            identifier: values.identifier,
            password: values.password
          }
        }
      })
        .then((result) => {
          if (result && result.data && result.data.login) {
            setSession(`${result.data?.login?.accessToken}`);
          }
          enqueueSnackbar('Connexion réussie', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill}/>
              </MIconButton>
            )
          });
          resetForm();
        })
        .catch((err) => {
          const errorMessage =
            err.message === 'Not Authorised!'
              ? `Authentification échoué. Email / Nom de compte ou mot de passe entré est incorrect.`
              : err.message;
          setErrors({afterSubmit: errorMessage});
        })
        .finally(() => {
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        });
    }
  });

  const {errors, touched, isSubmitting, handleSubmit, getFieldProps} = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            label="Email / Nom de compte"
            {...getFieldProps('identifier')}
            error={Boolean(touched.identifier && errors.identifier)}
            helperText={touched.identifier && errors.identifier}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{my: 2}}>
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.forgotPassword}
            style={{alignSelf: 'center'}}
          >
            Mot de passe oublié ?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || loading}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
