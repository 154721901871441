import React, { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import PermissionRouteGuard from 'guards/PermissionRouteGuard';
import { PERMISSIONS, PERMISSIONS_BY_TYPE } from 'guards/permissions';
import EntryPointGuard from 'guards/EntryPointGuard';
import { GROUP_PERMISSION } from '../interface/common';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={ {
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          } }
        />
      }
    >
      <Component { ...props } />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          )
        },
        {
          path: 'forgot-password',
          element: <ResetPassword/>
        },
        {
          path: 'reset-password/:token',
          element: <ResetPassword/>
        }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <EntryPointGuard/> },
        {
          path: 'dashboard',
          children: [
            {
              path: 'user',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/user/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.user }>
                      <UserList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: 'new',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.user.add] }>
                      <UserCreate/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:userId/edit',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.user.edit] }>
                      <UserCreate/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'group',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/group/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.group }>
                      <GroupList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:groupId/users',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.group.readDetail] }>
                      <UserInGroupList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: 'new',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.group.add] }>
                      <GroupCreate/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:groupId/edit',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.group.edit] }>
                      <UserCreate/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'siteType',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/siteType/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.siteType }>
                      <SiteTypeList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'tag',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/tag/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.tag }>
                      <TagList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'risk',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/risk/currentRisk/list" replace/>
                },
                {
                  path: 'currentRisk',
                  children: [
                    {
                      path: '/',
                      element: <Navigate to="/dashboard/risk/currentRisk/list" replace/>
                    },
                    {
                      path: 'list',
                      element: (
                        <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.currentRisk }>
                          <CurrentRiskList/>
                        </PermissionRouteGuard>
                      )
                    },
                    {
                      path: '/:currentRiskId/edit'
                    }
                  ]
                },
                {
                  path: 'specificRisk',
                  children: [
                    {
                      path: '/',
                      element: <Navigate to="/dashboard/risk/specificRisk/list" replace/>
                    },
                    {
                      path: 'list',
                      element: (
                        <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.specificRisk }>
                          <SpecificRiskList/>
                        </PermissionRouteGuard>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'site',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/site/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.site }>
                      <SiteList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: 'create-request-list',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.site.manageReview] }>
                      <CreateRequestList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: 'change-request-list',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.site.manageReview] }>
                      <ChangeRequestList/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: 'new',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.site.add] }>
                      <SiteCreate/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:siteId/edit',
                  element: (
                    <PermissionRouteGuard
                      permissions={ [PERMISSIONS.site.edit, PERMISSIONS.site.readDetail] }
                    >
                      <EditSite/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:siteId/modification-details/:userGroupId',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.site.manageReview] }>
                      <ModificationSiteDetails/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/:siteId/creation-details/:userGroupId',
                  element: (
                    <PermissionRouteGuard permissions={ [PERMISSIONS.site.manageReview] }>
                      <CreateRequestDetails/>
                    </PermissionRouteGuard>
                  )
                },
                {
                  path: '/export',
                  element: (
                    <PermissionRouteGuard permissions={ [GROUP_PERMISSION.IS_SUPERVISOR] }>
                      <ExportSites/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'dangerousMaterial',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/dangerousMaterial/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.dangerousMaterial }>
                      <DangerousMaterialList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'gedOps',
              children: [
                {
                  path: '/category',
                  children: [
                    {
                      path: '/',
                      element: <Navigate to="/dashboard/gedOps/category/list" replace/>
                    },
                    {
                      path: 'list',
                      element: (
                        <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.category }>
                          <CategoryList/>
                        </PermissionRouteGuard>
                      )
                    }
                  ]
                },
                {
                  path: '/',
                  element: <Navigate to="/dashboard/gedOps/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.gedOps }>
                      <GedOpsList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'device',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/device/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.device }>
                      <DeviceList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'department',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/Department/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.department }>
                      <DepartmentList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'commune',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/Commune/list" replace/>
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.commune }>
                      <CommuneList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'logs',
              children: [
                {
                  path: '/',
                  element: <Navigate to="/dashboard/logs/list" replace />
                },
                {
                  path: 'list',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.logs }>
                      <LogsList/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            },
            {
              path: 'admin',
              children: [
                {
                  path: '/',
                  element: (
                    <PermissionRouteGuard permissions={ PERMISSIONS_BY_TYPE.isBatifireAdmin }>
                      <Admin/>
                    </PermissionRouteGuard>
                  )
                }
              ]
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout/>,
      children: [
        { path: 'coming-soon', element: <ComingSoon/> },
        { path: 'maintenance', element: <Maintenance/> },
        { path: '500', element: <Page500/> },
        { path: '404', element: <NotFound/> },
        { path: '*', element: <Navigate to="/404" replace/> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace/> }
  ]);
}

//// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

// Dashboard
const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const EditSite = Loadable(lazy(() => import('../pages/site/EditSite')));
const ModificationSiteDetails = Loadable(
  lazy(() => import('../pages/changeRequest/changeRequestDetails/ChangeRequestDetails'))
);
const CreateRequestDetails = Loadable(
  lazy(() => import('../pages/createRequest/createRequestDetails/CreateRequestDetails'))
);
const SiteList = Loadable(lazy(() => import('../pages/site/ListSite')));
const SiteCreate = Loadable(lazy(() => import('../pages/site/NewSite')));
const GroupList = Loadable(lazy(() => import('../pages/group/GroupList')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const GroupCreate = Loadable(lazy(() => import('../pages/group/GroupCreate')));
const SiteTypeList = Loadable(lazy(() => import('../pages/siteType/SiteTypeList')));
const TagList = Loadable(lazy(() => import('../pages/tag/TagList')));
const ChangeRequestList = Loadable(lazy(() => import('../pages/changeRequest')));
const CreateRequestList = Loadable(lazy(() => import('../pages/createRequest')));
const UserInGroupList = Loadable(lazy(() => import('../pages/group/UserGroupList')));
const CurrentRiskList = Loadable(lazy(() => import('../pages/currentRisk/CurrentRiskList')));
const SpecificRiskList = Loadable(lazy(() => import('../pages/specificRisk/SpecificRiskList')));
const DangerousMaterialList = Loadable(lazy(() => import('../pages/dangerousMaterial/DangerousMaterialList')));
const DepartmentList = Loadable(lazy(() => import('../pages/department/DepartmentList')));
const CommuneList = Loadable(lazy(() => import('../pages/commune/CommuneList')));
const GedOpsList = Loadable(lazy(() => import('../pages/gedOps/GedOpsList')));
const CategoryList = Loadable(lazy(() => import('../pages/category/CategoryList')));
const DeviceList = Loadable(lazy(() => import('../pages/device/DeviceList')));
const LogsList = Loadable(lazy(() => import('../pages/logs/LogsList')));
const Admin = Loadable(lazy(() => import('../pages/admin/Admin')));
const ExportSites = Loadable(lazy(() => import('../pages/site/ExportSites')));

// Main Routes
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
