import React, { useEffect } from 'react';
import { createContext, FC, useState } from 'react';

type AuthType = {
  token: string | null | undefined;
  initialized: boolean;
  setSession: (token: string | null) => void;
};

export const AuthContext = createContext<AuthType>({
  token: null,
  initialized: false,
  setSession: () => {},
});

export const AuthProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string | null | undefined>();
  const [initialized, setInitalized] = useState(false);

  useEffect(() => {
    setToken(window.localStorage.getItem('accessToken'));
    setInitalized(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        initialized,
        setSession: (token: string | null) => {
          window.location.reload();
          window.localStorage.setItem('accessToken', token ? token : '');
          sessionStorage.clear();
          setToken(token);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
