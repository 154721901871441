import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@material-ui/core';
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import React, { useContext } from 'react';
import NotificationsPopover from './NotificationsPopover';
import { UserContext } from 'contexts/UserContext';
import { GROUP_PERMISSION } from '../../interface/common';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${ DRAWER_WIDTH + 1 }px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({onOpenSidebar}: DashboardNavbarProps) {
  const {me} = useContext(UserContext);

  const isBatifireAdmin = () => {
    if (me && me.userGroup && me.userGroup.permissions) {
      if (me.userGroup.permissions.find(value => value.name === GROUP_PERMISSION.IS_BATIFIRE.toString())) {
        return true;
      }
    }
    return false;
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={ onOpenSidebar } sx={ {mr: 1, color: 'text.primary'} }>
            <Icon icon={ menu2Fill }/>
          </IconButton>
        </MHidden>

        <Box sx={ {flexGrow: 1} }/>

        <Stack direction="row" spacing={ {xs: 0.5, sm: 1.5} }>
          { !isBatifireAdmin() && <NotificationsPopover/> }
          <AccountPopover/>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
