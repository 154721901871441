import Router from './routes';
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { Worker } from '@react-pdf-viewer/core';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from 'utils/apollo';
import { useContext } from 'react';
import { AuthContext, AuthProvider } from 'contexts/AuthContext';
import { UserProvider } from 'contexts/UserContext';
import { FileSizeProvider } from 'contexts/FileContext';

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop/>
            <AuthProvider>
              <MainApp/>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js"/>
            </AuthProvider>
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

const MainApp = () => {
  const { initialized, token } = useContext(AuthContext);
  const client = createApolloClient(token || '');
  return (
    <ApolloProvider client={ client }>
      { initialized ? (
        <FileSizeProvider>
          <UserProvider>
            <Router/>
          </UserProvider>
        </FileSizeProvider>
      ) : (
        <LoadingScreen/>
      ) }
    </ApolloProvider>
  );
};
