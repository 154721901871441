import gql from 'graphql-tag';

export const SITE_FRAGMENT = gql`
  fragment SiteFragment on Site {
    id
    name
    logoUrl
    communeId
    createdAt
    updatedAt
    status
    isFromBatiregistre
    managePasswordBySupervisor
    authorizedGroups {
      id
      name
    }
    siteHistories {
      id
      field
      status
      value
    }
    planHistories
    documentHistories
    contactHistories
    commune {
      id
      name
      department {
        id
        name
      }
    }
    siteType {
      id
      name
    }
    etare
    referenceEtare
    sensible
    password
    passwordDecrypted
    address
    riskLevel
    activity
    ranking
    coordinates
    reference
    town
    author {
      lastName
      firstName
      userGroup {
        id
        name
      }
    }
    zipCode
    bienHistory
    latitude
    longitude
  }
`;

export const EDIT_REQUEST_SITE_LIST_FRAGMENT = gql`
  fragment EditRequestSiteList on Site {
    id
    name
    etare
    createdAt
    siteHistories {
      id
      field
      status
      value
      site {
        id
      }
      author {
        firstName
        lastName
        userGroup {
          name
        }
      }
    }
    commune {
      id
      department {
        id
        name
      }
    }
    author {
      lastName
      firstName
      userGroup {
        id
        name
      }
    }
  }
`;
