import gql from 'graphql-tag';

export const NOTIFICATION_FRAGMENT = gql`
    fragment NotificationFragment on Notification {
        id
        isRead
        department {
            id
            name
        }
        receiver_type
        createdAt
        notificationType
        sender {
            firstName
            lastName
            userGroup {
                id
                name
            }
        }
        site {
            id
            name
        }
    }
`;
