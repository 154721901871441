import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Box, Drawer, Link, Typography } from '@material-ui/core';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import LoadingScreen from 'components/LoadingScreen';
import {
  GetValidationRequestCountQuery,
  GetValidationRequestCountQueryVariables,
  useGetValidationRequestCountQuery,
  useGetValidationRequestCountSubscriptionSubscription,
  useMeQuery
} from 'generated/graphql';
import { UserContext } from 'contexts/UserContext';
import { FileSizeContext } from 'contexts/FileContext';
import { GET_VALIDATION_REQUEST_COUNT } from 'gql/Site/query';
import FileSizeDialog from '../../components/_dashboard/fileSize/FileSizeDialog';
import { fData } from 'utils/formatNumber';
import PermissionGuard from 'guards/PermissionGuard';
import { PERMISSIONS } from 'guards/permissions';
import { versionDate, versionNumber } from '../../config';

const DRAWER_WIDTH = 300;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const FileSizeStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  color: theme.palette.grey[900],
  ':hover': {
    backgroundColor: '#FFEDEB',
    cursor: 'pointer',
    color: theme.palette.primary.main
  }
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { me, permissions } = useContext(UserContext);
  const [openFileSize, setOpenFileSize] = useState<boolean>(false);
  const currentFileSize = useContext(FileSizeContext);
  const [fileSize, setFileSize] = useState<{
    id: string;
    size: number;
  }>({
    id: currentFileSize.id,
    size: currentFileSize.size
  });
  const { data, loading } = useMeQuery();

  useEffect(() => {
    if (currentFileSize) {
      setFileSize({
        id: currentFileSize.id,
        size: currentFileSize.size
      });
    }
  }, [currentFileSize]);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const {
    data: validationRequestData,
    loading: validationRequestLoading
  } = useGetValidationRequestCountQuery();

  useGetValidationRequestCountSubscriptionSubscription({
    variables: {
      input: {
        userId: me?.id || ''
      }
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data?.getValidationRequestCount) {
        return;
      }
      const cachedData = client.readQuery<GetValidationRequestCountQuery,
        GetValidationRequestCountQueryVariables>({
        query: GET_VALIDATION_REQUEST_COUNT
      });

      client.writeQuery<GetValidationRequestCountQuery, GetValidationRequestCountQueryVariables>({
        query: GET_VALIDATION_REQUEST_COUNT,
        data: {
          ...cachedData,
          getValidationRequestCount: {
            ...subscriptionData.data.getValidationRequestCount
          }
        }
      });
    }
  });

  const validationRequestCount = validationRequestData?.getValidationRequestCount;

  if (loading || validationRequestLoading) {
    return <LoadingScreen/>;
  }

  const handleCloseFileSize = () => {
    setOpenFileSize(false);
  };

  const renderContent = (
    <Scrollbar
      sx={ {
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      } }
    >
      <Box sx={ { px: 2.5, py: 6, display: 'flex', justifyContent: 'center' } }>
        <Box component={ RouterLink } to="/" sx={ { display: 'inline-flex' } }>
          <Logo width={ 180 } height={ 109 }/>
        </Box>
      </Box>

      <Box sx={ { mb: 2, mx: 2.5, mt: 4 } }>
        <Link underline="none" component={ RouterLink } to={ '/' }>
          <AccountStyle>
            <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg"/>

            <Box sx={ { ml: 2 } }>
              <Typography variant="subtitle2" sx={ { color: 'text.primary' } }>
                { data?.me?.accountName }
              </Typography>
              <Typography variant="body2" sx={ { color: 'text.secondary' } }>
                { data?.me?.userGroup?.name }
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={ sidebarConfig(permissions, validationRequestCount) as any }/>

      <Box sx={ { flexGrow: 1 } }/>

      <Box sx={ { px: 2.5, pb: 3, mt: 10 } }>
        {/* Edit max file size */ }
        <Box
          sx={ { mb: 2, mx: 2, mt: 2 } }
          onClick={ () => {
            setOpenFileSize((prev) => !prev);
          } }
        >
          <FileSizeStyle>
            <Box sx={ { ml: 2, textAlign: 'center' } }>
              <Typography variant="subtitle2">Taille maximale du fichier</Typography>
              <Typography variant="body2" sx={ { color: 'text.secondary' } }>
                { fData(currentFileSize.size) }
              </Typography>
            </Box>
          </FileSizeStyle>
        </Box>
        <Box
          style={ {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'row'
          } }
        >
          <Box component="span" style={ { display: 'flex', alignItems: 'center' } }>
            <img
              src="/static/icons/date_range_black.svg"
              alt="login"
              style={ { width: 18, height: 18, marginRight: 8 } }
            />
            <span style={ { marginTop: 2 } }>{ versionDate }</span>
          </Box>
          <Box component="span" style={ { display: 'flex', alignItems: 'center' } }>
            <img
              src="/static/icons/version.svg"
              alt="login"
              style={ { width: 18, height: 18, marginRight: 8 } }
            />
            { versionNumber }
          </Box>
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <PermissionGuard permissions={ [PERMISSIONS.fileSize.edit] }>
        <FileSizeDialog open={ openFileSize } fileSize={ fileSize } onClose={ handleCloseFileSize }/>
      </PermissionGuard>
      <MHidden width="lgUp">
        <Drawer
          open={ isOpenSidebar }
          onClose={ onCloseSidebar }
          PaperProps={ {
            sx: { width: DRAWER_WIDTH }
          } }
        >
          { renderContent }
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={ {
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          } }
        >
          { renderContent }
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
