import gql from 'graphql-tag';
import { EDIT_REQUEST_SITE_LIST_FRAGMENT, SITE_FRAGMENT } from './fragment';

export const GET_ALL_SITES = gql`
  query getAllSite($input: InputGetAllSite) {
    allSites(input: $input) {
      pageInfo {
        currentPage
        totalCount
        hasNextPage
      }
      data {
        ...SiteFragment
      }
    }
  }
  ${ SITE_FRAGMENT }
`;

export const GET_SITE_BY_ID = gql`
  query GetSiteById($input: InputGetSiteById) {
    getSiteById(input: $input) {
      ...SiteFragment
    }
  }
  ${ SITE_FRAGMENT }
`;

export const GET_ALL_CREATION_REQUEST = gql`
  query GetAllCreationRequest {
    getAllCreationRequest {
      id
      name
      logoUrl
      communeId
      createdAt
      status
      isFromBatiregistre
      managePasswordBySupervisor
      authorizedGroups {
        id
        name
      }
      siteHistories {
        id
        field
        status
        value
      }
      planHistories
      documentHistories
      contactHistories
      commune {
        id
        name
        department {
          id
          name
        }
      }
      siteType {
        id
        name
      }
      etare
      referenceEtare
      sensible
      password
      passwordDecrypted
      address
      riskLevel
      activity
      ranking
      coordinates
      reference
      town
      author {
        lastName
        firstName
        userGroup {
          id
          name
        }
      }
      zipCode
      bienHistory
    }
  }
`;

export const GET_ALL_EDIT_REQUEST = gql`
  query GetAllEditRequest {
    getAllEditRequest {
      sites {
        ...EditRequestSiteList
      }
      editorGroupName
      editorId
    }
  }
  ${ EDIT_REQUEST_SITE_LIST_FRAGMENT }
`;

export const CHECK_HISTIRY_DATA = gql`
  query checkHistoriesData($input: InputHistoryData) {
    checkHistoriesData(input: $input) {
      hasPlanHistories
      hasContactHistories
      hasDocumentHistories
      hasCurrentRiskHistories
      hasSpecificRiskHistories
      hasMoyenHistories
      hasBienHistories
    }
  }
`;

export const GET_VALIDATION_REQUEST_COUNT = gql`
  query GetValidationRequestCount {
    getValidationRequestCount {
      create
      edit
      total
    }
  }
`;

export const GET_EXPORT_SITES = gql`
    query GetExportSites($input: InputGetExportSites) {
        getExportSites(input: $input) {
            file
        }
    }
`;


export const GET_EXPORT_SITE_BY_SITE_ID = gql`
    query GetExportSiteBySiteId($input: InputGetExportSiteBySiteId) {
        getExportSiteBySiteId(input: $input) {
            file
        }
    }
`;
