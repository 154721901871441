import { ReactNode, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

type PermissionGuardProp = {
  permissions: string[];
  children: ReactNode | string;
  readonlyDepartmentId?: string;
};

export default function PermissionGuard({
                                          permissions,
                                          children,
                                          readonlyDepartmentId
                                        }: PermissionGuardProp) {
  const { can, me } = useContext(UserContext);
  const readOnlyDepartmentUserList = me?.departmentUser.filter(departmentUser => departmentUser.readonly === true);
  const hasAccess = readOnlyDepartmentUserList?.some((departmentUser) => departmentUser.department.id === readonlyDepartmentId)
    ? false
    : can(permissions);
  if (hasAccess) {
    return <>{ children }</>;
  }

  return null;
}
