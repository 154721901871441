export enum USER_GROUP_TYPE {
  BATIFIRE = 'batifire',
  SUPERVISOR = 'supervisor',
  OPERATEUR = 'operator',
  CLIENT = 'client',
  NONE = 'none'
}

export enum GROUP_PERMISSION {
  IS_BATIFIRE = 'IS_BATIFIRE',
  IS_SUPERVISOR = 'IS_SUPERVISOR',
  MANAGE_REVIEW = 'MANAGE_REVIEW'
}
