export const getItemGeneralInformationFromLocalStorage = () => {
  const sessionData = sessionStorage.getItem('generalInformationData');
  let generalInformationData = undefined;
  if (sessionData) {
    generalInformationData = JSON.parse(sessionData);
  }
  return generalInformationData;
};
export const writeGeneralInfoInLocalStorage = (data: any) => {
  sessionStorage.setItem('generalInformationData', data);
};

export const setGeneralInfoInLocalStorageToInitial = () => {
  sessionStorage.clear()
};
