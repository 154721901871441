import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import clockFill from '@iconify/icons-eva/clock-fill';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import fr from 'date-fns/locale/fr';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { useNavigate } from 'react-router-dom';
import { paramCase } from 'change-case';
import { PATH_DASHBOARD } from 'routes/paths';
import {
  Exact,
  GetCurrentUserNotificationsQuery,
  InputGetUserNotifications,
  Maybe,
  NotificationTypeEnum,
  ReceiverTypeEnum,
  useUpdateNotificationByIdMutation
} from 'generated/graphql';
import { MIconButton } from '../../components/@material-extend';
import { ApolloQueryResult } from '@apollo/client';
import { underScoreJoin } from 'common/commonFunction';

type TNotificationPopover = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
  author: string;
};

const TITLE_CONVERTER: Record<string, string> = {
  CREATION_REQUEST: 'DEMANDE DE CRÉATION',
  MODIFICATION_REQUEST: 'DEMANDE DE MODIFICATION',
  CREATION_REQUEST_ACCEPTED: 'DEMANDE DE CRÉATION ACCEPTÉE',
  CREATION_REQUEST_CANCELED: 'DEMANDE DE CRÉATION ANNULÉE\'',
  EDIT_REQUEST_ACCEPTED: 'DEMANDE DE MODIFICATION ACCEPTÉE',
  EDIT_REQUEST_CANCELED: 'DEMANDE DE MODIFICATION ANNULÉE',
  CREATION_ADMIN: 'CRÉATION EFFECTUÉ PAR UN ADMIN',
  MODIFICTION_ADMIN: 'MODIFICATION EFFECTUÉ PAR UN ADMIN'
};

function renderContent(notification: TNotificationPopover) {
  const title = (
    <>
      <Typography variant="subtitle2" style={ { display: 'flex' } }>
        { TITLE_CONVERTER[notification.title] }{ ' ' }
        { notification.isUnRead && (
          <MIconButton color="primary" style={ { padding: 0 } }>
            <Icon icon={ doneAllFill } width={ 20 } height={ 20 }/>
          </MIconButton>
        ) }
      </Typography>
      <Typography component="span" variant="body2" sx={ { color: 'text.secondary' } }>
        { notification.description }
      </Typography>
    </>
  );
  return { title };
}

interface NotificationItemProps {
  notification: TNotificationPopover;
  groupId: string;
  groupName: string;
  siteId: string;
  currentPage: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notificationType: NotificationTypeEnum | undefined;
  reFetchNotification: (
    variables?:
      | Partial<Exact<{
      input?: Maybe<InputGetUserNotifications> | undefined;
    }>>
      | undefined
  ) => Promise<ApolloQueryResult<GetCurrentUserNotificationsQuery>> | undefined;
}

const NotificationItem = (props: NotificationItemProps) => {
  const {
    notification,
    groupId,
    groupName,
    siteId,
    notificationType,
    reFetchNotification,
    currentPage,
    setOpen
  } = props;

  const navigate = useNavigate();

  const [setNotificationAsRead] = useUpdateNotificationByIdMutation({
    onCompleted: (data) => {
      if (data) {
        reFetchNotification({
          input: {
            maxPage: 3,
            currentPage,
            searchParams: ''
          }
        });
      }
    }
  });

  const { title } = renderContent(notification);
  const viewNotificationDetail = () => {
    setNotificationAsRead({
      variables: {
        id: notification.id,
        isRead: true
      }
    });
    switch (notificationType) {
      case 'CREATION_REQUEST':
        navigate(
          `${ PATH_DASHBOARD.site.root }/${ paramCase(siteId) }/creation-details/${ paramCase(groupId) }`,
          {
            state: {
              id: siteId,
              notificationType: 'CREATION_REQUEST_ACCEPTED',
              receiver_type: underScoreJoin(groupName) as ReceiverTypeEnum,
              authorGroupId: groupId || ''
            }
          }
        );
        break;
      case 'MODIFICATION_REQUEST':
        navigate(
          `${ PATH_DASHBOARD.site.root }/${ paramCase(siteId) }/modification-details/${ paramCase(groupId) }`,
          {
            state: {
              id: siteId,
              notificationType: 'EDIT_REQUEST_ACCEPTED',
              receiver_type: underScoreJoin(groupName) as ReceiverTypeEnum,
              groupId
            }
          }
        );
        break;
      case 'CREATION_REQUEST_ACCEPTED':
      case 'EDIT_REQUEST_CANCELED':
      case 'EDIT_REQUEST_ACCEPTED':
        navigate(`${ PATH_DASHBOARD.site.root }/${ paramCase(siteId) }/edit`);
        break;
      case 'CREATION_ADMIN':
        navigate(`${ PATH_DASHBOARD.site.root }/${ paramCase(siteId) }/edit`);
        break;
      case 'MODIFICATION_ADMIN':
        navigate(`${ PATH_DASHBOARD.site.root }/${ paramCase(siteId) }/edit`);
        break;
      default:
        break;
    }
    setOpen(false);
  };
  return (
    <ListItem
      button
      disableGutters
      key={ notification.id }
      onClick={ viewNotificationDetail }
      sx={ {
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      } }
    >
      <ListItemAvatar>
        <Avatar sx={ { bgcolor: 'background.neutral' } }>
          { notification.author.charAt(0).toUpperCase() }
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={ <>{ title } </> }
        secondary={
          <Typography
            variant="caption"
            sx={ {
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            } }
          >
            <Box component={ Icon } icon={ clockFill } sx={ { mr: 0.5, width: 16, height: 16 } }/>
            { formatDistanceToNow(new Date(notification.createdAt), { locale: fr }) }
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NotificationItem;
