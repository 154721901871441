// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { PERMISSIONS, PERMISSIONS_BY_TYPE } from 'guards/permissions';
import { ValidationRequestCountReturn } from 'generated/graphql';
import { GROUP_PERMISSION } from '../../interface/common';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={ `/static/icons/navbar/${ name }.svg` } sx={ { width: '100%', height: '100%' } }/>
);

const ICONS = {
  user: getIcon('ic_manage_user'),
  groups: getIcon('ic_manage_group'),
  risks: getIcon('ic_replace'),
  sites: getIcon('ic_sites'),
  moyens: getIcon('ic_moyen'),
  devices: getIcon('ic_device'),
  dangerousMaterial: getIcon('ic_dangerous_material'),
  gedOps: getIcon('ic_ged_ops'),
  department: getIcon('ic_department'),
  commune: getIcon('ic_commune'),
  logs: getIcon('ic_ged_ops'),
};

const sidebarConfig = (
  permissions: string[],
  badges: ValidationRequestCountReturn | undefined | null
) => {
  const isAllowed = (requiredPermissions: string[]): boolean => {
    return requiredPermissions.some((p) => permissions.includes(p.toLowerCase()));
  };
  return [
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // MANAGEMENT : USER
        isAllowed(PERMISSIONS_BY_TYPE.site) && {
          title: 'Gestion des sites',
          showBadge: isAllowed([PERMISSIONS.site.manageReview]),
          badge: badges?.total || 0,
          path: PATH_DASHBOARD.site.root,
          icon: ICONS.sites,
          userPermissions: PERMISSIONS_BY_TYPE.site,
          children: [
            {
              title: 'Liste des sites',
              path: PATH_DASHBOARD.site.list,
              userPermissions: permissions
            },
            isAllowed([PERMISSIONS.site.manageReview]) && {
              title: 'Demande de Création',
              path: PATH_DASHBOARD.site.listCreateRequest,
              showBadge: isAllowed([PERMISSIONS.site.manageReview]),
              badge: badges?.create || 0,
              userPermissions: permissions
            },
            isAllowed([PERMISSIONS.site.manageReview]) && {
              title: 'Demande de Modification',
              path: PATH_DASHBOARD.site.listChangeRequest,
              showBadge: isAllowed([PERMISSIONS.site.manageReview]),
              badge: badges?.edit || 0,
              userPermissions: permissions
            },
            isAllowed(PERMISSIONS_BY_TYPE.siteType) && {
              title: 'Gestion des types de batiment',
              path: PATH_DASHBOARD.siteType.root,
              icon: ICONS.groups,
              userPermissions: PERMISSIONS_BY_TYPE.siteType
            },
            isAllowed(PERMISSIONS_BY_TYPE.tag) && {
              title: 'Gestion des types de plans',
              path: PATH_DASHBOARD.tag.root,
              icon: ICONS.groups,
              userPermissions: PERMISSIONS_BY_TYPE.tag
            },
            isAllowed([GROUP_PERMISSION.IS_SUPERVISOR]) && {
              title: 'Export des sites',
              path: PATH_DASHBOARD.site.export,
              showBadge: isAllowed([GROUP_PERMISSION.IS_SUPERVISOR]),
              userPermissions: permissions
            }
          ]
        },
        isAllowed(PERMISSIONS_BY_TYPE.user) && {
          title: 'Gestion des utilisateurs',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          userPermissions: PERMISSIONS_BY_TYPE.user
        },
        isAllowed(PERMISSIONS_BY_TYPE.group) && {
          title: 'Gestion des groupes',
          path: PATH_DASHBOARD.group.root,
          icon: ICONS.groups,
          userPermissions: PERMISSIONS_BY_TYPE.group
        },
        {
          title: 'Gestion des risques',
          path: PATH_DASHBOARD.risk.root,
          icon: ICONS.risks,
          userPermissions: PERMISSIONS_BY_TYPE.specificRisk.concat(PERMISSIONS_BY_TYPE.currentRisk),
          children: [
            isAllowed(PERMISSIONS_BY_TYPE.currentRisk) && {
              title: 'Risques courants',
              path: PATH_DASHBOARD.risk.currentRisk.root,
              userPermissions: permissions
            },
            isAllowed(PERMISSIONS_BY_TYPE.specificRisk) && {
              title: 'Risques particuliers',
              path: PATH_DASHBOARD.risk.specificRisk.root,
              userPermissions: permissions
            }
          ]
        },
        // isAllowed(PERMISSIONS_BY_TYPE.moyen) && {
        //   title: 'Gestion des moyens',
        //   path: PATH_DASHBOARD.moyen.root,
        //   icon: ICONS.moyens,
        //   userPermissions: PERMISSIONS_BY_TYPE.moyen,
        // },
        isAllowed(PERMISSIONS_BY_TYPE.dangerousMaterial) && {
          title: 'Matière dangereuse',
          path: PATH_DASHBOARD.dangerousMaterial.root,
          icon: ICONS.dangerousMaterial,
          userPermissions: PERMISSIONS_BY_TYPE.dangerousMaterial
        },
        isAllowed([...PERMISSIONS_BY_TYPE.category, ...PERMISSIONS_BY_TYPE.gedOps]) && {
          title: 'Ged Ops',
          path: PATH_DASHBOARD.gedOps.category.root,
          icon: ICONS.gedOps,
          userPermissions: PERMISSIONS_BY_TYPE.category.concat(PERMISSIONS_BY_TYPE.gedOps),
          children: [
            isAllowed(PERMISSIONS_BY_TYPE.category) && {
              title: 'Gestion des catégories',
              path: PATH_DASHBOARD.gedOps.category.root,
              userPermissions: permissions
            },
            isAllowed(PERMISSIONS_BY_TYPE.gedOps) && {
              title: 'Gestion des documents',
              path: PATH_DASHBOARD.gedOps.list,
              userPermissions: permissions
            }
          ]
        },
        isAllowed(PERMISSIONS_BY_TYPE.device) && {
          title: 'Périphériques',
          path: PATH_DASHBOARD.device.root,
          icon: ICONS.devices,
          userPermissions: PERMISSIONS_BY_TYPE.device
        },
        isAllowed(PERMISSIONS_BY_TYPE.department) && {
          title: 'Gestion de département',
          path: PATH_DASHBOARD.Department.root,
          icon: ICONS.department,
          userPermissions: PERMISSIONS_BY_TYPE.department
        },
        isAllowed(PERMISSIONS_BY_TYPE.commune) && {
          title: 'Gestion de commune',
          path: PATH_DASHBOARD.Commune.root,
          icon: ICONS.commune,
          userPermissions: PERMISSIONS_BY_TYPE.department
        },
        isAllowed(PERMISSIONS_BY_TYPE.logs) && {
          title: 'Historique des actions',
          path: PATH_DASHBOARD.logs.list,
          icon: ICONS.logs,
          userPermissions: PERMISSIONS_BY_TYPE.logs
        }
      ]
    }
  ];
};
export default sidebarConfig;
