import gql from 'graphql-tag';

export const ME = gql`
    query me {
        me {
            id
            firstName
            lastName
            accountName
            email
            password
            getCreateNotification
            getUpdateNotification
            userGroup {
                id
                name
                deleted
                permissions {
                    id
                    name
                }
            }
            departments {
                id
                name
            }
            defaultDepartments {
                id
                name
            }
            departmentUser {
                id
                readonly
                department {
                    id
                    name
                }
            }
            users {
                id
            }
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers($input: InputFilterUsers) {
        users(input: $input) {
            data {
                id
                lastName
                firstName
                accountName
                email
                accountStatus
                deleted
                isAdmin
                createdAt
                updatedAt
                userGroup {
                    id
                    name
                    deleted
                    permissions {
                        id
                        name
                    }
                }
                departments {
                    id
                    name
                    departmentCode
                }
                departmentUser {
                    id
                    readonly
                    department {
                        id
                        name
                    }
                }
            }
            pageInfo {
                currentPage
                totalCount
                hasNextPage
            }
        }
    }
`;

export const GET_USER_BY_ID = gql`
    query GetUserById($input: InputUser) {
        getUserById(input: $input) {
            id
            lastName
            firstName
            accountName
            email
            accountStatus
            deleted
            isAdmin
            createdAt
            updatedAt
            userGroup {
                id
                name
                deleted
                permissions {
                    id
                    name
                }
            }
            departments {
                id
                name
                departmentCode
            }
            defaultDepartments {
                id
                name
            }
            departmentUser {
                id
                readonly
                department {
                    id
                    name
                }
            }
            users {
                id
            }
        }
    }
`;

export const GET_ALL_USERS_BY_CURRENT_USER = gql`
    query getUsersByCurrentUser($input: InputGetUsersByCurrentUser) {
        getUsersByCurrentUser(input: $input) {
            data {
                id
                lastName
                firstName
                accountName
                email
                accountStatus
                isAdmin
                userGroup {
                    id
                    name
                    deleted
                }
            },
            pageInfo {
                currentPage,
                totalCount,
                hasNextPage
            }
        }
    }
`;
