import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useCreateUpdateFileSizeMutation } from 'generated/graphql';
import { keyErrorExist } from 'common/commonFunction';
import _ from 'lodash';

interface FileSizeDialogProps {
  children?: any;
  open: boolean;
  onClose: () => void;
  fileSize: {
    id: string;
    size: number;
  };
}

const FileSizeDialog: FC<FileSizeDialogProps> = (props) => {
  const { fileSize, onClose, open } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [createUpdateFileSize] = useCreateUpdateFileSizeMutation();

  const validationSchema = Yup.object().shape({
    size: Yup.string()
      .required('Taille du fichier obligatoire')
      .matches(/^[0-9]+$/, 'Nombre seulement')
  });

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      size: fileSize.size / 1024
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      setSubmitting(true);
      try {
        const result = await createUpdateFileSize({
          variables: {
            input: {
              id: fileSize.id || '',
              size: parseInt(String(values.size)) * 1024
            }
          }
        });
        if (result && result.data && result.data.createUpdateFileSize) {
          setSubmitting(false);
          handleClose();
          enqueueSnackbar(!fileSize ? 'Taille du fichier créée' : 'Taille du fichier modifiée', {
            variant: 'success'
          });
        }
      } catch (error) {
        let errorMessage = 'Une erreur est survenue';
        if (error.networkError) {
          errorMessage = 'Erreur de réseau. Veuillez vérifier votre connexion internet';
        }
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setSubmitting(false);
      }
    }
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    resetForm,
    isSubmitting,
    getFieldProps,
    initialValues
  } = formik;

  const checkSizeValue = () => {
    return _.isEqual(values.size, initialValues.size);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={ open } onClose={ handleClose }>
      <DialogTitle>Modifier la taille maximale du fichier (Ko)</DialogTitle>
      <DialogContent>
        <FormikProvider value={ formik }>
          <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 12 }>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="1500"
                  label="Taille du fichier*"
                  { ...getFieldProps('size') }
                  onKeyDown={ (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault() }
                  error={ Boolean(touched.size && errors.size) }
                  helperText={ touched.size && errors.size }
                />
                <Box sx={ { mt: 3, display: 'flex', justifyContent: 'flex-end' } }>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={ !values.size || keyErrorExist(['size'], errors) || checkSizeValue() }
                    loading={ isSubmitting }
                    style={ { marginRight: 15 } }
                  >
                    { !fileSize ? 'Ajouter' : 'Modifier' }
                  </LoadingButton>
                  <Button variant="outlined" onClick={ handleClose }>
                    Annuler
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};

export default FileSizeDialog;
