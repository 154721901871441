export const PERMISSIONS = {
  fullAccess: 'full_access',
  site: {
    add: 'add_site',
    delete: 'delete_site',
    edit: 'edit_site',
    readList: 'read_site_list',
    readDetail: 'read_site_detail',
    readSensitiveDetail: 'read_sensitive_site_detail',
    readPasswordSite: 'read_password_site',
    manageReview: 'manage_review',
  },
  user: {
    add: 'add_user',
    delete: 'delete_user',
    edit: 'edit_user',
    readList: 'read_user_list',
    add_admin: 'add_user_admin',
    edit_admin: 'edit_user_admin',
    readList_admin: 'read_user_admin_list',
    delete_admin: 'delete_user_admin',
  },
  group: {
    add: 'add_group',
    edit: 'edit_group',
    delete: 'delete_group',
    readList: 'read_group_list',
    readDetail: 'read_group_detail',
  },
  plan: {
    add: 'add_plan_in_site',
    edit: 'edit_plan_in_site',
    delete: 'delete_plan_in_site',
    readList: 'read_plan_in_site',
  },
  document: {
    add: 'add_document_in_site',
    edit: 'edit_document_in_site',
    delete: 'delete_document_in_site',
    readList: 'read_document_in_site',
  },
  contact: {
    add: 'add_contact_in_site',
    edit: 'edit_contact_in_site',
    delete: 'delete_contact_in_site',
    readList: 'read_contact_in_site',
  },
  currentRiskToSite: {
    // add: 'add_current_risk_in_site',
    edit: 'edit_current_risk_in_site',
    // delete: 'delete_current_risk_in_site',
    readList: 'read_current_risk_in_site',
  },
  specificRiskToSite: {
    add: 'add_specific_risk_in_site',
    edit: 'edit_specific_risk_in_site',
    delete: 'delete_specific_risk_in_site',
    readList: 'read_specific_risk_in_site',
  },
  currentRisk: {
    add: 'add_current_risk',
    edit: 'edit_current_risk',
    delete: 'delete_current_risk',
    readList: 'read_current_risk',
  },
  specificRisk: {
    add: 'add_specific_risk',
    edit: 'edit_specific_risk',
    delete: 'delete_specific_risk',
    readList: 'read_specific_risk',
  },
  siteType: {
    add: 'add_site_type',
    edit: 'edit_site_type',
    delete: 'delete_site_type',
    readList: 'read_site_type',
  },
  tag: {
    add: 'add_tag',
    edit: 'edit_tag',
    delete: 'delete_tag',
    readList: 'read_tag',
  },
  moyen: {
    add: 'add_moyen',
    edit: 'edit_moyen',
    delete: 'delete_moyen',
    readList: 'read_moyen',
  },
  bien: {
    add: 'create_bien',
    edit: 'edit_bien',
    delete: 'delete_bien',
    readList: 'read_bien',
  },
  dangerousMaterial: {
    add: 'add_dangerous_material',
    edit: 'edit_dangerous_material',
    delete: 'delete_dangerous_material',
    read: 'read_dangerous_material_list',
  },
  category: {
    add: 'add_category',
    edit: 'edit_category',
    delete: 'delete_category',
    readList: 'read_category',
  },
  gedOps: {
    add: 'add_ged_ops',
    edit: 'edit_ged_ops',
    delete: 'delete_ged_ops',
    readList: 'read_ged_ops',
  },
  device: {
    readList: 'read_device',
    edit: 'edit_device',
  },
  department: {
    add: 'add_department',
    edit: 'edit_department',
    delete: 'delete_department',
    readList: 'read_department',
  },
  commune: {
    add: 'add_commune',
    edit: 'edit_commune',
    delete: 'delete_commune',
    readList: 'read_commune',
  },
  fileSize: {
    edit: 'edit_file_size',
  },
};

export const PERMISSIONS_BY_TYPE = {
  site: [
    'add_site',
    'delete_site',
    'edit_site',
    'read_site_list',
    'read_site_detail',
    'read_sensitive_site_detail',
    'read_password_site',
  ],
  user: ['add_user', 'delete_user', 'edit_user', 'read_user_list'],
  group: ['add_group', 'edit_group', 'delete_group', 'read_group_list', 'read_group_detail'],
  currentRisk: [
    'add_current_risk',
    'edit_current_risk',
    'delete_current_risk',
    'read_current_risk',
  ],
  specificRisk: [
    'add_specific_risk',
    'edit_specific_risk',
    'delete_specific_risk',
    'read_specific_risk',
  ],
  siteType: ['add_site_type', 'edit_site_type', 'delete_site_type', 'read_site_type'],
  tag: ['add_tag', 'edit_tag', 'delete_tag', 'read_tag'],
  moyen: ['add_moyen', 'edit_moyen', 'delete_moyen', 'read_moyen'],
  bien: ['create_bien', 'edit_bien', 'delete_bien', 'read_bien'],
  dangerousMaterial: [
    'add_dangerous_material',
    'edit_dangerous_material',
    'delete_dangerous_material',
    'read_dangerous_material_list',
  ],
  category: ['add_category', 'edit_category', 'delete_category', 'read_category'],
  gedOps: ['add_ged_ops', 'edit_ged_ops', 'delete_ged_ops', 'read_ged_ops'],
  device: ['read_device', 'edit_device'],
  department: ['add_department', 'edit_department', 'delete_department', 'read_department'],
  commune: ['add_commune', 'edit_commune', 'delete_commune', 'read_commune'],
  fileSize: ['edit_file_size'],
  isBatifireAdmin: ['is_batifire'],
  logs: ['read_logs']
};
