import React, { createContext, FC } from 'react';
import { useGetFileSizeQuery } from 'generated/graphql';

type FileSizeContextType = {
  size: number;
  id: string;
};

export const FileSizeContext = createContext<FileSizeContextType>({
  size: 3000000,
  id: ''
});

export const FileSizeProvider: FC = ({ children }) => {
  const { data: fileSize } = useGetFileSizeQuery({ fetchPolicy: 'cache-and-network' });

  return (
    <FileSizeContext.Provider
      value={ {
        size: fileSize?.getFileSize?.size || 3000000,
        id: fileSize?.getFileSize?.id || ''
      } }
    >
      { children }
    </FileSizeContext.Provider>
  );
};
