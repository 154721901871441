import { ReactNode, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { Navigate } from 'react-router-dom';

type PermissionRouteGuardProp = {
  permissions: string[];
  children: ReactNode | string;
};

export default function PermissionRouteGuard({ permissions, children }: PermissionRouteGuardProp) {
  const { can } = useContext(UserContext);
  const hasAccess = can(permissions);
  if (hasAccess) {
    return <>{ children }</>;
  }

  return <Navigate to="/404" replace/>;
}
