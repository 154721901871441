export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const sentryConfig = process.env.REACT_APP_SENTRY_DSN;
export const versionNumber = process.env.REACT_APP_VERSION_NUMBER;
export const versionDate = process.env.REACT_APP_VERSION_DATE;

export const apolloConfig = {
  servers: {
    graphqlUrl: process.env.REACT_APP_API_URL,
    graphqlSubscriptionUrl: process.env.REACT_APP_WS_URL
  }
};

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
