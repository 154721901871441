// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${ root }${ sublink }`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    editById: path(ROOTS_DASHBOARD, '/user/:userId/edit')
  },
  group: {
    root: path(ROOTS_DASHBOARD, '/group'),
    newGroup: path(ROOTS_DASHBOARD, '/group/new'),
    list: path(ROOTS_DASHBOARD, '/group/list'),
    users: path(ROOTS_DASHBOARD, '/group/:groupId/users'),
    editById: path(ROOTS_DASHBOARD, '/user/:groupId/edit')
  },
  siteType: {
    root: path(ROOTS_DASHBOARD, '/siteType'),
    list: path(ROOTS_DASHBOARD, '/siteType/list')
  },
  tag: {
    root: path(ROOTS_DASHBOARD, '/tag'),
    list: path(ROOTS_DASHBOARD, '/tag/list')
  },
  risk: {
    root: path(ROOTS_DASHBOARD, '/risk'),
    currentRisk: {
      root: path(ROOTS_DASHBOARD, '/risk/currentRisk'),
      list: path(ROOTS_DASHBOARD, '/risk/currentRisk/list')
    },
    specificRisk: {
      root: path(ROOTS_DASHBOARD, '/risk/specificRisk'),
      list: path(ROOTS_DASHBOARD, '/risk/specificRisk/list'),
      new: path(ROOTS_DASHBOARD, '/risk/specificRisk/new'),
      editById: path(ROOTS_DASHBOARD, '/risk/specificRisk/:specificRiskId/edit')
    }
  },
  site: {
    root: path(ROOTS_DASHBOARD, '/site'),
    newSite: path(ROOTS_DASHBOARD, '/site/new'),
    list: path(ROOTS_DASHBOARD, '/site/list'),
    listChangeRequest: path(ROOTS_DASHBOARD, '/site/change-request-list'),
    listCreateRequest: path(ROOTS_DASHBOARD, '/site/create-request-list'),
    editById: path(ROOTS_DASHBOARD, '/site/:siteId/edit'),
    export: path(ROOTS_DASHBOARD, '/site/export')
  },
  moyen: {
    root: path(ROOTS_DASHBOARD, '/moyen')
  },
  device: {
    root: path(ROOTS_DASHBOARD, '/device'),
    list: path(ROOTS_DASHBOARD, '/device/list')
  },
  dangerousMaterial: {
    root: path(ROOTS_DASHBOARD, '/dangerousMaterial'),
    list: path(ROOTS_DASHBOARD, '/dangerousMaterial/list'),
    editById: path(ROOTS_DASHBOARD, 'user/:dangerousMaterialId/edit')
  },
  gedOps: {
    root: path(ROOTS_DASHBOARD, '/gedOps'),
    category: {
      root: path(ROOTS_DASHBOARD, '/gedOps/category'),
      list: path(ROOTS_DASHBOARD, '/gedOps/category/list')
    },
    list: path(ROOTS_DASHBOARD, '/gedOps/list')
  },
  Department: {
    root: path(ROOTS_DASHBOARD, '/Department'),
    list: path(ROOTS_DASHBOARD, 'Department/list')
  },
  Commune: {
    root: path(ROOTS_DASHBOARD, '/Commune'),
    list: path(ROOTS_DASHBOARD, 'Commune/list')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin')
  },
  logs: {
    root: path(ROOTS_DASHBOARD, '/logs'),
    list: path(ROOTS_DASHBOARD, '/logs/list')
  }
};
