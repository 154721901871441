import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { apolloConfig } from '../config';

export const createApolloClient = (token: string) => {
  const cache = new InMemoryCache();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${ token }` : ''
      }
    };
  });

  const httpLink = createHttpLink({
    uri: apolloConfig.servers.graphqlUrl
  });

  const wsLink = new WebSocketLink({
    uri: apolloConfig.servers.graphqlSubscriptionUrl || 'ws://localhost:4000/graphql',
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token
      }
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: cache
  });
  return apolloClient;
};
